/** it expects the redux state */
import { mergeArrays } from '../arraysHelper/index';
import _ from 'lodash';
import eventsFleetConstructor from './mstp_EventsFleet';

const vehiclesConstructor = state => {
  if (state.fleet.fleet.length === 0) {
    return [];
  }
  const events = eventsFleetConstructor(state);
  const vehicleWithEvents = state.fleet.fleet.vehicle.map(vehicle => {
    vehicle.events = events.filter(event => {
      return event.vehicleReference === vehicle.Reference;
    });
    return vehicle;
  });
  return vehicleWithEvents;
};

const vehicleTreeConstructor = (vehicle, disabled = false) => {
  return {
    key: vehicle.Vehicle.Reference,
    pId: vehicle.Vehicle.Traction,
    title: vehicle.Vehicle.Designation,
    value: vehicle.Vehicle.Designation,
    disabled: disabled
  };
};

const fleetConstructor = vehicles => {
  return [...new Set(vehicles.map(element => element.Traction))];
};

const oldFleetConstructor = vehicles => {
  return [...new Set(vehicles.map(element => element.Vehicle.Traction))];
};

const fleetTreeConstructor = (fleetType, disabled = false) => {
  return {
    key: fleetType,
    pId: 0,
    title: fleetType,
    value: fleetType,
    disabled: disabled
  };
};

const oldFleetTreeConstructor = (fleetType, disabled = false) => {
  return {
    key: fleetType,
    pId: 0,
    title: fleetType,
    value: fleetType,
    disabled: disabled
  };
};

const carriageContructor = (vehicles, events = null) => {
  return mergeArrays(
    vehicles.map(vehicle => {
      return vehicle.Carriages.map(carriage => {
        const axleboxes = axleboxContructor(vehicles, events).filter(
          axlebox => {
            if (axlebox) {
              return axlebox.carriageReference === carriage.Reference;
            }
          }
        );
        return {
          reference: carriage.Reference,
          assetKey: carriage.AssetKey,
          designation: carriage.VehicleNumber,
          vehicleReference: vehicle.Reference,
          vehicleDesignation: vehicle.Designation,
          axleboxes,
          fleetDesignation: vehicle.Traction,
          position: carriage.Position
        };
      });
    })
  );
};

const carriageTreeContructor = carriage => {
  return {
    key: carriage.reference,
    pId: carriage.vehicleReference,
    title: carriage.designation,
    value: carriage.designation
  };
};

const axleboxContructor = (vehicles, events = null) => {
  let result = [];
  const wheelsets = mergeArrays(
    vehicles.map(vehicle => {
      return vehicle.Wheelsets;
    })
  );
  const wheelsetsPerCarriage = vehicles.map(vehicle => {
    return vehicle.Carriages.map(carriage => {
      return {
        wheelsetsInCarriage: carriage.Wheelsets,
        carriageReference: carriage.Reference,
        carriageNumber: carriage.VehicleNumber,
        vehicleReference: vehicle.Reference,
        vehicleNumber: vehicle.Designation
      };
    });
  });
  wheelsetsPerCarriage.map(vehicle => {
    vehicle.map(carriage => {
      result = [
        ...result,
        ...mergeArrays(
          wheelsets
            .filter(wheelset => {
              let haveToFilter = false;
              carriage.wheelsetsInCarriage.forEach(wheelsetInCarriage => {
                if (wheelset && wheelset.Reference === wheelsetInCarriage) {
                  haveToFilter = true;
                }
              });
              return haveToFilter;
            })
            .map(wheelset => {
              return wheelset.Axleboxes.map(axlebox => {
                return {
                  reference: axlebox.Reference,
                  assetKey: axlebox.AssetKey,
                  designation: axlebox.LocationName,
                  vehicleReference: carriage.vehicleReference,
                  vehicleDesignation: carriage.vehicleNumber,
                  carriageReference: carriage.carriageReference,
                  carriageDesignation: carriage.carriageNumber,
                  event: [],
                  bearings: axlebox.Bearings,
                  IMEI: axlebox.IMEI
                };
              });
            })
        )
      ];
    });
  });

  wheelsets.map(wheelset => {
    let Gearbox = wheelset.GearBox;
    let TractionMotor = wheelset.TractionMotor;
    if (Gearbox && wheelsetsPerCarriage[0][0])
      Gearbox = {
        reference: Gearbox.Reference,
        assetKey: Gearbox.AssetKey,
        locationName: Gearbox.LocationName,
        vehicleReference: wheelsetsPerCarriage[0][0].vehicleReference,
        vehicleDesignation: wheelsetsPerCarriage[0][0].vehicleNumber,
        carriageReference: wheelsetsPerCarriage[0][0].carriageReference,
        carriageDesignation: wheelsetsPerCarriage[0][0].carriageNumber
      };
    if (TractionMotor && wheelsetsPerCarriage[0][0])
      TractionMotor = {
        reference: TractionMotor.Reference,
        assetKey: TractionMotor.AssetKey,
        locationName: TractionMotor.LocationName,
        vehicleReference: wheelsetsPerCarriage[0][0].vehicleReference,
        vehicleDesignation: wheelsetsPerCarriage[0][0].vehicleNumber,
        carriageReference: wheelsetsPerCarriage[0][0].carriageReference,
        carriageDesignation: wheelsetsPerCarriage[0][0].carriageNumber
      };

    result.push(Gearbox);
    result.push(TractionMotor);
  });

  if (events) {
    events.forEach(event => {
      result[
        _.findIndex(result, { reference: event.axleboxReference })
      ].event.push(event);
    });
  }

  return result;
};

const axleboxTreeContructor = axlebox => {
  return {
    key: axlebox.reference,
    pId: axlebox.carriageReference,
    title: axlebox.designation,
    value: axlebox.designation
  };
};

const getByDesignation = ({ vehicles, designation }) => {
  const result = vehicles.find(
    vehicle => vehicle.Vehicle.Designation === designation
  );
  return result;
};

const constgetCarriageforBogie = ({ carriages, designation }) => {
  const carriage = carriages.find(
    carriage => carriage.key == designation && carriage.title.length > 3
  );
  const result = { type: 'Carriages', ...carriage };
  return result;
};

const getVehicleOrCarriage = ({
  vehicles,
  carriages,
  designation,
  reference
}) => {
  let result = {};
  let carriage = undefined;
  let bogie = undefined;

  const vehicle = vehicles.find(
    vehicle => vehicle.Vehicle.Reference === reference
  );
  result = { type: 'Trains', ...vehicle };

  if (!vehicle) {
    carriage = carriages.find(
      carriage => carriage.key == reference && carriage.title.length > 3
    );
    result = { type: 'Carriages', ...carriage };
  }

  if (!carriage && !vehicle) {
    bogie = carriages.find(bogie => bogie.key == reference);
    result = { type: 'Bogies', ...bogie };
  }

  return result;
};

const getByReferenceOrDesignation = ({
  vehicles,
  reference,
  designation,
  assetKey,
  events = null
}) => {
  const searcher =
    (assetKey && 'assetKey') ||
    (reference && 'reference') ||
    (designation && 'designation') ||
    '';
  if (!searcher) {
    return null;
  }

  const vehicleFound = vehicles.find(
    vehicleSingle =>
      vehicleSingle[searcher.charAt(0).toUpperCase() + searcher.slice(1)] ==
      (reference || designation || assetKey)
  );
  if (vehicleFound !== undefined) {
    return vehicleFound;
  }

  const carriagesFound = carriageContructor(vehicles, events).find(
    carriageSingle => {
      if (carriageSingle[searcher] == (reference || designation || assetKey))
        return carriageSingle;
    }
  );
  if (carriagesFound !== undefined) {
    return carriagesFound;
  }

  const axleboxesFound = axleboxContructor(vehicles, events).find(
    axleboxSingle => {
      if (axleboxSingle[searcher] == (reference || designation || assetKey))
        return axleboxSingle;
    }
  );

  return axleboxesFound || null;
};

/** Use this function inside a .filter() */
const vehiclesWithEventsConstructor = vehicle => {
  return vehicle.events.length > 0;
};

/** Use this function inside a .filter() */
/** TODO: We should remove this duplicate*/
const vehiclesWithEventsConstructorDashboard = vehicle => {
  return vehicle.events.length > 0;
};

export {
  vehiclesConstructor,
  vehiclesWithEventsConstructor,
  fleetConstructor,
  fleetTreeConstructor,
  carriageContructor,
  carriageTreeContructor,
  axleboxContructor,
  axleboxTreeContructor,
  vehicleTreeConstructor,
  getByReferenceOrDesignation,
  getByDesignation,
  vehiclesWithEventsConstructorDashboard,
  oldFleetTreeConstructor,
  oldFleetConstructor,
  getVehicleOrCarriage,
  constgetCarriageforBogie
};
