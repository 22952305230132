import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

interface ModalProps {
  handleHideModal: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  toggleModal: string;
  width: string;
}

const DivButton = styled.div`
  text-align: right;
`;

export class Modal extends React.Component<ModalProps> {
  render() {
    return (
      <div className="modal" style={{ display: this.props.toggleModal }}>
        <div
          className="modal-content"
          style={
            this.props.width
              ? { width: this.props.width, minWidth: 600 }
              : { width: '80%' }
          }
        >
          <DivButton>
            <a onClick={this.props.handleHideModal}>
              <FontAwesomeIcon icon={faTimes} />
            </a>
          </DivButton>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Modal;
