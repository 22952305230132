import React, { Component } from 'react';
import reportIcon from '../../assets/img/icon-report@x3.png';
import excelIcon from '../../assets/img/excel-logo-1.png';
import { apiService, HTTPMethods } from '../../services/apiService';
import Modal from '../../components/vehiclePage/InformationButton';
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import { getEventtKeySelector } from '../../selectors/getEventtSelector';
import PdfFile from './PdfReportFile';
import { connect } from 'react-redux';

const ComponentColumns = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin-left: 10%;
`;
const ComponentColumn = styled.div`
  flex: 40%;
  text-align: center;
  margin: auto;
  max-width: 400px;
  border: 1px solid #0f58d6;
  padding: 30px;
  border-radius: 25px;
  height: 180px;
  margin: 20px;
`;
const Span = styled.div`
  margin-top: 25px;
  font-size: 20px;
  color: black;
`;

export interface IPdfProps {
  startDate: string;
  endDate: string;
  measuramentValue: string;
  actionValue: string;
  hierarchyValue: string;
  eventsFleet: any[];
  onlyOpen: boolean;
  dataPdf: any[];
}
export interface IPdfState {
  toggleModal: boolean;
}

class PdfReport extends Component<IPdfProps, IPdfState> {
  constructor(props: any) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      toggleModal: false
    };

    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  showModal() {
    this.setState({ toggleModal: true });
  }

  hideModal() {
    this.setState({ toggleModal: false });
  }

  b64toBlob = (b64Data: string, contentType: string) => {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  handleClick = () => {
    if (this.state.toggleModal) this.setState({ toggleModal: false });
    else this.setState({ toggleModal: true });
  };

  handleClickExcel = (data: any) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Event Case Report');
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'SKF Insight Rail Web Event Case Report.xlsx');
  };

  handleClickPdf = async (
    ev: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    let eventsFleet = [];
    this.props.eventsFleet.map((e2: any) => {
      e2.map((e: any) => {
        if (!this.props.onlyOpen || (this.props.onlyOpen && e.status !== 2))
          eventsFleet.push(e);
      });
    });

    var reportFilterValues = {
      hierarchy: this.props.hierarchyValue,
      action: this.props.actionValue,
      measurementsType: this.props.measuramentValue,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      filterClosed: false,
      eventsFleet: eventsFleet
    };

    var data = await apiService(
      '/api/Events/GetReportPDF',
      HTTPMethods.POST,
      reportFilterValues
    )
      .then((response: any) => {
        return response.json();
      })
      .catch(e => {
        //console.log('Desde user service(login func): ', e);
      });

    const PDF = data.PDF;
    const fileName = 'SKF Insight Rail Web Event Case Report.pdf';
    if (window.navigator.msSaveOrOpenBlob && window.navigator.msSaveBlob) {
      //Download pdf in edge
      const blob = this.b64toBlob(PDF, 'application/pdf');
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      //Download pdf in chrome
      var a = document.createElement('a');
      a.href = `data:application/pdf;base64,${encodeURIComponent(PDF)}`;
      a.download = fileName;
      a.dispatchEvent(new MouseEvent('click'));
    }
  };

  render() {
    const { timeZone } = this.props;

    let csvData = [];
    this.props.eventsFleet.map((e2: any) => {
      e2.map((e: any) => {
        const STATUS = e.status === 2 ? 'Close' : 'Open';
        if (!this.props.onlyOpen || (this.props.onlyOpen && e.status !== 2))
          csvData.push({
            TRAIN: e.train_ID,
            CARRIAGE: e.vehicle_Nickname,
            FUNCTIONAL_LOCATION: e.functionalLocationID,
            EVENT_CASE_NUMBER: getEventtKeySelector(e),
            ASSET: e.assetType,
            ACTION: e.assetStatusLabel,
            SENSOR_IMEI: e.identifier,
            MEASUREMENT_TYPE: e.measurementType,
            STATUS,
            ASSESSMENT: e.diagnosis,
            RECOMMENDEDactions: e.recommendation,
            REPORTED_BY: e.createdBy,
            REPORTE_ON: e.alarmTimestamp,
            ACTION_STATUS: e.statusTaken,
            TIMEZONE: timeZone
          });
      });
    });

    return (
      <div>
        <input
          type="image"
          src={reportIcon}
          onClick={this.handleClick}
          alt=""
        />
        <Modal
          handleHideModal={this.hideModal}
          width={'60%'}
          toggleModal={this.state.toggleModal ? 'block' : 'none'}
        >
          <h2>Export Events data</h2>
          <br></br> <br></br> <br></br>
          <ComponentColumns>
            <PdfFile
              chapters={['chapter 1', 'chapter 2', 'chapter 3']}
              dataPdf={this.props.dataPdf}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              measuramentValue={this.props.measuramentValue}
              actionValue={this.props.actionValue}
              hierarchyValue={this.props.hierarchyValue}
              onlyOpen={this.props.onlyOpen}
            ></PdfFile>

            <ComponentColumn
              onClick={() => this.handleClickExcel(csvData)}
              style={{ cursor: 'pointer' }}
            >
              <input
                type="image"
                style={{ width: 60 }}
                src={excelIcon}
                alt=""
              />

              <Span>Export a Excel file</Span>
            </ComponentColumn>
            <br></br>
            <br></br>
            <Span>
              This will download a file of all events shown in the previous
              screen according to the filters applied. This can take a few
              seconds to finish.
            </Span>
            <br></br>
            <br></br>
          </ComponentColumns>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  timeZone: state.timeZone.timeZone
});

export default connect(mapStateToProps)(PdfReport);
