import { Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import SelectForm from '../../Home/Dashboard/DashboardContainer/Dashboard/SelectForm/SelectForm';
import TrainsTablev02 from '../../Home/Dashboard/DashboardContainer/Dashboard/TrainsTable/TrainsTablev02';
import { TrainDashboard } from '../../models/train';

export interface DashboardTrainsPanelPropsValues {
  trains: TrainDashboard[];
  typeVehicle: string;
  selectedTrains: string[];
}

export interface DashboardTrainsPanelPropsActions {
  onClickTrain: (train: TrainDashboard) => void;
  hasDashboardToVehicle: (payload: any) => void;
}

export type DashboardTrainsPanelProps = DashboardTrainsPanelPropsValues &
  DashboardTrainsPanelPropsActions;

const commissionedcomparisson = (a: TrainDashboard, b: TrainDashboard) => {
  if (a.totalTemperatureRedEvents > b.totalTemperatureRedEvents) return -1;
  if (a.totalTemperatureRedEvents < b.totalTemperatureRedEvents) return 1;

  if (a.totalVibrationRedEvents > b.totalVibrationRedEvents) return -1;
  if (a.totalVibrationRedEvents < b.totalVibrationRedEvents) return 1;

  if (a.totalTemperatureAmberEvents > b.totalTemperatureAmberEvents) return -1;
  if (a.totalTemperatureAmberEvents < b.totalTemperatureAmberEvents) return 1;

  if (a.totalVibrationAmberEvents > b.totalVibrationAmberEvents) return -1;
  if (a.totalVibrationAmberEvents < b.totalVibrationAmberEvents) return 1;

  if (a.totalSensorAmberEvents > b.totalSensorAmberEvents) return -1;
  if (a.totalSensorAmberEvents < b.totalSensorAmberEvents) return 1;

  if (a.totalTemperatureEvents > b.totalTemperatureEvents) return -1;
  if (a.totalTemperatureEvents < b.totalTemperatureEvents) return 1;

  if (a.totalVibrationEvents > b.totalVibrationEvents) return -1;
  if (a.totalVibrationEvents < b.totalVibrationEvents) return 1;

  if (a.totalSensorEvents > b.totalSensorEvents) return -1;
  if (a.totalSensorEvents < b.totalSensorEvents) return 1;

  if (
    parseInt(a.totalCommissionedSensors.toString()) >
    parseInt(b.totalCommissionedSensors.toString())
  ) {
    return -1;
  }
  if (
    parseInt(a.totalCommissionedSensors.toString()) <
    parseInt(b.totalCommissionedSensors.toString())
  ) {
    return 1;
  }

  return 0;
};

const orderByRecent = (trains: TrainDashboard[]) => {
  const sortedVehicles = [...trains].sort((a, b) =>
    a.vehicleName > b.vehicleName ? 1 : -1
  );
  sortedVehicles.sort((a, b) => {
    if (a.alarmTimestamp > b.alarmTimestamp) {
      return -1;
    } else if (a.alarmTimestamp < b.alarmTimestamp) {
      return 1;
    }
    if (
      parseInt(a.totalCommissionedSensors.toString()) >
      parseInt(b.totalCommissionedSensors.toString())
    ) {
      return -1;
    } else if (
      parseInt(a.totalCommissionedSensors.toString()) <
      parseInt(b.totalCommissionedSensors.toString())
    ) {
      return 1;
    } else if (
      parseInt(a.vehicleName.toString()) > parseInt(b.vehicleName.toString())
    ) {
      return -1;
    } else if (
      parseInt(a.vehicleName.toString()) < parseInt(b.vehicleName.toString())
    ) {
      return 1;
    }

    return 0;
  });
  return sortedVehicles;
};

const orderByCritical = (trains: TrainDashboard[]) => {
  const sortedVehicles = [
    ...trains.sort((a, b) => (a.vehicleName > b.vehicleName ? 1 : -1))
  ];

  let temperatureEvents = sortedVehicles.filter(
    vehicle => vehicle.totalTemperatureEvents > 0
  );

  let vibrationEvents = sortedVehicles.filter(
    vehicle => vehicle.totalVibrationEvents > 0
  );

  let sensorEvents = sortedVehicles.filter(
    vehicle => vehicle.totalSensorEvents > 0
  );

  vibrationEvents = vibrationEvents.filter(
    el => !temperatureEvents.includes(el)
  );
  sensorEvents = sensorEvents.filter(el => !vibrationEvents.includes(el));
  sensorEvents = sensorEvents.filter(el => !temperatureEvents.includes(el));

  const actNowVehiclesT = temperatureEvents.filter(
    vehicle => vehicle.severity === 'ACT NOW'
  );
  const actNowVehiclesV = vibrationEvents.filter(
    vehicle => vehicle.severity === 'ACT NOW'
  );
  const actNowVehiclesS = sensorEvents.filter(
    vehicle => vehicle.severity === 'ACT NOW'
  );

  const actVehiclesT = temperatureEvents.filter(
    vehicle => vehicle.severity === 'ACT'
  );
  const actVehiclesV = vibrationEvents.filter(
    vehicle => vehicle.severity === 'ACT'
  );
  const actVehiclesS = sensorEvents.filter(
    vehicle => vehicle.severity === 'ACT'
  );

  const planVehiclesT = temperatureEvents.filter(
    vehicle => vehicle.severity === 'PLAN'
  );
  const planVehiclesV = vibrationEvents.filter(
    vehicle => vehicle.severity === 'PLAN'
  );
  const planVehiclesS = sensorEvents.filter(
    vehicle => vehicle.severity === 'PLAN'
  );

  const noActionVehicles = sortedVehicles.filter(
    vehicle => vehicle.severity === ''
  );

  const aux = JSON.parse(JSON.stringify(planVehiclesS));

  return [
    ...actNowVehiclesT.sort(commissionedcomparisson),
    ...actNowVehiclesV.sort(commissionedcomparisson),
    ...actNowVehiclesS.sort(commissionedcomparisson),
    ...actVehiclesT.sort(commissionedcomparisson),
    ...actVehiclesV.sort(commissionedcomparisson),
    ...actVehiclesS.sort(commissionedcomparisson),
    ...planVehiclesT.sort(commissionedcomparisson),
    ...planVehiclesV.sort(commissionedcomparisson),
    ...planVehiclesS.sort(commissionedcomparisson),
    ...noActionVehicles.sort(commissionedcomparisson)
  ];
};

const orderTrains = (
  trains: TrainDashboard[],
  orderType: string
): TrainDashboard[] => {
  if (orderType === 'recent') {
    return orderByRecent(trains);
  }
  return orderByCritical(trains);
};

const DashboardTrainsPanel = ({
  trains,
  onClickTrain,
  typeVehicle,
  selectedTrains,
  hasDashboardToVehicle
}: DashboardTrainsPanelProps) => {
  const [orderType, setOrderType] = useState('critical');

  return (
    <Row className="flex1">
      <Col className="page-box-tab removeMarginTop">
        <Row className="map-title-container">
          <Col md={3} xl={4} xs={3}>
            <p className="page-box-map-main-title">{typeVehicle}</p>
          </Col>
          <Col md={9} xl={8} xs={9}>
            <SelectForm
              typeVehicle={typeVehicle}
              handleChange={(e: any) => {
                setOrderType(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row className="ml-3 mr-3">
          <TrainsTablev02
            typeVehicle={typeVehicle}
            vehicles={orderTrains(trains, orderType)}
            selectedVehicles={selectedTrains}
            clickRow={onClickTrain}
            handleViewDetailsClick={hasDashboardToVehicle}
          />
        </Row>
      </Col>
    </Row>
  );
};

DashboardTrainsPanel.defaultProps = {
  trains: []
};

export { DashboardTrainsPanel };
