import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import Moment from 'moment';
import { GetConfigurationPanel } from '../../services/userManagment.service';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { fleetService } from '../../services/fleet.service';
import { eventService } from '../../services/event.service';
import { axleboxService } from '../../services/axlebox.service';
import { chartService } from '../../services/chart.service';

import Header from '../utils/header/header';
import MainFooter from './../footer/mainFooter';
import { vehicleTreeConstructor } from '../utils/state';
import TreeSelectComponent from '../utils/filters/treeSelect';
import ComponentSlider from '@kapost/react-component-slider';
import Mask from '../../assets/img/mask.png';
import TrailerBogie from '../../Home/Fleet/Components/TrailerBogie';
import { BearingTemperatureSensor } from '../../Home/Fleet/Components/BearingTemperatureSensor';

import EventsTable from '../../Home/Fleet/Components/EventsTable';
import { getHierarchy } from '../../actions';
import {
  getByReferenceOrDesignation,
  getVehicleOrCarriage,
  constgetCarriageforBogie
} from '../utils/state/mstp_Vehicles';
import FleetMapWrapperv02 from './../../Home/Fleet/Components/FleetMapWrapper/FleetMapWrapperv02';
import analyzeIcon from './../../assets/img/generalSvg/analyzeIcon';

import { history } from '../../../src/helpers/index';
import { GetHierarchyForFleetDropdown } from '../../services/common.service';
import Modal from './InformationButton';
import AssetInformation from './AssetInformation';
import FleetAssetPanel from './FleetAssetPanel';
import ModalDocuments from './modalDocuments';
import ModalPreFilters from './modalPreFilters';
// import { ErrorBoundary, RecordPageView } from '../../App/AwsRum';

const TopViewContainer = styled(Row)`
  margin: 0 2px;
`;

const TrainFilterContainerOne = styled.div`
  height: 100%;
`;

const BreadcrumbContainer = styled(Breadcrumb)`
  display: flex;
  justify-content: flex-end;
  .breadcrumb {
    margin: 0.5rem 0;
  }
`;

const LoadingBreadcrumb = styled.div`
  min-height: 54px;
`;

const TrainFilterContainerTwo = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const TrainFilterContainerFour = styled.div`
  transform: translateY(-8px);

  margin-left: 15px;
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 20px 0px;
  padding: 20px 0;
  font-size: 32px;
  text-align: center;
`;

const LoadingTempChart = styled.div`
  display: flex;
  background-color: var(--white);
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  min-height: 304px;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 100%;
`;

const ChartsContainer = styled(Col)`
  display: flex;
  justify-content: flex-start;
`;

const NoDataContainer = styled.div`
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 10px 30%;
  padding: 60px;
  font-size: 32px;
  text-align: center;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
`;

const BodyContainer = styled.div`
  min-height: calc(100vh - 258px);
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataContainer = styled(Row)`
  margin: 0px 0px 0px 0px;
`;

const RowTable = styled(Row)`
  margin-right: 0;
  margin-left: 0;
  min-height: 230px;
`;

const renderLeftArrow = () => <img src={Mask} className="mask maskright" />;
const renderRightArrow = () => <img src={Mask} className="mask" />;

class VehicleMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleLoading: props.trainSelected ? true : false,
      bogies: [],
      positionClicked: 0,
      trainSelected: props.trainSelected,
      carriageSelected: {},
      axleboxSelected: {},
      assetInformation: {},
      kpi: {},
      hierarchyFleetData: [],
      fleet: [],
      startDate: new Date().setDate(new Date().getDate() - 1),
      events: [],
      eventsForTopView: [],
      scopedEvents: [],
      points: [],
      lastLevel: false,
      tabSelected: 'status',
      loadingTemperatureRadar: true,
      loadingKPI: true,
      loadingEventTable: false,
      loadingAssetInformationTab: true,
      loadingRadar: false,
      toggleModal: false,
      modalDocumentOpen: false,
      roles: [],
      listVehiclePreSelected: [],
      modalPreFiltersOpen: false
    };

    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  getDisabled = value => {
    return this.state.treeData?.find(e => e.value === value)?.disabled;
  };

  getHash = value => {
    return this.state.treeData?.find(e => e.value === value)?.key;
  };

  getNode = value => {
    return this.state.treeData?.find(e => e.value === value);
  };

  getChilds = value => {
    return this.state.treeData?.filter(e => e.pId === value);
  };

  getAllChild = (value, level) => {
    let result = this.getChilds(value);
    if (level === 'Trains') return result;
    let resultCarriages = [];
    result.forEach(e => {
      resultCarriages = resultCarriages.concat(this.getChilds(e.key));
    });
    if (level === 'Carriages') {
      return resultCarriages;
    }
    let resultBogies = [];
    resultCarriages.forEach(e => {
      resultBogies = resultBogies.concat(this.getChilds(e.key));
    });
    if (level === 'Bogies') {
      return resultBogies;
    }
  };

  loadUniquefilter = filterVehicle => {
    return this.getHash(filterVehicle);
  };

  loadMultiplesfilter = aPrechargedVehicle => {
    var level = sessionStorage.getItem('typeVehicle');
    let filters = [];
    if (!Array.isArray(aPrechargedVehicle))
      filters = filters.concat(this.getAllChild(aPrechargedVehicle, level));
    else
      aPrechargedVehicle.forEach(filter => {
        if (this.getDisabled(filter) && this.getNode(filter).pId === 0)
          filters = filters.concat(this.getAllChild(filter, level));
        else filters.push(this.getNode(filter));
      });

    this.setState({
      listVehiclePreSelected: filters,
      modalPreFiltersOpen: true
    });
  };

  checkPrefilters = filterVehicles => {
    let prechargedVehicle = filterVehicles;
    let aPrechargedVehicle = prechargedVehicle?.split(',');
    if (
      aPrechargedVehicle.length === 1 &&
      !this.getDisabled(aPrechargedVehicle[0])
    ) {
      return this.loadUniquefilter(aPrechargedVehicle[0]);
    } else {
      return this.loadMultiplesfilter(aPrechargedVehicle);
    }
  };

  async componentDidMount() {
    this.props.changeMenu('fleet');

    if (this.props.hierarchy.length === 0) {
      this.props.getHierarchy();
    }
    // const treeData = await GetHierarchybyVehicleDetails();
    var client = sessionStorage.getItem('client');
    var level = sessionStorage.getItem('typeVehicle');
    let filterVehicles = sessionStorage.getItem('filterVehicles');
    const treeData = await GetHierarchyForFleetDropdown(client, level);
    const configClients = await GetConfigurationPanel([client]);
    const roles = configClients.data[0].Roles;
    this.setState({ treeData, roles });

    let prechargedVehicle = this.checkPrefilters(filterVehicles);
    if (!prechargedVehicle) prechargedVehicle = this.props.trainSelected;
    if (prechargedVehicle) {
      const { Vehicle: vehicle } = await fleetService.vehicle(
        prechargedVehicle
      );
      this.setState(
        { vehicle, hierarchyFleetData: vehicle.Designation },
        async () => {
          const fleet = await fleetService.fleet();
          this.setState({ fleet });
        }
      );

      this.setState({ loadingKPI: true });

      const [events, eventsForTopView, kpi] = await Promise.all([
        eventService.getAllEventsByVehicle(
          prechargedVehicle,
          vehicle.Reference,
          level
        ),
        eventService.getAllEventsForTopViewByVehicle(
          prechargedVehicle,
          vehicle.Reference,
          level
        ),

        this.scopedKPI(level, prechargedVehicle)
      ]);
      this.setState(
        { kpi, events, eventsForTopView, loadingKPI: false, prechargedVehicle },
        () => {
          this.scopedEvents(level);
          this.scopedGps(level, prechargedVehicle, vehicle.Reference);
          this.getTemperaturesRadarInfoByVehicle({
            hash: prechargedVehicle,
            date: new Date().setDate(new Date().getDate() - 1),
            level
          });
        }
      );
      // change to vehicle Reference eventually.

      fleetService
        .gpsByVehicle(prechargedVehicle, vehicle.Reference, level)
        .then(points => this.setState({ mapInfo: points }));

      /**
       * Bogies Declaration
       */
      if (Object.keys(this.state.vehicle).length > 0) {
        this.bogieConstructor(this.state.vehicle);
        this.setState({ vehicleLoading: false });
      }
    }
    if (document.getElementsByClassName('caret-right')[0])
      document.getElementsByClassName('caret-right')[0].click();
  }

  handlerPreFilter = value => {
    sessionStorage.setItem('filterVehicles', value);
    let key = this.getHash(value);
    let obj = [
      {},
      {
        triggerNode: {
          props: {
            eventKey: key
          }
        }
      }
    ];
    obj.value = value;
    this.setState({ modalPreFiltersOpen: false, listVehiclePreSelected: [] });
    this.handleHierarchyFleetOnChange(obj);

    setTimeout(this.lastTrain, 3000);
  };

  lastTrain = () => {
    if (document.getElementsByClassName('caret-right')[0])
      document.getElementsByClassName('caret-right')[0].click();
  };

  toHistory = date => {
    const hashSelected =
      this.state.axleboxSelected.reference ||
      this.state.carriageSelected.reference ||
      this.state.trainSelected;

    this.props.toHistory(hashSelected, date);
    history.push('/history');
  };

  setmodalDocumentOpen = () => {
    this.setState({ modalDocumentOpen: !this.state.modalDocumentOpen });
  };

  bogieConstructor = vehicle => {
    let customUIC = this.state.vehicle.SetUIC;
    customUIC = customUIC.replace(/['.*+?^${}()|[\]\\]/g, ',');
    customUIC = customUIC.replace(/,,/g, ',');
    let bogieUICList = customUIC.split(',');
    bogieUICList = bogieUICList.filter(UIC => UIC !== ''); // Remove empty values
    let bogieIndex = 0;

    vehicle.Units.forEach(unit => {
      unit.Bogies.forEach((bogie, indexUnit) => {
        const newBogie = bogie;
        if (indexUnit === 0) {
          newBogie.displayInCarriage = 'firstBogie';
        } else if (indexUnit === unit.Bogies.length - 1) {
          newBogie.displayInCarriage = 'lastBogie';
        } else {
          newBogie.displayInCarriage = 'sharedBogie';
        }
        newBogie.UIC = bogieUICList[bogieIndex] || '';
        newBogie.positionOrder = bogieIndex;
        this.setState(prevstate => {
          return { bogies: [...prevstate.bogies, newBogie] };
        });
        bogieIndex += 1;
      });
    });
  };

  scopedPoints = scopedPoints => {
    let bluePointsIndex = [];
    const totalPoints =
      (this.state.skf_radarTempVehicle &&
        this.state.skf_radarTempVehicle.map(point => point.thevalue)) ||
      [];

    this.state.skf_radarTempVehicle &&
      this.state.skf_radarTempVehicle.forEach((point, index) => {
        scopedPoints.forEach(scopedPoint => {
          if (
            scopedPoint.sensorFunctionalLocationSerialNumber ===
            point.sensorFunctionalLocationSerialNumber
          ) {
            bluePointsIndex = [...bluePointsIndex, index];
            /**Aqui actualizar el dato azul con la respuesta del api */
          }
        });
      });
    const bluePointsFinal =
      this.state.skf_radarTempVehicle &&
      this.state.skf_radarTempVehicle.map((point, index) => {
        if (bluePointsIndex.includes(index)) {
          return point.thevalue;
        } else {
          return 0;
        }
      });
    const greyPointsFinal =
      this.state.skf_radarTempVehicle &&
      this.state.skf_radarTempVehicle.map((point, index) => {
        if (bluePointsIndex.includes(index)) {
          return 0;
        } else {
          return point.thevalue;
        }
      });

    const skf_bearingTemperature = {
      skf_bearingTemperatureToShow: bluePointsFinal,
      skf_bearingTemperatureToHide: greyPointsFinal,
      skf_bearingTemperatureTotal: totalPoints
    };

    return skf_bearingTemperature;
  };

  /***********************Get Thresholds****************************/
  getThresholds = tempArr => {
    const allThresholds = tempArr.map(obj => {
      return {
        threshold: [
          obj.temperatureAlarmLowerLimit,
          obj.temperatureAlarmUpperLimit
        ],
        ...obj
      };
    });
    const thresholdsArr = allThresholds.map(obj => {
      return [...obj.threshold];
    });
    let radarThresholds = Array.from(
      new Set(thresholdsArr.map(JSON.stringify)),
      JSON.parse
    );
    return radarThresholds;
  };

  /***********************Get Temperatures Radar Values by Vehicle****************************/
  getTemperaturesRadarInfoByVehicle = async ({ hash, date, level }) => {
    //console.debug("fecha cambiada", date);
    const formatedDate = Moment(date).format('YYYY-MM-DD');
    //console.debug("fecha cambiada 2", formatedDate);
    this.setState({ loadingRadar: true }, async () => {
      const skf_getSensorMaxTempByVehicle = await eventService.getSensorMaxTempByVehicle(
        hash,
        formatedDate,
        this.state.vehicle.Reference,
        level
      );

      let skf_bearingTemperature = {};
      if (
        skf_getSensorMaxTempByVehicle &&
        skf_getSensorMaxTempByVehicle.length !== 0
      ) {
        skf_bearingTemperature = {
          skf_bearingTemperatureToShow: skf_getSensorMaxTempByVehicle.map(
            ({ thevalue }) => thevalue
          ),
          skf_bearingTemperatureToHide: skf_getSensorMaxTempByVehicle.map(
            () => 0
          ),
          skf_bearingTemperatureTotal: skf_getSensorMaxTempByVehicle.map(
            ({ thevalue }) => thevalue
          )
        };
      } else {
        skf_bearingTemperature = {
          skf_bearingTemperatureToShow: [],
          skf_bearingTemperatureToHide: [],
          skf_bearingTemperatureTotal: []
        };
      }

      const radarThresholds = this.getThresholds(skf_getSensorMaxTempByVehicle);
      var skf_SensorMaxTempSortedByDate = [...skf_getSensorMaxTempByVehicle];
      this.setState({
        loadingRadar: false,
        skf_bearingTemperature,
        skf_radarTempVehicle: skf_getSensorMaxTempByVehicle,
        radarThresholds,
        // Start date is the last available date.
        startDate:
          skf_SensorMaxTempSortedByDate &&
          skf_SensorMaxTempSortedByDate.length !== 0
            ? skf_SensorMaxTempSortedByDate.sort(
                (a, b) => new Date(b.theDatetime) - new Date(a.theDatetime)
              )[0].theDatetime
            : date
            ? new Date(date)
            : new Date()
      });
    });
  };
  /***********************Get Temperatures Radar Values by Carriage****************************/
  getTemperaturesRadarInfoByCarriage = async ({
    scopedHash,
    vehicleReference,
    date
  }) => {
    const formatedDate = Moment(date).format('YYYY-MM-DD');
    this.setState({ loadingRadar: true }, async () => {
      const carriagePoints = await chartService.getSensorMaxTempByCarriage(
        scopedHash,
        vehicleReference,
        formatedDate
      );
      const coloredPoints = this.scopedPoints(carriagePoints);
      this.setState({
        loadingRadar: false,
        skf_bearingTemperature: coloredPoints
      });
    });
  };

  /***********************Get Temperatures Radar Values by Axlebox****************************/
  getTemperaturesRadarInfoByFunctionalLocation = async ({
    scopedHash,
    vehicleReference,
    date
  }) => {
    let coloredPoints = {};
    if (this.state.axleboxSelected.IMEI === '') {
      /**If imei === '', we need to all radar points in gray. */
      coloredPoints = this.scopedPoints([]);
    } else {
      /**If imei !== '', this will find the selected axlebox and paint it blue on the radar.  */
      const formatedDate = Moment(date).format('YYYY-MM-DD');
      const axleboxPoints = await chartService.getSensorMaxTempByFunctionalLocation(
        scopedHash,
        vehicleReference,
        formatedDate
      );
      coloredPoints = this.scopedPoints(axleboxPoints);
    }

    this.setState({
      loadingRadar: false,
      skf_bearingTemperature: coloredPoints
    });
  };
  /***********************Get Temperatures Radar Values by Vehicle and (carriage or axlebox)****************************/
  /**This function is called because when you change the date on the radar, we need to refresh not only the selected points,
   * but the entire radar (including thresholds), so, we are calling the whole radar to get the new updated gray points, then, get the blue ones (root).
   */
  getTemperaturesRadarInfoByVehicleAndScopedAsset = async ({
    hash,
    scopedHash,
    root,
    date
  }) => {
    const formatedDate = Moment(date).format('YYYY-MM-DD');
    var level = sessionStorage.getItem('typeVehicle');
    this.setState({ loadingRadar: true }, async () => {
      const skf_getSensorMaxTempByVehicle = await eventService.getSensorMaxTempByVehicle(
        hash,
        formatedDate,
        this.state.vehicle.Reference,
        level
      );

      let skf_bearingTemperature = {};
      if (
        skf_getSensorMaxTempByVehicle &&
        skf_getSensorMaxTempByVehicle.length !== 0
      ) {
        skf_bearingTemperature = {
          skf_bearingTemperatureToShow: skf_getSensorMaxTempByVehicle.map(
            ({ thevalue }) => thevalue
          ),
          skf_bearingTemperatureToHide: skf_getSensorMaxTempByVehicle.map(
            () => 0
          ),
          skf_bearingTemperatureTotal: skf_getSensorMaxTempByVehicle.map(
            ({ thevalue }) => thevalue
          )
        };
      }
      const radarThresholds = this.getThresholds(skf_getSensorMaxTempByVehicle);

      var skf_SensorMaxTempSortedByDate = [...skf_getSensorMaxTempByVehicle];

      this.setState(
        {
          skf_bearingTemperature,
          skf_radarTempVehicle: skf_getSensorMaxTempByVehicle,
          radarThresholds,
          startDate:
            skf_SensorMaxTempSortedByDate &&
            skf_SensorMaxTempSortedByDate.length !== 0
              ? skf_SensorMaxTempSortedByDate.sort(
                  (a, b) => new Date(b.theDatetime) - new Date(a.theDatetime)
                )[0].theDatetime
              : date
              ? new Date(date)
              : new Date()
        },
        async () => {
          let coloredPoints = [];
          if (root === 'Carriages') {
            const carriagePoints = await chartService.getSensorMaxTempByCarriage(
              scopedHash,
              hash,
              formatedDate
            );
            coloredPoints = this.scopedPoints(carriagePoints);
          } else if (root === 'axlebox') {
            const axleboxPoints = await chartService.getSensorMaxTempByFunctionalLocation(
              scopedHash,
              hash,
              formatedDate
            );
            coloredPoints = this.scopedPoints(axleboxPoints);
          }
          if (
            this.state.skf_radarTempVehicle &&
            this.state.skf_radarTempVehicle.length < 1
          ) {
            this.setState({
              loadingRadar: false,
              skf_bearingTemperature: {}
            });
          } else {
            this.setState({
              loadingRadar: false,
              skf_bearingTemperature: coloredPoints
            });
          }
        }
      );
    });
  };

  /**Function to be called when the datepicker changes,
   * it calls the temperature radar information for the whole train, then (if needed)
   * it calls by axle or carriage to mark the blue points.
   */
  handleDayChange = e => {
    /**If axlebox is selected */
    if (
      Object.entries(this.state.axleboxSelected) &&
      Object.entries(this.state.axleboxSelected).length > 1 &&
      this.state.axleboxSelected.constructor === Object
    ) {
      this.setState(
        {
          startDate: new Date(e),
          loadingRadar: true
        },
        () => {
          this.getTemperaturesRadarInfoByVehicleAndScopedAsset({
            hash: this.state.vehicle.Reference,
            scopedHash: this.state.axleboxSelected.reference,
            root: 'axlebox',
            date: this.state.startDate
          });
        }
      );
    } else if (
      /**If carriage is selected */
      Object.entries(this.state.carriageSelected) &&
      Object.entries(this.state.carriageSelected).length > 1 &&
      this.state.carriageSelected.constructor === Object
    ) {
      this.setState({ startDate: new Date(e) }, () => {
        this.getTemperaturesRadarInfoByVehicleAndScopedAsset({
          hash: this.state.vehicle.Reference,
          scopedHash: this.state.carriageSelected.reference,
          root: 'Carriages',
          date: this.state.startDate
        });
      });
    } else {
      /**If vehicle is selected */
      var level = sessionStorage.getItem('typeVehicle');

      this.setState({ startDate: new Date(e) }, () => {
        this.getTemperaturesRadarInfoByVehicle({
          hash: this.state.prechargedVehicle,
          date: this.state.startDate,
          level: level
        });
      });
    }
  };
  /**Function to be called when a new train from the dropdown is clicked.
   * It calls vehicledetails, events, radar, map kpi for the new train.
   */
  handleHierarchyFleetOnChange = e => {
    const selectedReference = getVehicleOrCarriage({
      vehicles: this.props.hierarchy,
      carriages: this.state.treeData,
      designation: e.value,
      reference: e[1].triggerNode.props.eventKey
    });
    let vehicleReference = undefined;
    let levelRef = undefined;
    let carriageSelected = undefined;
    let bogieSelected = undefined;
    var level = sessionStorage.getItem('typeVehicle');
    let vehicleRef = levelRef;
    sessionStorage.setItem('vehicle', e[1].triggerNode.props.eventKey);
    switch (selectedReference.type) {
      case 'Trains':
        vehicleReference = selectedReference.Vehicle.Reference;
        carriageSelected = false;
        bogieSelected = false;
        vehicleRef = selectedReference.Vehicle.Reference;
        levelRef = selectedReference.Vehicle.Reference;
        break;
      case 'Carriages':
        vehicleReference = selectedReference.pId;
        carriageSelected = selectedReference;
        vehicleRef = selectedReference.key;
        levelRef = selectedReference.key;
        bogieSelected = false;
        if (level === 'Trains') vehicleRef = vehicleReference;

        break;
      case 'Bogies':
        carriageSelected = constgetCarriageforBogie({
          carriages: this.state.treeData,
          designation: selectedReference.pId
        });

        vehicleReference = carriageSelected.pId;
        bogieSelected = selectedReference;
        levelRef = selectedReference.key;
        vehicleRef = selectedReference.key;
        if (level === 'Trains') vehicleRef = vehicleReference;
        if (level === 'Carriages') vehicleRef = carriageSelected.key;

        break;

      default:
        break;
    }
    this.setState(
      {
        loadingEventTable: true,
        vehicleLoading: true,
        hierarchyFleetData: e.value,
        trainSelected: vehicleReference,
        positionClicked: 0,
        bogies: []
      },
      async () => {
        const { Vehicle: vehicle } = await fleetService.vehicle(
          vehicleRef,
          level
        );

        const events = await eventService.getAllEventsByVehicle(
          vehicleRef,
          vehicleReference,
          level
        );
        const eventsForTopView = await eventService.getAllEventsForTopViewByVehicle(
          vehicleRef,
          vehicleReference,
          level
        );

        this.setState(
          {
            vehicle,
            events,
            eventsForTopView,
            axleboxSelected: {},
            carriageSelected: {},
            tabSelected: 'status',
            lastLevel: false,
            assetInformation: {},
            vehicleLoading: carriageSelected ? true : false,
            loadingEventTable: false
          },
          async () => {
            var level = sessionStorage.getItem('typeVehicle');
            this.getTemperaturesRadarInfoByVehicle({
              hash: levelRef,
              date: this.state.startDate,
              level: level
            });
            this.bogieConstructor(vehicle);
            this.scopedEvents();
            this.scopedGps('Trains', vehicleReference, vehicleReference);
            this.setState({ loadingKPI: true });
            const kpi = await this.scopedKPI('Trains', vehicleReference);
            this.setState(
              {
                kpi,
                loadingKPI: false
              },
              () => {
                if (bogieSelected) {
                  this.handleClickOnBogie(bogieSelected.key);
                } else if (carriageSelected) {
                  this.handleClickOnCarriage(
                    carriageSelected.pId,
                    carriageSelected.key,
                    carriageSelected.title
                  );
                }
              }
            );
          }
        );
        this.lastTrain();
      }
    );
  };

  scopedKPI = async (root, rootReference) => {
    const vehicleReference = this.state.vehicle?.Reference;
    let kpi = {};

    if (root === 'axlebox') {
      kpi = await fleetService.kpiByFunctionalLocation(
        rootReference,
        vehicleReference
      );
    } else
      kpi = await fleetService.kpiByVehicle(
        rootReference,
        vehicleReference,
        root
      );
    if (kpi && kpi.isIMx) kpi.IMxRail = true;
    return kpi;
  };

  scopedEvents = root => {
    const allEvents = this.state.events;
    let eventsToRender = [];
    if (root === 'Carriages') {
      eventsToRender = allEvents.filter(event => {
        const carriageReference =
          this.state.carriageSelected.reference || undefined;
        return (
          event.carriageReference === carriageReference || !carriageReference
        );
      });
    } else if (root === 'axlebox') {
      const axleboxReference =
        (this.state.axleboxSelected && this.state.axleboxSelected.reference) ||
        {};

      eventsToRender = allEvents.filter(event => {
        return event.functionalLocationReference === axleboxReference;
      });
    } else if (root === 'Bogies') {
      const bogieReference =
        (this.state.bogieSelected && this.state.bogieSelected.Reference) ||
        undefined;

      eventsToRender = allEvents.filter(event => {
        return event.bogieReference === bogieReference || !bogieReference;
      });
    } else {
      eventsToRender = allEvents;
    }

    this.setState({ scopedEvents: eventsToRender });
  };

  scopedGps = async (root, Reference, scopedReference) => {
    let response = [];
    if (root === 'axlebox') {
      response = await fleetService.gpsByFunctionalLocation(
        Reference,
        scopedReference
      );
    } else {
      response = await fleetService.gpsByVehicle(
        scopedReference,
        Reference,
        root
      );
    }
    this.setState({
      mapInfo: response
    });
  };

  isMotor(bogie) {
    // If the bogie UIC is a number, don't have motor
    let result = true;
    const firstChar = bogie.UIC.charAt(0);
    if (firstChar >= '0' && firstChar <= '9') {
      // it is a number
      result = false;
    }
    return result;
  }

  handleSelect = key => {
    this.setState({ tabSelected: key });
  };

  handleClickOnAxlebox = async (...variables) => {
    if (!variables[6]) return;
    this.setState(
      { vehicleLoading: true, loadingRadar: true, bogieSelected: undefined },
      async () => {
        const assetInformation = await axleboxService.getSensorByReference(
          variables[6],
          this.state.vehicle.Reference
        );

        const axleboxSelected = getByReferenceOrDesignation({
          vehicles: [this.state.vehicle],
          reference: variables[5]
        });

        if (!axleboxSelected) {
          this.setState({
            vehicleLoading: false,
            loadingRadar: false,
            bogieSelected: undefined
          });
          return;
        }

        let carriageSelected = getByReferenceOrDesignation({
          vehicles: [this.state.vehicle],
          reference: axleboxSelected.carriageReference
        });

        carriageSelected = {
          ...carriageSelected,
          vehicleNumber: carriageSelected.designation
        };

        this.setState(
          {
            assetInformation: assetInformation[0],
            vehicleLoading: false,
            loadingAssetInformationTab: false,
            axleboxSelected,
            carriageSelected,
            lastLevel: true,
            positionClicked: carriageSelected.position
          },
          async () => {
            this.scopedEvents('axlebox');
            this.getTemperaturesRadarInfoByFunctionalLocation({
              scopedHash: axleboxSelected.reference,
              vehicleReference: this.state.vehicle.Reference,
              //date: new Date("2018/08/12")
              date: this.state.startDate
            });
            this.scopedGps(
              'axlebox',
              this.state.vehicle.Reference,
              axleboxSelected.reference
            );
            this.setState({ loadingKPI: true });

            const kpi = await this.scopedKPI(
              'axlebox',
              axleboxSelected.assetKey
            );
            this.setState({
              kpi,
              loadingKPI: false
            });
            /*  } */
          }
        );
      }
    );
  };

  handleClickOnBogie = async bogieReference => {
    const axleboxSelected = {};
    const carriageReference = this.state.treeData.find(
      e => e.key === bogieReference
    ).pId;
    const carriageSelected = this.state.vehicle.Carriages.find(
      e => e.Reference === carriageReference
    );
    carriageSelected.vehicleNumber = carriageSelected.VehicleNumber;
    const bogie = this.state.bogies.find(e => e.Reference === bogieReference);
    this.setState({
      vehicleLoading: true,
      loadingRadar: true,
      loadingKPI: true,
      carriageSelected,
      axleboxSelected,
      assetInformation: {},
      lastLevel: false
    });
    this.scopedGps(
      'Bogies',

      this.state.vehicle.Reference,
      bogie.Reference
    ).then(e => this.setState({ loadingRadar: false }));
    var kpi = await this.scopedKPI(
      'Bogies',
      bogie.Reference,
      this.state.vehicle.Reference
    );
    var level = sessionStorage.getItem('typeVehicle');
    this.getTemperaturesRadarInfoByVehicle({
      hash: bogie.Reference,
      date: this.state.startDate,
      level: level
    }).then(e => this.setState({ vehicleLoading: false }));
    this.setState({ bogieSelected: bogie, kpi }, () => {
      this.scopedEvents('Bogies');
      this.setState({ loadingKPI: false });
    });
  };

  async handleClickOnCarriage(
    vehicleReference,
    carriageReference,
    designation
  ) {
    const { Position: positionClicked } = this.state.vehicle.Carriages.find(
      carriage => carriage.Reference === carriageReference
    );
    this.setState(
      {
        bogieSelected: undefined,
        vehicleLoading: true,
        lastLevel: false,
        axleboxSelected: {},
        assetInformation: {},
        tabSelected: 'status',
        carriageSelected: {
          reference: carriageReference,
          vehicleReference,
          vehicleNumber: designation
        },
        positionClicked
      },
      async () => {
        var level = sessionStorage.getItem('typeVehicle');
        this.getTemperaturesRadarInfoByVehicle({
          hash: carriageReference,
          date: this.state.startDate,
          level: level
        });

        this.scopedEvents('Carriages');
        this.scopedGps('Carriages', vehicleReference, carriageReference);
        this.setState({ loadingKPI: true });

        const kpi = await this.scopedKPI('Carriages', carriageReference);
        this.setState({ kpi, vehicleLoading: false, loadingKPI: false });
      }
    );
  }

  handleClickBreadcrumb = async ({ root }) => {
    if (root === 'Carriages') {
      this.setState({ loadingKPI: true });
      const kpi = await this.scopedKPI(
        'Carriages',
        this.state.carriageSelected.reference
      );
      this.setState(
        {
          bogieSelected: undefined,
          axleboxSelected: {},
          lastLevel: false,
          assetInformation: {},
          tabSelected: 'status',
          kpi,
          loadingKPI: false
        },
        () => {
          // call all functions for carriage.
          this.getTemperaturesRadarInfoByCarriage({
            scopedHash: this.state.carriageSelected.reference,
            vehicleReference: this.state.vehicle.Reference,
            // date: new Date("2018/08/12")
            date: this.state.startDate
            //date: date ? new Date(date) : new Date()
          });
          this.scopedEvents('Carriages');
          this.scopedGps(
            'Carriages',
            this.state.vehicle.Reference,
            this.state.carriageSelected.reference
          );
        }
      );
    } else if (root === 'Bogies') {
      this.setState({ loadingKPI: true });
      const kpi = await this.scopedKPI(
        'Bogies',
        this.state.bogieSelected.Reference
      );
      this.setState(
        {
          axleboxSelected: {},
          lastLevel: false,
          assetInformation: {},
          tabSelected: 'status',
          kpi,
          loadingKPI: false
        },
        () => {
          // call all functions for carriage.
          fleetService
            .maxTempInfoByBogie(
              this.state.bogieSelected.Reference,
              this.state.vehicle.Reference
            )
            .then(e => this.setState({ vehicleLoading: false }));
          this.scopedEvents('Bogies');
          this.scopedGps(
            'Bogies',
            this.state.vehicle.Reference,
            this.state.carriageSelected.reference
          );
        }
      );
    } else if (root === 'Trains') {
      this.setState({ loadingKPI: true });
      const kpi = await this.scopedKPI('Trains');

      this.setState(
        {
          bogieSelected: undefined,
          axleboxSelected: {},
          lastLevel: false,
          assetInformation: {},
          tabSelected: 'status',
          carriageSelected: {},
          scopedEvents: this.state.events,
          positionClicked: 0,
          kpi,
          loadingKPI: false
        },
        () => {
          // call all functions for vehicle.
          this.getTemperaturesRadarInfoByVehicle({
            hash: this.state.vehicle.Reference,
            // date: new Date("2018/08/12")
            date: this.state.startDate,
            level: root
            //date: date ? new Date(date) : new Date()
          });
          this.scopedGps('Trains', this.state.vehicle.Reference);
        }
      );
    }
  };

  isVehicleSelected = () => {
    let response = false;
    if (!this.state.carriageSelected.reference) {
      // If carriageSelected has reference, we are on Carriage or Axle level
      response = true;
    }
    return response;
  };

  showModal() {
    this.setState({ toggleModal: true });
  }

  hideModal() {
    this.setState({ toggleModal: false });
  }

  getFirmwareVerion = tempVersion => {
    const arr = new ArrayBuffer(4);
    const view = new DataView(arr);

    view.setUint32(0, tempVersion, true);

    const majorVersion = view.getUint8(3);
    const minorVersion = view.getUint8(2);

    const arr2 = new ArrayBuffer(2);
    const view2 = new DataView(arr2);

    view2.setUint8(0, view.getUint8(1), true);

    view2.setUint8(1, view.getUint8(0), true);

    const revision = view2.getUint16(0) >> 1;

    return `${majorVersion}.${minorVersion}.${revision}`;
  };

  getVehicleNumberCarriage = () => {
    const level = sessionStorage.getItem('typeVehicle');

    if (
      this.state.carriageSelected &&
      this.state.carriageSelected.vehicleNumber
    )
      return this.state.carriageSelected.vehicleNumber;
    if (this.state.bogieSelected && this.state.bogieSelected.VehicleNumber)
      return this.state.bogieSelected.VehicleNumber;
    else if (this.state.vehicle && level == 'Carriages')
      return this.state.vehicle.Carriages[0].VehicleNumber;
    else if (this.state.vehicle && level == 'Bogies')
      return this.state.vehicle.Carriages[0].VehicleNumber;
    return this.state.vehicle?.Designation;
  };

  getPositionBogie = () => {
    const level = sessionStorage.getItem('typeVehicle');
    if (this.state.bogieSelected && this.state.bogieSelected.positionOrder)
      return this.state.bogieSelected.positionOrder;
    if (level === 'Bogies' && this.state.vehicle)
      return this.state.vehicle.Units[0].Bogies[0].positionOrder;
    return undefined;
  };

  render() {
    const {
      carriageSelected,
      axleboxSelected,
      assetInformation,
      loadingAssetInformationTab,
      vehicle,
      hierarchyFleetData,
      bogies,
      positionClicked,
      bogieSelected,
      startDate,
      eventsForTopView,
      lastLevel,
      vehicleLoading,
      scopedEvents,
      loadingEventTable,
      kpi,
      mapInfo,
      skf_bearingTemperature,
      loadingRadar,
      treeData
    } = this.state;

    const level = sessionStorage.getItem('typeVehicle');
    const { dropdownData } = this.props;
    //console.debug("state", this.state);
    let nameInfoText = '';
    let nameInfo = level;
    if (
      lastLevel ||
      level === 'Bogies' ||
      (bogieSelected &&
        Object.keys(bogieSelected) &&
        Object.keys(bogieSelected).length > 0)
    ) {
      nameInfo = 'Asset';
      nameInfoText = 'Asset';
    } else if (
      (Object.keys(carriageSelected) &&
        Object.keys(carriageSelected).length > 0) ||
      level === 'Carriages'
    ) {
      nameInfo = 'Vehicle';
      nameInfoText = 'Asset';
    } else {
      nameInfo = 'Train';
      nameInfoText = 'Vehicle';
    }

    let vehicleNumber = this.getVehicleNumberCarriage();

    let bogiePosition = this.getPositionBogie();
    let carriages = vehicle?.Carriages;
    if (level === 'Bogies' && carriages?.length > 1) {
      carriages = [vehicle?.Carriages[0]];
      carriages[0].LabelVehicleNumber =
        vehicle?.Carriages[0].VehicleNumber +
        ' - ' +
        vehicle?.Carriages[1].VehicleNumber;
    }

    scopedEvents.sort((a, b) => {
      let da = new Date(a.alarmTimestamp),
        db = new Date(b.alarmTimestamp);
      return da - db;
    });

    return (
      <>
   
        <Modal
          handleHideModal={this.hideModal}
          toggleModal={this.state.toggleModal ? 'block' : 'none'}
        >
          {loadingAssetInformationTab ? (
            <LoadingContainer>
              <div className="spinner-border sfk_spinner" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </LoadingContainer>
          ) : (
            <>
              <div className="customText14">Asset Information</div>
              <AssetInformation
                loadingAssetInformationTab={loadingAssetInformationTab}
                trainOperator={assetInformation.Train_Operator}
                bogie={assetInformation.Bogie_Serial_Number}
                wheelset={assetInformation.Wheelset_Serial_Number}
                diameter={assetInformation.Wheel_Diameter}
                axlebox={axleboxSelected.designation}
                brand={
                  axleboxSelected.bearings
                    ? axleboxSelected.bearings[0].OEM
                    : ''
                }
                bearingSN={assetInformation.Bearing_Serial_Number}
                designation={
                  axleboxSelected.bearings
                    ? axleboxSelected.bearings[0].Designation
                    : ''
                }
                id={assetInformation.Identifier}
                firmware={this.getFirmwareVerion(
                  assetInformation.Firmware_Version
                )}
                axleboxLocation={assetInformation.Sensor_Location_Angle}
                axleboxOrientation={assetInformation.Sensor_Orientation_Angle}
                trainName={assetInformation.Train_Name}
                installationDate={Moment.utc(assetInformation.Added).format(
                  'DD/MM/YYYY'
                )}
              />
            </>
          )}
        </Modal>

        <ModalPreFilters
          vehicles={this.state.listVehiclePreSelected}
          isOpen={this.state.modalPreFiltersOpen}
          treeData={treeData}
          setFilter={this.handlerPreFilter}
          closeModal={() =>
            this.setState({
              modalPreFiltersOpen: false,
              listVehiclePreSelected: []
            })
          }
        ></ModalPreFilters>

        <ModalDocuments
          treeData={treeData}
          roles={this.state.roles}
          isOpen={this.state.modalDocumentOpen}
          closeModal={this.setmodalDocumentOpen}
          setID={vehicle && vehicle.Designation}
          vehicleNumber={vehicleNumber}
          bogiePosition={bogiePosition}
        ></ModalDocuments>
        <Header
          title={nameInfo}
          subtitle={sessionStorage.getItem('username')}
          typeVehicle={level}
          firstNumber={(kpi && kpi.totalVehicles) || 0}
          secondNumber={(kpi && kpi.totalRed) || 0} // cambiar
          thirdNumber={(kpi && kpi.totalAmber) || 0} // cambiar
          firstText={nameInfo}
          secondText={nameInfoText}
          thirdText={nameInfoText}
        />

        <Container fluid="true" className="dFlex customHeightVehicle">
          <Row fluid="true" style={{ marginLeft: 9, marginRight: 0 }}>
            {/* Dropdown menu */}
            <Col xl={6} md={6} sm={6} xs={6}>
              <TrainFilterContainerOne className="text-left">
                <TrainFilterContainerTwo>
                  <TrainFilterContainerFour>
                    <TreeSelectComponent
                      title=""
                      placeholder={'Search or Select'}
                      skf_TreeData={treeData}
                      onChange={e => this.handleHierarchyFleetOnChange(e)}
                      value={hierarchyFleetData}
                      singleSelect
                      spinner={dropdownData === 0}
                      valuesSelected={hierarchyFleetData}
                    />
                  </TrainFilterContainerFour>
                </TrainFilterContainerTwo>
              </TrainFilterContainerOne>
            </Col>
            {/**
                BreadCrumbs
                */}
            {vehicle && Object.keys(vehicle).length > 0 ? (
              <Col xl={6} md={6} sm={6} xs={6}>
                <div className="vCenter">
                  {/**
                BreadCrumb of Traction/Fleet
                */}
                  <BreadcrumbContainer className="breadCrumbSKF">
                    <Breadcrumb.Item
                      onClick={() => {
                        this.setState(
                          {
                            axleboxSelected: {},
                            carriageSelected: {},
                            positionClicked: 0,
                            assetInformation: {},
                            tabSelected: 'status'
                          },
                          () => this.scopedEvents()
                        );
                      }}
                    >
                      {vehicle.Traction}
                    </Breadcrumb.Item>
                    {/**
                BreadCrumb of Train/Vehicle
                */}
                    <Breadcrumb.Item
                      onClick={() => {
                        if (level === 'Trains')
                          this.handleClickBreadcrumb({
                            root: 'Trains'
                          });
                        this.scopedEvents();
                      }}
                    >
                      {vehicle && vehicle.Designation}
                    </Breadcrumb.Item>
                    {/**
                BreadCrumb of Carriage
                */}
                    {((carriageSelected &&
                      carriageSelected.vehicleNumber &&
                      carriageSelected.vehicleNumber.length > 0) ||
                      level === 'Bogies' ||
                      level === 'Carriages') && (
                      <Breadcrumb.Item
                        onClick={() => {
                          if (level === 'Trains' || level === 'Carriages')
                            this.handleClickBreadcrumb({
                              root: 'Carriages'
                            });
                        }}
                      >
                        {level === 'Bogies' || level === 'Carriages'
                          ? vehicle?.Carriages[0]?.VehicleNumber
                          : carriageSelected.vehicleNumber}
                      </Breadcrumb.Item>
                    )}
                    {/**
                BreadCrumb of Bogie
                */}
                    {((bogieSelected &&
                      bogieSelected.displayInCarriage &&
                      bogieSelected.displayInCarriage.length) ||
                      level === 'Bogies') > 0 && (
                      <Breadcrumb.Item>
                        {level === 'Bogies'
                          ? vehicle.Units[0].Bogies[0].UIC
                          : bogieSelected.displayInCarriage}
                      </Breadcrumb.Item>
                    )}

                    {/**
                     * 
                     * 
                     * 
                BreadCrumb of Axlebox
                */}
                    {carriageSelected &&
                      carriageSelected.vehicleNumber &&
                      carriageSelected.vehicleNumber.length > 0 &&
                      axleboxSelected &&
                      axleboxSelected.designation &&
                      axleboxSelected.designation.length > 0 && (
                        <Breadcrumb.Item onClick={() => null}>
                          {axleboxSelected.designation}
                          <a onClick={this.showModal} className="info-button">
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </a>
                        </Breadcrumb.Item>
                      )}
                  </BreadcrumbContainer>
                </div>
              </Col>
            ) : (
              <LoadingBreadcrumb></LoadingBreadcrumb>
            )}
          </Row>
          {vehicle ||
          vehicleLoading ||
          this.props.trainSelected ||
          (sessionStorage.getItem('filterVehicles') !== '' &&
            this.state.listVehiclePreSelected.length === 0) ? (
            <Fragment>
              <TopViewContainer>
                <Col xl={12} className="page-box-shadow trainDetailsView">
                  {vehicleLoading ? (
                    <LoadingContainer>
                      <div className="spinner-border sfk_spinner" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </LoadingContainer>
                  ) : (
                    vehicle &&
                    Object.keys(vehicle).length > 0 && (
                      <div className="insideTrain maxHeight pl-2">
                        <div className="fleet-datetime-group ml-1 trainTopView mb-0 font-weight-bold pb-0">
                          Train Top View
                        </div>
                        <ComponentSlider
                          renderLeftArrow={renderLeftArrow}
                          renderRightArrow={renderRightArrow}
                        >
                          <ul
                            id="listTrains"
                            className=" listTrains listMixedTrain"
                            ref={vehicle.Reference}
                          >
                            {' '}
                            {bogies
                              .sort(function(a, b) {
                                return b.positionOrder - a.positionOrder;
                              })
                              .map((bogie, idx) => {
                                if (
                                  (bogieSelected &&
                                    bogieSelected.displayInCarriage ==
                                      bogie.displayInCarriage &&
                                    level == 'Bogies') ||
                                  level != 'Bogies' ||
                                  !bogieSelected
                                ) {
                                  return (
                                    <li
                                      key={idx}
                                      className={bogie.displayInCarriage}
                                    >
                                      <Container className="topViewUnitCouple">
                                        <Row className="fullRow row-fluid align-items-center">
                                          <Col
                                            className={`col-fluid colAleft `}
                                          >
                                            <TrailerBogie
                                              axleBoxReference={
                                                axleboxSelected.reference
                                              }
                                              bogieSelected={bogieSelected}
                                              lastLevel={lastLevel}
                                              isMotor={this.isMotor(bogie)}
                                              carriages={vehicle.Carriages}
                                              IMxRail={this.state.kpi.IMxRail}
                                              bogie={bogie}
                                              clickBogie={
                                                this.handleClickOnBogie
                                              }
                                              clickElement={
                                                this.handleClickOnAxlebox
                                              }
                                              events={this.state.events}
                                              positionClicked={positionClicked}
                                              wheelsets={vehicle.Wheelsets}
                                              unitWheelSets={bogie.Wheelsets}
                                              allWheelWithEvents={
                                                eventsForTopView
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      </Container>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <Container className="topViewUnitCouple">
                                      <Row className="fullRow row-fluid align-items-center">
                                        <Col className={`col-fluid colAleft `}>
                                          <div></div>
                                        </Col>
                                      </Row>
                                    </Container>
                                  );
                                }
                              })}
                            <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                          </ul>

                          <ul
                            className={`${vehicle.Reference} listTrains marginTopSlide`}
                            ref={vehicle.Reference}
                          >
                            {carriages
                              .sort(function(a, b) {
                                return b.Position - a.Position;
                              })
                              .map(carriage => {
                                let carriagePosition = 'middleTopView';

                                if (
                                  carriages &&
                                  carriage.Position === carriages.length &&
                                  level !== 'Bogies'
                                ) {
                                  carriagePosition = 'startTopView';
                                } else if (
                                  carriage.Position === 1 &&
                                  level !== 'Bogies'
                                ) {
                                  carriagePosition = 'endTopView';
                                }

                                return (
                                  <li key={carriage.Reference}>
                                    <Container
                                      onClick={() =>
                                        this.handleClickOnCarriage(
                                          vehicle.Reference,
                                          carriage.Reference,
                                          carriage.VehicleNumber
                                        )
                                      }
                                      className={
                                        level === 'Bogies'
                                          ? 'middleTopViewBogie'
                                          : `topView ${carriagePosition}`
                                      }
                                    >
                                      <div className="positionVehicle">
                                        {level !== 'Bogies'
                                          ? carriage.Position
                                          : ''}
                                      </div>
                                    </Container>
                                    <div className="textCenter marginTopVehicle">
                                      <span className="customText12 customGrey">
                                        Vehicle:&nbsp;
                                        <span className="dark">
                                          <strong>
                                            {carriage.LabelVehicleNumber
                                              ? carriage.LabelVehicleNumber
                                              : carriage.VehicleNumber}
                                          </strong>
                                        </span>
                                      </span>
                                    </div>
                                  </li>
                                );
                              })}
                            <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
                          </ul>
                        </ComponentSlider>
                      </div>
                    )
                  )}
                </Col>
              </TopViewContainer>
              <DataContainer className="d-flex flex1">
                <Col
                  lg={8}
                  md={7}
                  xs={7}
                  style={{ marginLeft: '-2px' }}
                  className="w-100"
                >
                  <Row className="flex1 main-background fixedMargins">
                    <Col
                      lg={this.state.kpi.IMxRail ? 12 : 8}
                      xs={12}
                      md={12}
                      className="dFlex"
                    >
                      <FleetAssetPanel
                        kpi={this.state.kpi}
                        isLoading={this.state.loadingKPI}
                      />
                    </Col>
                    {!this.state.kpi.IMxRail && (
                      <ChartsContainer
                        className="w-100 resetMargins"
                        lg={4}
                        xs={12}
                        md={12}
                      >
                        {!loadingRadar && skf_bearingTemperature ? (
                          <BearingTemperatureSensor
                            handleDayChange={this.handleDayChange}
                            selected={new Date(startDate)}
                            isLoading={false}
                            totalSensors={
                              this.state.skf_bearingTemperature
                                .skf_bearingTemperatureTotal
                            }
                            sensorsToShow={
                              this.state.skf_bearingTemperature
                                .skf_bearingTemperatureToShow
                            }
                            sensorsToHide={
                              this.state.skf_bearingTemperature
                                .skf_bearingTemperatureToHide
                            }
                            thresholds={
                              this.state.radarThresholds &&
                              this.state.radarThresholds.length === 1
                                ? this.state.radarThresholds
                                : false
                            }
                            showLabel={false}
                            labelValue={''}
                            referenceToShow={this.toHistory}
                            showDetailBtn={lastLevel}
                            labels={this.state.skf_radarTempVehicle}
                          />
                        ) : (
                          <LoadingTempChart>
                            <div
                              className="spinner-border sfk_spinner"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </LoadingTempChart>
                        )}
                      </ChartsContainer>
                    )}
                  </Row>

                  {/* New Event table */}
                  {/* loadingEventTable SPINNER PARA EVENTOS AL CAMBIAR UN TREN. */}
                  <RowTable className="page-box-shadow marginTop marginBottomCustom">
                    <div className="fleet-datetime-group ml-1 trainTopView mb-0 font-weight-bold pb-0">
                      Active events
                    </div>
                    {loadingEventTable ? (
                      <LoadingContainer>
                        <div
                          className="spinner-border sfk_spinner"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </LoadingContainer>
                    ) : (
                      <Container className="event-container">
                        {scopedEvents && scopedEvents.length > 0 && (
                          <EventsTable eventsData={scopedEvents} />
                        )}
                        {!scopedEvents && (
                          <div className="noEvents">No Active Events</div>
                        )}
                        {scopedEvents && scopedEvents.length === 0 && (
                          <div className="noEvents">No Active events</div>
                        )}
                      </Container>
                    )}
                  </RowTable>
                </Col>
                <Col
                  lg={4}
                  md={5}
                  xs={5}
                  className="d-flex marginBottomCustom marginTop"
                >
                  <Container>
                    <Row>
                      {this.state.kpi.IMxRail && (
                        <ChartsContainer
                          className="w-100 resetMargins"
                          lg={12}
                          xs={12}
                        >
                          {!loadingRadar && skf_bearingTemperature ? (
                            <BearingTemperatureSensor
                              handleDayChange={this.handleDayChange}
                              selected={new Date(startDate)}
                              isLoading={false}
                              totalSensors={
                                this.state.skf_bearingTemperature
                                  .skf_bearingTemperatureTotal
                              }
                              sensorsToShow={
                                this.state.skf_bearingTemperature
                                  .skf_bearingTemperatureToShow
                              }
                              sensorsToHide={
                                this.state.skf_bearingTemperature
                                  .skf_bearingTemperatureToHide
                              }
                              thresholds={
                                this.state.radarThresholds &&
                                this.state.radarThresholds.length === 1
                                  ? this.state.radarThresholds
                                  : false
                              }
                              showLabel={false}
                              labelValue={''}
                              referenceToShow={this.toHistory}
                              showDetailBtn={lastLevel}
                              labels={this.state.skf_radarTempVehicle}
                            />
                          ) : (
                            <LoadingTempChart>
                              <div
                                className="spinner-border sfk_spinner"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </LoadingTempChart>
                          )}
                        </ChartsContainer>
                      )}
                    </Row>
                    <Row
                      className={this.state.kpi.IMxRail ? 'marginTop' : ''}
                      style={{ marginLeft: -10 }}
                    >
                      <Col className="page-box-shadow dFlex resetMargins">
                        <Row className="map-title-container pt-2">
                          <Col>
                            <p className="page-box-map-main-title">
                              {nameInfo} History Map
                            </p>
                          </Col>
                          <Col>
                            <p className="lastDays">Last 7 days</p>
                          </Col>
                        </Row>
                        {/*************** Google Map *******************/}
                        <Row className="resetMargins page-box flex1">
                          {vehicleLoading ? (
                            <LoadingContainer>
                              <div
                                className="spinner-border sfk_spinner"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </LoadingContainer>
                          ) : (
                            <FleetMapWrapperv02
                              className="h-100"
                              clickMarker={() => null} //handle function empty
                              scopedPoints={mapInfo} //puntos de la llamada
                              currentLocation={this.state.currentLatLng}
                            />
                          )}
                        </Row>
                        {/*************** Density of Taken Measurements *******************/}
                        <Row fluid="true" className="marginInfoFleet">
                          <Col xs={5}>
                            <span className="fleetInfoMapTitle">
                              Density of Taken Measurements:
                            </span>
                          </Col>
                          <Col xs={2}> </Col>
                          <Col xs={5}>
                            <Row className="gradient-container">
                              <Col xs={12}>
                                <div className="gradient"> </div>
                              </Col>
                              <Col xs={12}>
                                <Row>
                                  <Col xs={6} className="gradient-low-label">
                                    <p> Low </p>
                                  </Col>
                                  <Col xs={6} className="gradient-high-label">
                                    <p> High </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* </div> */}
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </DataContainer>
            </Fragment>
          ) : (
            <BodyContainer>
              <NoDataContainer className="AutoHeight">
                <>
                  {analyzeIcon}
                  <div>
                    Select a train or vehicle in the filter for detailed view
                  </div>
                </>
              </NoDataContainer>
            </BodyContainer>
          )}
      
          <MainFooter />
        </Container>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toHistory: (payload, date) => {
      dispatch({ type: 'SET_DATA_VEHICLES_TO_HISTORY', payload, date });
    },
    getHierarchy: () => dispatch(getHierarchy()),
    changeMenu: menu => dispatch({ type: 'CHANGE_MENU', menu }),
    hasDashboardToVehicle: payload =>
      dispatch({ type: 'SET_HASH_DASHBOARD_TO_VEHICLES', payload })
  };
};

const mapStateToProps = state => {
  const {
    fleet: { hierarchy },
    carrier
  } = state;
  let dropdownData = [];
  if (hierarchy && hierarchy.length > 0) {
    dropdownData = hierarchy.map(vehicle => {
      return vehicleTreeConstructor(vehicle, false);
    });
  }

  return {
    hierarchy: hierarchy || [],
    dropdownData,
    trainSelected: carrier.hashes.dashboardToVehicles
  };
};

const connectedVehicleMain = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleMain);
export { connectedVehicleMain as VehicleMain };
