import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ComponentContainer = styled.div`
  min-height: auto !important;
  height: auto !important;
  display: flex;
  justify-content: center;
  align-content: center;
  transform: ${props =>
    props.isLoading ? 'translateY(calc(100vh - 90px))' : ''};

  & :first-child {
    border-left: 0;
  }
`;

const TextWrapper = styled.a`
  color: #3c3838;
  font-size: 11.3333px;
  text-transform: uppercase;
  padding: 0px 20px;
  border-left: 1px solid #cccccc;
  margin: 5px 0px;
  &:hover {
    text-decoration: none;
  }
`;

const SpanWrapper = styled.span`
  color: #3c3838;
  font-size: 11.3333px;
  text-transform: uppercase;
  padding: 0px 20px;
  border-left: 1px solid #cccccc;
  margin: 5px 10px;
  &:hover {
    text-decoration: none;
  }
`;

const MainFooter = ({ isLoading = false }) => {
  return (
    <ComponentContainer isLoading={isLoading} className="MainFooter">
      <SpanWrapper className="logoFooter"></SpanWrapper>
      <SpanWrapper>© Copyright</SpanWrapper>
      <TextWrapper
        href="https://www.skf.com/group/footer/terms-and-conditions.html"
        target="_blank"
      >
        Terms & conditions
      </TextWrapper>
      <TextWrapper
        href="https://www.skf.com/group/footer/privacy-policy.html"
        target="_blank"
      >
        Privacy Policy
      </TextWrapper>
      <TextWrapper
        href="https://www.skf.com/group/footer/site-ownership.html"
        target="_blank"
      >
        Site OwnerShip
      </TextWrapper>
    </ComponentContainer>
  );
};

export default MainFooter;

MainFooter.propTypes = {
  isLoading: PropTypes.bool
};

MainFooter.defaultProps = {
  isLoading: false
};
