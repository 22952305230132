import React, { Fragment } from 'react';
import uuidv1 from 'uuid/v1';
import Moment from 'moment';

import locationWarning from '../../../../../../assets/img/ic-location-warning.png';
import locationAlarm from '../../../../../../assets/img/ic-location-alarm.png';
import locationSensor from '../../../../../../assets/img/ic-location-sensor.png';

let toolTipObj = null;
const offset = { x: -60, y: -60 };

class Markerv02 extends React.Component {
  constructor(props) {
    super(props);
    this.markersUx = 0;
    this.state = {
      isButtons: false,
      heatMapsBtnIsActived: true,
      markersBtnIsActived: true
    };
  }

  componentDidUpdate() {
    this.renderMarker();
    this.renderLogicButtons();
  }

  renderMarker = () => {
    const { google, map, markers, handleClickOnMarker } = this.props;
    // Delete previous markers, otherwise they would keep adding up on the map
    if (this.markersUx !== 0) {
      this.markersUx.forEach(marker => {
        marker.setMap(null);
      });
    }

    if (markers) {
      this.markersUx = markers
        .filter(point => point !== undefined && point !== null)
        .map(marker => {
          let lastTrainMeasurement = Moment(marker.date).format(
            'MMMM Do YYYY, h:mm:ss a'
          );
          let myLatLng = new google.maps.LatLng(marker.lat, marker.lng);
          let markerIcon =
            marker.severityColor === 'AMBER'
              ? locationWarning
              : marker.severityColor === 'RED'
              ? locationAlarm
              : locationSensor;

          let markerIndex =
            marker.severityColor === 'RED'
              ? 3
              : marker.severityColor === 'AMBER'
              ? 2
              : 1;
          let markerUx = new google.maps.Marker({
            name: marker.name,
            key: uuidv1(),
            position: myLatLng,
            map: map,
            icon: markerIcon,
            vehicle: marker.reference,
            zIndex: markerIndex,
            lastTrainMeasurement: lastTrainMeasurement
          });
          markerUx.addListener('mouseover', this.handleHover);
          markerUx.addListener('mouseout', this.handleMouseLeave);
          markerUx.addListener(
            'click',
            this.handleClickEvent.bind(marker, event, handleClickOnMarker),
            false
          );
          markerUx.setMap(map);
          return markerUx;
        });
    }
  };

  renderLogicButtons = () => {
    if (!this.state.isButtons) {
      const { google, map, handleClickOnMarker } = this.props;
      const centerControlDiv = document.createElement('div');
      centerControlDiv.style.display = 'flex';
      centerControlDiv.style.margin = '10px 0 0 10px';
      this.centerControl(centerControlDiv, map);
      centerControlDiv.index = 1;
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);
      this.setState(prevState => {
        return { isButtons: !prevState.isButtons };
      });
    }
  };

  centerControl = (controlDiv, map) => {
    // Set CSS for the control border.
    const heatMapsBtn = document.createElement('div');
    heatMapsBtn.id = 'heatMapsBtn';
    heatMapsBtn.style.borderRadius = '3px';
    heatMapsBtn.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    heatMapsBtn.style.cursor = 'pointer';
    heatMapsBtn.style.marginBottom = '22px';
    heatMapsBtn.style.textAlign = 'center';
    heatMapsBtn.style.backgroundColor = '#0f58d6';
    heatMapsBtn.style.color = '#ffffff';
    heatMapsBtn.style.fontFamily = 'Roboto,Arial,sans-serif';
    heatMapsBtn.style.fontSize = '16px';
    heatMapsBtn.style.lineHeight = '38px';
    heatMapsBtn.style.paddingLeft = '15px';
    heatMapsBtn.style.paddingRight = '15px';
    heatMapsBtn.innerHTML = 'Route map';
    heatMapsBtn.addEventListener('click', this.clickHeatMapsBtn);
    controlDiv.appendChild(heatMapsBtn);

    const markersBtn = document.createElement('div');
    markersBtn.id = 'markersBtn';
    markersBtn.style.borderRadius = '3px';
    markersBtn.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
    markersBtn.style.cursor = 'pointer';
    markersBtn.style.marginBottom = '22px';
    markersBtn.style.textAlign = 'center';
    markersBtn.style.backgroundColor = '#0f58d6';
    markersBtn.style.color = '#ffffff';
    markersBtn.style.fontFamily = 'Roboto,Arial,sans-serif';
    markersBtn.style.fontSize = '16px';
    markersBtn.style.lineHeight = '38px';
    markersBtn.style.paddingLeft = '15px';
    markersBtn.style.paddingRight = '15px';
    markersBtn.style.marginLeft = '10px';
    markersBtn.innerHTML = 'Last position recorded';
    markersBtn.addEventListener('click', this.clickMarkersBtn);
    controlDiv.appendChild(markersBtn);
  };

  clickHeatMapsBtn = () => {
    const heatMapsBtn = document.getElementById('heatMapsBtn');
    const newBtnIsActive = !this.state.heatMapsBtnIsActived;

    if (newBtnIsActive) {
      // blue btn
      heatMapsBtn.style.backgroundColor = '#0f58d6';
      heatMapsBtn.style.color = 'white';
    } else {
      // grey btn
      heatMapsBtn.style.backgroundColor = '#edeef0';
      heatMapsBtn.style.color = '#a59b9b';
    }
    this.setState({ heatMapsBtnIsActived: newBtnIsActive });
    this.props.toggleHeatMaps();
  };

  clickMarkersBtn = () => {
    const markersBtn = document.getElementById('markersBtn');
    const newBtnIsActive = !this.state.markersBtnIsActived;

    if (newBtnIsActive) {
      markersBtn.style.backgroundColor = '#0f58d6'; // blue
      markersBtn.style.color = 'white';
    } else {
      markersBtn.style.backgroundColor = '#edeef0'; // grey
      markersBtn.style.color = '#a59b9b';
    }
    this.setState({ markersBtnIsActived: newBtnIsActive });
    this.props.toggleMarkers();
  };

  handleMouseLeave() {
    if (toolTipObj) {
      document.body.removeChild(toolTipObj);
      toolTipObj = null;
    }
  }

  moveTooltip(event) {
    if (toolTipObj && event) {
      toolTipObj.style.top =
        event.Ba.clientY + window.scrollY + offset.y + 'px';
      toolTipObj.style.left =
        event.Ba.clientX + window.scrollX + offset.x + 'px';
    }
  }

  handleClickEvent(e, handleClickOnMarker, callback) {
    if (toolTipObj) {
      document.body.removeChild(toolTipObj);
      toolTipObj = null;
    }
    const vehicleClicked = this;
    handleClickOnMarker(vehicleClicked);
  }

  handleHover(event) {
    const coordMarker = event.domEvent.target.getBoundingClientRect();
    const coordMarkerX = coordMarker.left;
    const coordMarkerY = coordMarker.top;
    if (!toolTipObj && event) {
      // create the tooltip object
      toolTipObj = document.createElement('div');
      toolTipObj.classList.add('toolTipGoogleMaps');
      toolTipObj.innerHTML = `${this.name}<br>${this.lastTrainMeasurement}`;
      toolTipObj.style.top = coordMarkerY + window.scrollY + offset.y + 'px';
      toolTipObj.style.left = coordMarkerX + window.scrollX + offset.x + 'px';
      document.body.appendChild(toolTipObj);
    }
  }

  render() {
    return null;
  }
}
export default Markerv02;
