import { createSelector } from 'reselect';
import { ApplicationState } from '../reducers';
import {
  Train,
  TrainsSummary,
  TrainDashboard,
  TrainIdentifier,
  calcTrainWarningLevel
} from '../models/train';

const defaultTrainSummary: TrainsSummary = {
  totalRed: 0,
  totalAmber: 0,
  totalBearingRed: 0,
  totalBearingAmber: 0,
  totalWheelRed: 0,
  totalWheelAmber: 0,
  totalSensorAmber: 0
};

const trainDashboardSelector = (state: ApplicationState) =>
  state.newReducers.fetchTrainDashboardAsyncReducer.vehicles;
const filteredTrainDashboardSelector = (state: ApplicationState) =>
  state.newReducers.fetchTrainDashboardAsyncReducer.filteredVehicles;

const getTrainDashboardSelector = createSelector(
  trainDashboardSelector,
  filteredTrainDashboardSelector,
  (vehicles: Train[], filteredVehicles: TrainIdentifier[]) => {
    return vehicles.map(vehicle => {
      return {
        ...vehicle,
        filtered: !!filteredVehicles.find(name => name === vehicle.vehicleName),
        warning: calcTrainWarningLevel(vehicle)
      } as TrainDashboard;
    });
  }
);

const getFilteredTrainDashboardSelector = createSelector(
  getTrainDashboardSelector,
  filteredTrainDashboardSelector,
  (vehicles: TrainDashboard[], selected: string[]) =>
    selected.length ? vehicles.filter(v => v.filtered) : vehicles
);

const getFilteredTrainDashboardSummarySelector = createSelector(
  getFilteredTrainDashboardSelector,
  (vehicles: TrainDashboard[]) =>
    vehicles.reduce(
      (summary, vehicle) => {
        summary.totalRed += vehicle.totalRed;
        summary.totalAmber += vehicle.totalAmber;
        summary.totalBearingRed += vehicle.totalBearingRed;
        summary.totalBearingAmber += vehicle.totalBearingAmber;
        summary.totalWheelRed += vehicle.totalWheelRed;
        summary.totalWheelAmber += vehicle.totalWheelAmber;
        summary.totalSensorAmber += vehicle.totalSensorAmber;
        return summary;
      },
      { ...defaultTrainSummary }
    )
);

const getTrainByClientDashboardSelector = createSelector(
  trainDashboardSelector,
  (vehicles: Train[]) => {
    let skf_vehicles_by_company: any = {};
    vehicles.map(vehicle => {
      if (!skf_vehicles_by_company[vehicle.companyName]) {
        skf_vehicles_by_company[vehicle.companyName] = {};
      }
      skf_vehicles_by_company[vehicle.companyName] = {
        skf_Trains: vehicle.total,
        skf_totalRed: vehicle.totalFunctionalLocationRed,
        skf_totalAmber: vehicle.totalFunctionalLocatioAmber
      };
    });
    return skf_vehicles_by_company as TrainsSummary[];
  }
);

const isFetchingKpis = (state: ApplicationState) =>
  state.newReducers.fetchTrainDashboardAsyncReducer.isFetchingVehicles;

export {
  getFilteredTrainDashboardSummarySelector,
  getTrainDashboardSelector,
  getTrainByClientDashboardSelector,
  getFilteredTrainDashboardSelector,
  filteredTrainDashboardSelector,
  isFetchingKpis
};
