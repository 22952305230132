import { authHeader } from '../helpers';
import moment from 'moment';
import axios from 'axios';
import { customError, catchError } from './error.service';
function gpsByVehicle(reference, vehicleReference, level) {
  let levelReference = vehicleReference;
  if (!levelReference) levelReference = reference;
  const enddate = moment(new Date()).format('YYYY-MM-DD').toString();
  const startdate = moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD').toString();
  //GetLocationGpsByLevel/{levelReference}/vehicle/{vehicleReference}/level/{level}/startDate/{startDate}/endDate/{endDate}
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Trends/GetLocationGpsByLevel/${reference}/vehicle/${levelReference}/level/${level}/startDate/${startdate}/endDate/${enddate}`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function gpsByFunctionalLocation(hashVehicle, hashAxlebox) {
  //apiUrlDev
  const enddate = moment(new Date()).format('YYYY-MM-DD').toString();
  const startdate = moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD').toString();
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Trends/GetLocationGpsByAsset/${hashAxlebox}/vehicle/${hashVehicle}/startdate/${startdate}/enddate/${enddate}`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function fleet() {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Taxonomy/GetHierarchy`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function vehicle(hash, level) {
  var typeVehicle = level;
  if (!level) typeVehicle = sessionStorage.getItem('typeVehicle');
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Taxonomy/GetVehicleDetailsByLevel/levelReference/${hash}/level/${typeVehicle}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function kpiByVehicle(reference, vehicleReference, level) {
  let levelReference = vehicleReference;
  if (!levelReference) levelReference = reference;

  //GetAllKPIsByLevel/{levelReference}/vehicle/{vehicleReference}/level/{level}")
  return axios.get(`${process.env.REACT_APP_API_URL}/api/KPIS/GetAllKPIsByLevel/${reference}/vehicle/${levelReference}/level/${level}`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function kpiByFunctionalLocation(hashAxlebox, hashVehicle) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/KPIS/GetAllKPIsByAsset/${hashAxlebox}/vehicle/${hashVehicle}`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function kpisByBogie(bogieReference, vehicleReference) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/KPIS/GetAllKPIsByBogie/${bogieReference}/vehicle/${vehicleReference}`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function gpsByBogie(bogieReference, vehicleReference) {
  const endDate = moment(new Date()).format('YYYY-MM-DD').toString();
  const startDate = moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD').toString();
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Trends/GetLocationGpsByBogie/${bogieReference}/vehicle/${vehicleReference}/startDate/${startDate}/endDate/${endDate}`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function maxTempInfoByBogie(bogieReference, vehicleReference) {
  const thedate = moment(new Date()).format('YYYY-MM-DD').toString();
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Sensors/GetSensorMaxTempInfoByBogie/${bogieReference}/vehicle/${vehicleReference}/thedate/${thedate}`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function getDocumentcategory() {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/documents/GetCategories`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function postDocumentcategory(category, position, enabled) {
  const url = `${process.env.REACT_APP_API_URL}/api/documents/InsertCategory`;
  return axios({
    method: 'post',
    url,
    headers: authHeader(),
    data: {
      category,
      position: parseInt(position),
      status: enabled
    }
  });
}
function putDocumentcategory(category, position, newPosition, enabled) {
  const enabledBool = enabled === 'ENABLED';
  const url = `${process.env.REACT_APP_API_URL}/api/documents/UpdateCategory/${category}/${position}?newPosition=${newPosition}&enable=${enabledBool}`;
  return axios({
    method: 'put',
    url,
    headers: authHeader()
  });
}
function deleteDocumentcategory(category) {
  const url = `${process.env.REACT_APP_API_URL}/api/documents/DeleteStatus/${category}`;
  return axios({
    method: 'put',
    url,
    headers: authHeader()
  });
}
function getDocuments(client, level, setID, vehicleNumber, bogiePosition) {
  let url = `${process.env.REACT_APP_API_URL}/api/documents/${client}/level/${level}/${setID}`;
  if (vehicleNumber !== undefined) url = url + `/?vehicleNumber=${vehicleNumber}`;
  if (bogiePosition !== undefined) url = url + `&bogiePosition=${bogiePosition}`;
  return axios.get(url, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function insertDocumentFile(doc) {
  const url = `${process.env.REACT_APP_API_URL}/api/documents/InsertDocumentFile`;
  return axios({
    method: 'post',
    url,
    headers: authHeader(),
    data: doc
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function insertDocumentData(doc) {
  const url = `${process.env.REACT_APP_API_URL}/api/documents/InsertDocument`;
  return axios({
    method: 'post',
    url,
    headers: authHeader(),
    data: doc
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function getDocumentFile(uriDocumentFile, fileName) {
  const url = `${process.env.REACT_APP_API_URL}/api/documents/file`;
  return axios({
    responseType: 'arraybuffer',
    method: 'post',
    url,
    headers: authHeader(),
    data: {
      uriDocumentFile,
      fileName
    }
  });
}
export const fleetService = {
  vehicle,
  fleet,
  gpsByVehicle,
  gpsByFunctionalLocation,
  kpiByVehicle,
  kpiByFunctionalLocation,
  kpisByBogie,
  gpsByBogie,
  maxTempInfoByBogie,
  getDocumentcategory,
  postDocumentcategory,
  putDocumentcategory,
  deleteDocumentcategory,
  getDocuments,
  getDocumentFile,
  insertDocumentFile,
  insertDocumentData
};