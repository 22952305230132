import { AlertStatus } from './alerts';

export type TrainIdentifier = string;

export interface TrainDashboard extends Train, TrainStatus {}

export interface TrainFleet extends TrainSmall, TrainStatus {}
export interface TrainStatus {
  filtered: boolean;
  warning: AlertStatus;
}

export interface TrainsSmallSummary {
  totalRed: number;
  totalAmber: number;
}

export interface TrainsSummary {
  totalRed: number;
  totalAmber: number;
  totalBearingRed: number;
  totalBearingAmber: number;
  totalWheelRed: number;
  totalWheelAmber: number;
  totalSensorAmber: number;
  totalGearBoxAmber: number;
  totalGearBoxRed: number;
  totalTractionMotorAmber: number;
  totalTractionMotorRed: number;

  totalVibrationRedEvents: number;
  totalTemperatureRedEvents: number;
  totalVibrationAmberEvents: number;
  totalSensorAmberEvents: number;
  totalTemperatureAmberEvents: number;

  isIMx: boolean;
  maxTemperatureValue: number;
  companyId: number;
  companyName: string;
  bogiesPanel: string;
  typeVehicle: string;
  totaRed: number;
  total: number;
  totalTemperatureEvents: number;
  totalVibrationEvents: number;
  totalSensorEvents: number;
}

export interface Train extends TrainsSummary {
  trainId: number;
  vehicleName: string;
  reference: string;
  alarmTimestamp: string;
  severity: string;
  totalCommissionedSensors: number;
  totalFunctionalLocationRed: number;
  totalFunctionalLocatioAmber: number;
  isAxleboxesFake: boolean;
}

export interface TrainSmall extends TrainsSmallSummary {
  trainId: number;
  vehicleName: string;
  reference: string;
  alarmTimestamp: string;
}

export const calcTrainWarningLevel = <T extends TrainsSmallSummary>(
  vehicle: T
) => {
  if (vehicle.totalRed) {
    return AlertStatus.RED;
  } else if (vehicle.totalAmber) {
    return AlertStatus.AMBER;
  }
  return null;
};
