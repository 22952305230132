import { authHeader } from '../helpers';
import { customError, catchError } from './error.service';
import axios from 'axios';
export const dashboardService = {
  getCountAllKPIsByCustomerByLevel,
  GetAllKPIsByCustomerByLevel,
  getHierarchy,
  getLocationGpsByCustomer
};
function getCountAllKPIsByCustomerByLevel() {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/KPIS/CountAllKPIsByCustomerByLevel`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function GetAllKPIsByCustomerByLevel(companies, typeTrain) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/KPIS/GetAllKPIsByCustomerByLevel/company/${companies}/level/${typeTrain}`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function getHierarchy() {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Taxonomy/GetHierarchy`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function getLocationGpsByCustomer(startdate, enddate, level, client, isIMx) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Trends/GetLocationGpsByCustomerLevel/company/${client}/level/${level}/startDate/${startdate}/endDate/${enddate}`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}