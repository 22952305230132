import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ComponentContainer = styled.div`
  border-bottom: 1px solid #e3e5e8;
  font-size: 22px;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

const Title = ({ label }) => {
  return <ComponentContainer>{label}</ComponentContainer>;
};

Title.defaultProps = {
  label: 'Title'
};

Title.propTypes = {
  label: PropTypes.string
};

export default Title;
