import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { format, toDate } from 'date-fns-tz';

const TimeZoneSelector = ({
  defaultTimezoneSelectedParam,
  indexParam,
  clientParam,
  onTimeZoneChange
}) => {
  const [timeZones, setTimeZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);

  // Obtener el offset de una zona horaria
  const getOffset = timeZone => {
    const now = new Date();
    const zonedDate = toDate(now, { timeZone });
    return format(zonedDate, 'XXX', { timeZone });
  };

  // Cargar zonas horarias y asignar el valor predeterminado
  useEffect(() => {
    const ianaTimeZones = [
      { label: `Etc/UTC (UTC+00:00)`, value: 'UTC' },
      ...Intl.supportedValuesOf('timeZone').map(zone => ({
        label: `${zone} (UTC${getOffset(zone)})`,
        value: zone
      }))
    ];

    setTimeZones(ianaTimeZones);

    // Solo actualiza si hay un valor predeterminado y aún no se ha seleccionado una zona horaria
    if (defaultTimezoneSelectedParam && !selectedZone) {
      const initialZone = ianaTimeZones.find(
        zone => zone.value === defaultTimezoneSelectedParam
      );

      // Verifica que la zona horaria predeterminada exista
      if (initialZone) {
        setSelectedZone(initialZone);
      }
    }
  }, [defaultTimezoneSelectedParam, selectedZone]);

  // Maneja el cambio en la selección
  const handleChange = selectedOption => {
    setSelectedZone(selectedOption);

    if (onTimeZoneChange) {
      onTimeZoneChange(clientParam, {
        target: { name: 'timeZone', value: selectedOption.value }
      });
    }
  };

  return (
    <Select
      id={`timezoneSelect-${indexParam}`}
      name="timeZone"
      options={timeZones}
      value={selectedZone} // Valor que debe estar sincronizado con defaultTimezoneSelectedParam
      onChange={handleChange}
      placeholder="Select your time zone..."
      isSearchable
      classNamePrefix="react-select"
    />
  );
};

export default TimeZoneSelector;
