import React, { Component } from 'react';
//import autobind from 'autobind';

export interface SwitchButtonProps {
  label?: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export interface SwitchButtonState {}

class SwitchButton extends Component<SwitchButtonProps, SwitchButtonState> {
  constructor(props: SwitchButtonProps) {
    super(props);
    this.onValueChanged = this.onValueChanged.bind(this);
  }

  //@autobind
  onValueChanged(event: any) {
    if (this.props.onChange) this.props.onChange(event.target.checked);
  }

  render() {
    return (
      <div className="switch-container">
        <label>
          <input
            ref="switch"
            checked={this.props.value}
            onChange={this.onValueChanged}
            className="switch"
            type="checkbox"
          />
          <div>
            <div></div>
          </div>
        </label>
        <div className="switch-label">{this.props.label}</div>
      </div>
    );
  }
}

export default SwitchButton;
