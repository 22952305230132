import React, { Component } from 'react';
import Markerv02 from './Marker/Markerv02';

class FleetMapv02 extends Component {
  constructor(props) {
    super(props);
    const { google, points } = props;
    this.google = google;
    this.points = points;
    this.clickMarker = this.props.clickMarker;
    this.initialPosition = {
      lat: 0,
      lng: 0
    };
    this.state = {
      map: null,
      showHeatMaps: true,
      showMarkers: true
    };
    this.heatMap = null;
  }

  componentDidMount() {
    const map = this.setUpMap();
    this.setState({ map: map });
  }

  setGradient = () => {
    return [
      'rgba(0, 255, 255, 0)',
      'rgba(0, 255, 255, 1)',
      'rgba(0, 191, 255, 1)',
      'rgba(0, 127, 255, 1)',
      'rgba(15, 88, 214, 1)',
      'rgba(15, 88, 214, 1)',
      'rgba(15, 88, 214, 1)',
      'rgba(15, 88, 214, 1)',
      'rgba(15, 88, 214, 1)',
      'rgba(15, 88, 214, 1)',
      'rgba(98, 46, 112, 1)',
      'rgba(98, 46, 112, 1)',
      'rgba(98, 46, 112, 1)',
      'rgba(98, 46, 112, 1)'
    ];
  };

  setUpMap() {
    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 5,
      center: this.initialPosition,
      zoomControl: true,
      scaleControl: false,
      streetViewControl: false,
      panControl: false,
      rotateControl: false,
      fullscreenControl: true,
      mapTypeControl: false
    });
    return map;
  }

  getPointsHeatMap = heatMapPoints => {
    let newHeatMapPoints = [];
    heatMapPoints
      .filter(point => point !== undefined && point !== null)
      .map(heatMapPoint => {
        return heatMapPoint.map(point => newHeatMapPoints.push(point));
      });

    return newHeatMapPoints;
  };

  getGooglePointsHeatMap = heatMapPoints => {
    const heatMapPointsGoogle = [];
    heatMapPoints
      .filter(point => point !== undefined && point !== null)
      .map(heatMapPoint => {
        return heatMapPoint.map(point =>
          heatMapPointsGoogle.push(new google.maps.LatLng(point.lat, point.lng))
        );
      });

    return heatMapPointsGoogle;
  };

  getMarkersMap = LastInfoGps => {
    const markers = [];
    if (!LastInfoGps) return markers;
    LastInfoGps.map(marker => {
      markers.push({
        point: {
          lat: marker.point.latitude,
          lng: marker.point.longitude
        },
        date: marker.point.timestamp,
        alarm: marker.alarm,
        vehicle: marker.vehicle
      });
    });
    return markers;
  };

  setBoundsToMap(markers, map) {
    var bounds = new this.props.google.maps.LatLngBounds();
    if (markers && markers.length) {
      markers.map(point => {
        bounds.extend({ lat: point.lat, lng: point.lng });
      });
    }
    map.fitBounds(bounds);
  }

  setStyleToMap(map) {
    // map.setOptions({ styles:
    //  [
    //   {
    //     "featureType": "all",
    //     "elementType": "labels.text",
    //     "stylers": [
    //       {
    //         "visibility": "off"
    //       }
    //     ]
    //   },
    //   {
    //     "featureType": "all",
    //     "elementType": "labels.icon",
    //     "stylers": [
    //       {
    //         "visibility": "off"
    //       }
    //     ]
    //   }
    // ]
    // });
  }

  addHeatMapLayerToMap(heatMapPointsArr, map) {
    const gradient = this.setGradient();
    // remove previous heatMaps, otherwise they keep adding up on the map
    if (this.heatMap !== null) {
      this.heatMap.setMap(null);
      this.heatMap.getData().j = [];
    }
    this.heatMap = new google.maps.visualization.HeatmapLayer({
      data: heatMapPointsArr,
      map: map,
      gradient: gradient,
      radius: 5,
      opacity: 1
    });
    this.heatMap.setMap(map);
  }

  handleClickOnMarker = vehicle => {
    // this.clickMarker(vehicle.Vehicle.Reference, vehicle.Vehicle.Designation);

    this.clickMarker(vehicle.reference, vehicle.reference);
  };
  toggleHeatMaps = () => {
    this.setState({
      showHeatMaps: !this.state.showHeatMaps
    });
  };
  toggleMarkers = () => {
    this.setState({
      showMarkers: !this.state.showMarkers
    });
  };

  render() {
    const { google } = this.props;
    if (!this.state.showHeatMaps || this.props.heatMapPoints.length === 0) {
      // remove previous heatMaps, otherwise they keep adding up on the map
      if (this.heatMap !== null) {
        this.heatMap.setMap(null);
        this.heatMap.getData().j = [];
      }
    } else if (
      this.state.map &&
      this.props.markers &&
      this.props.heatMapPoints.length > 0
    ) {
      const { map } = this.state;
      // markers = this.getMarkersMap(LastInfoGps);
      const heatMapGooglePointsArr = this.getGooglePointsHeatMap(
        this.props.heatMapPoints
      );
      this.addHeatMapLayerToMap(heatMapGooglePointsArr, map);
      const heatMapPointsArr = this.getPointsHeatMap(this.props.heatMapPoints);
      this.setBoundsToMap(heatMapPointsArr, map);

      this.setStyleToMap(map);
    }

    return (
      <div ref={el => (this._googleMap = el)} className="map" id="map">
        <Markerv02
          google={google}
          map={this.state.map}
          markers={this.state.showMarkers ? this.props.markers : false}
          handleClickOnMarker={this.handleClickOnMarker}
          toggleHeatMaps={this.toggleHeatMaps}
          toggleMarkers={this.toggleMarkers}
        />
      </div>
    );
  }
}

export default FleetMapv02;
