import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div>
    <center>
      <div className="errorContainer">
        <br></br>
        <br></br>
        <h3>We are sorry,this is not the page you are looking</h3>
        <br></br>
        <br></br>
        <center>
          <Link to="/dashboard">
            {' '}
            <button type="button" className="btn btn-primary">
              Back to Rail CoMo dashboard
            </button>
          </Link>
        </center>
        <br></br> <br></br>
        <h3>Please, use the top bar navigation</h3>
        <br></br>
        <br></br>
      </div>
    </center>
  </div>
);
export default NotFound;
