import React, { Component } from 'react';
import SkfModal from '../reports/SkfModal';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { fleetService } from '../../services/fleet.service';
import { toast } from 'react-toastify';

interface IEventLog {
  userName: string;
  eventCaseId: string;
  comment: string;
  dateTime: Date;
  status: string;
  color: string;
}

export interface IEventLogProps {
  eventReference: string;
  isOpen: boolean;
  closeModal: any;
  assetStatus: number;
  createdOn: string;
  createdBy: string;
  categorys: any;
  category: any;
  setCategorys: any;
}

export interface StateProps {
  events: IEventLog[];
  isLoading: boolean;
  page: number;
  numPerPage: number;
  pages: number;
  category: string;

  newCategory: boolean;
}

class ModalDeleteCategoy extends Component<IEventLogProps, StateProps> {
  constructor(props: any) {
    super(props);
  }

  public readonly state: Readonly<StateProps> = {
    events: [],
    isLoading: true,
    page: 0,
    numPerPage: 10,
    pages: 0,
    newCategory: false
  };

  beforePage = () => {
    let page = this.state.page;
    page = page - 1;
    if (page < 0) {
      page = 0;
    }
    this.setState({
      page
    });
  };

  nextPage = () => {
    let lastPage = this.state.pages - 1;
    let page = this.state.page;
    page = page + 1;
    if (page > lastPage) {
      page = lastPage + 1;
    }
    this.setState({
      page
    });
  };

  setnewCategory = () => {
    this.setState({ newCategory: !this.state.newCategory });
  };

  deleteCategory = () => {
    fleetService
      .deleteDocumentcategory(
        this.props.category && this.props.category.category
      )
      .then(e => {
        this.props.setCategorys(e.data);
        toast.success('Category deleted.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 6000
        });
        this.props.closeModal();
      });
  };

  render() {
    return (
      <SkfModal
        size="small"
        isOpen={this.props.isOpen}
        handleClose={this.props.closeModal}
      >
        <SkfModal.Header handleClose={this.props.closeModal}>
          ¿Are you sure to delete{' '}
          {this.props.category && this.props.category.category}?
        </SkfModal.Header>
        <SkfModal.Content>
          <Form>
            <Row>
              {' '}
              <Button
                variant="primary"
                style={{ marginRight: 10 }}
                onClick={this.deleteCategory}
              >
                Delete
              </Button>{' '}
              <Button variant="" onClick={this.props.closeModal}>
                Cancel
              </Button>{' '}
            </Row>
            <br />
          </Form>
        </SkfModal.Content>
      </SkfModal>
    );
  }
}

export default ModalDeleteCategoy;
