import { authHeader } from '../helpers';
import { customError, catchError } from './error.service';
import axios from 'axios';
export const axleboxService = {
  getSensorByReference
};
function getSensorByReference(hash, vehicleHash) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Sensors/GetSensorByAsset/${hash}/vehicle/${vehicleHash}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}