import { Observable, from, of } from 'rxjs';
import { filter, switchMap, map, catchError, mapTo } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ApplicationState } from '../reducers';
import { Services } from '../services';
import { StateObservable, combineEpics } from 'redux-observable';
import {
  fetchLocationDashboard,
  TrainsDashboardMapAsyncAction
} from '../actions/trainDashboardMapAsyncAction';
import { fetchTrainDashboard } from '../actions/trainsDashboardAsyncAction';

const fetchLocationOnFetchTrainDashboardEpicCreator = () => (
  action$: Observable<TrainsDashboardMapAsyncAction>,
  _state$: StateObservable<ApplicationState>,
  services: Services
) =>
  action$.pipe(
    filter(isActionOf(fetchTrainDashboard)),
    mapTo(fetchLocationDashboard())
  );

export const fetchLocationDashboardEpicCreator = () =>
  combineEpics(...[fetchLocationOnFetchTrainDashboardEpicCreator()]);
