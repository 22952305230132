import React, { Component } from 'react';
import SkfModal from '../reports/SkfModal';
import { fleetService } from '../../services/fleet.service';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

interface IEventLog {
  userName: string;
  eventCaseId: string;
  comment: string;
  dateTime: Date;
  status: string;
  color: string;
}

export interface IEventLogProps {
  eventReference: string;
  isOpen: boolean;
  closeModal: any;
  assetStatus: number;
  createdOn: string;
  createdBy: string;
  categorys: any;
  category: any;
  setCategorys: any;
}

export interface StateProps {
  events: IEventLog[];
  isLoading: boolean;
  page: number;
  numPerPage: number;
  pages: number;
  category: string;
  position: string;
  status: string;
  disableButton: Boolean;
}

class ModalNewCategoy extends Component<IEventLogProps, StateProps> {
  constructor(props: any) {
    super(props);
  }

  public readonly state: Readonly<StateProps> = {
    events: [],
    isLoading: true,
    page: 0,
    numPerPage: 10,
    pages: 0,
    category: '',
    position: '0',
    status: 'ENABLED',
    disableButton: false
  };

  beforePage = () => {
    let page = this.state.page;
    page = page - 1;
    if (page < 0) {
      page = 0;
    }
    this.setState({
      page
    });
  };

  nextPage = () => {
    let lastPage = this.state.pages - 1;
    let page = this.state.page;
    page = page + 1;
    if (page > lastPage) {
      page = lastPage + 1;
    }
    this.setState({
      page
    });
  };

  restePage = () => {
    this.setState({
      category: '',
      position: '0',
      status: 'ENABLED',
      disableButton: false
    });
  };

  addCategory = () => {
    if (this.state.category && this.state.category != '') {
      fleetService
        .postDocumentcategory(
          this.state.category,
          this.state.position,
          this.state.status
        )
        .then(e => {
          this.props.setCategorys(e.data);
          toast.success('Category added.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 6000
          });
          this.restePage();
          this.props.closeModal();
        });
    } else {
      toast.info('Add Category name first', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 6000
      });
    }
  };

  closeModal = () => {
    this.restePage();
    this.props.closeModal();
  };

  updateCategory = () => {
    fleetService
      .putDocumentcategory(
        this.state.category,
        this.props.category.position,
        this.state.position,
        this.state.status
      )
      .then(e => {
        this.props.setCategorys(e.data);
        toast.success('Category added.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 6000
        });
        this.restePage();
        this.props.closeModal();
      });
    this.setState({ disableButton: true });
  };

  render() {
    if (
      this.props.category &&
      (this.state.category === undefined || this.state.category === '')
    ) {
      this.setState({
        category: this.props.category.category,
        position: this.props.category.position,
        status: this.props.category.status
      });
    }

    return (
      <SkfModal
        size="small"
        isOpen={this.props.isOpen}
        handleClose={this.closeModal}
      >
        <SkfModal.Header handleClose={this.closeModal}>
          Category Information
        </SkfModal.Header>
        <SkfModal.Content>
          <Form>
            <Form.Group
              sm="6"
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="2">
                Category
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  readOnly={this.props.category ? true : false}
                  type="text"
                  placeholder="Category"
                  onChange={e => this.setState({ category: e.target.value })}
                  value={this.state.category}
                />
              </Col>
            </Form.Group>
            <Form.Group
              sm="6"
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="2">
                Position
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  name="selectedToBucket"
                  onChange={e => this.setState({ position: e.target.value })}
                  value={this.state.position}
                >
                  {this.props.categorys.map((e: any) => (
                    <option value={e.position}>{e.position}</option>
                  ))}
                  <option value={this.props.categorys.length}>
                    {this.props.categorys.length}
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Row>
              <Col sm="5">
                <Form.Group
                  sm="6"
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Form.Check
                    checked={this.state.status === 'ENABLED'}
                    type="checkbox"
                    label="Enabled"
                    onChange={e =>
                      this.setState({
                        status:
                          this.state.status === 'ENABLED'
                            ? 'DISABLED'
                            : 'ENABLED'
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col sm="5">
                {' '}
                <Button
                  variant="primary"
                  disabled={this.state.disableButton}
                  onClick={() =>
                    this.props.category
                      ? this.updateCategory()
                      : this.addCategory()
                  }
                >
                  Save
                </Button>{' '}
              </Col>
            </Row>
            <br />
          </Form>
        </SkfModal.Content>
      </SkfModal>
    );
  }
}

export default ModalNewCategoy;
