import { apiService, HTTPMethods } from '../services/apiService';
import { EventCase } from '../models/eventCase';

export const postEventCase = async (eventCase: EventCase) => {
  var response = await apiService(
    `/api/Feedback/PostActionStatus`,
    HTTPMethods.POST,
    eventCase
  );
  if (response.status === 200) {
    return Promise.resolve(response.text());
  }
};

export const getEventCase = async (sourceKey: string) => {
  var response = await apiService(
    `/api/Feedback/GetActionStatus/${sourceKey}`,
    HTTPMethods.GET
  );
  if (response.status === 200) {
    return Promise.resolve(response.json());
  }
};

export const getEventLogs = async (sourceKey: string) => {
  var response = await apiService(
    `/api/Feedback/GetEventLogs/sourceKey/${sourceKey}`,
    HTTPMethods.GET
  );
  if (response.status === 200) {
    return Promise.resolve(response.json());
  }
};
