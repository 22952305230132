export const getEventtKeySelector = (axleboxEvent: any) => {
  var key =
    axleboxEvent.measurementType === 'Temperature'
      ? axleboxEvent.activeAlarm +
        ' ' +
        axleboxEvent.alarmType +
        ' - ' +
        axleboxEvent.sourceId
      : axleboxEvent.caseNoPrefix
      ? axleboxEvent.caseNoPrefix +
        '-' +
        axleboxEvent.eventCaseID +
        '-' +
        axleboxEvent.reportID
      : axleboxEvent.eventCaseID + '-' + axleboxEvent.reportID;

  return key;
};
