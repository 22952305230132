import { Observable, from, of } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ApplicationState } from '../reducers';
import { Services } from '../services';
import { StateObservable } from 'redux-observable';
import {
  TrainsDashboardAsyncAction,
  fetchTrainDashboard,
  fetchTrainDashboardFulfilled,
  fetchTrainDashboardFailed
} from '../actions/trainsDashboardAsyncAction';

export const fetchTrainDashboardEpicCreator = () => (
  action$: Observable<TrainsDashboardAsyncAction>,
  state$: StateObservable<ApplicationState>,
  { trainDashboardApi }: Services
) =>
  action$.pipe(
    filter(isActionOf(fetchTrainDashboard)),
    switchMap(() =>
      from(trainDashboardApi.getTrains()).pipe(
        map(trains => fetchTrainDashboardFulfilled('ok', trains)),
        catchError((message: string) => of(fetchTrainDashboardFailed('ko')))
      )
    )
  );
