import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { menu } from './menu.reducer';
import { fleet } from './fleet.reducer';
import { carrier } from '../reducers/carrier.reducer';
import { createRootReducer } from '../reducers/createRootReducer';
import timeZoneReducer from '../helpers/timeZone/timeZoneReducer';
const newReducers = createRootReducer();
const rootReducer = combineReducers({
  menu,
  authentication,
  fleet,
  carrier,
  newReducers,
  timeZone: timeZoneReducer
});
export default rootReducer;