const initialState = {
  hashes: {
    dashboardToVehicles: '',
    vehiclesToHistory: ''
  },
  date: {
    vehicleToHistoryDate: new Date()
  }
};
export function carrier() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const {
    type,
    payload,
    date
  } = action;
  switch (type) {
    case 'SET_HASH_DASHBOARD_TO_VEHICLES':
      return {
        ...state,
        hashes: {
          ...state.hashes,
          dashboardToVehicles: payload
        }
      };
    case 'SET_DATA_VEHICLES_TO_HISTORY':
      return {
        ...state,
        hashes: {
          ...state.hashes,
          vehiclesToHistory: payload
        },
        date: {
          vehicleToHistoryDate: date
        }
      };
    default:
      return state;
  }
}