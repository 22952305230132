import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { merge, of as ofObservable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RootAction } from 'typesafe-actions';
import { ApplicationState } from '../reducers';

export const wrapErrors = (epic: Epic) => (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<ApplicationState>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies: any
) =>
  epic(action$, state$, dependencies).pipe(
    catchError((error, source) =>
      // eslint-disable-next-line no-console
      merge(source, ofObservable(console.error(error.toString(), error.stack)))
    )
  );
