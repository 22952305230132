import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'rc-tree-select/assets/index.css';
import TreeSelect, { SHOW_PARENT } from 'rc-tree-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import './treeSelect.css';

const ComponentContainer = styled.div`
  width: 105%;
  position: relative;
`;
const SpinnerContainer = styled.div`
  margin-top: 15px;
`;

const TreeSelectContainer = styled(TreeSelect)`
  width: 100%;
  min-width: 15.5vw;
  .rc-tree-select-tree li span.rc-tree-select-tree-switcher_open {
    display: none;
  }
`;

const TreeSingleSelectContainer = styled(TreeSelect)`
  width: 100%;

  min-width: 15.5vw;
  .rc-tree-select-tree li span.rc-tree-select-tree-switcher_open {
    display: none;
  }

  .rc-tree-select-arrow {
    display: none;
  }
`;

const TreeSingleSelectContainerHistory = styled(TreeSelect)`
  width: auto;
  min-width: 15.5vw;
  .rc-tree-select-tree li span.rc-tree-select-tree-switcher_open {
    display: none;
  }
  .rc-tree-select-arrow {
    display: none;
  }
`;

const IconContainer = styled(FontAwesomeIcon)`
  margin-top: -14px;
  margin-left: 5px;
  color: lightgray;
`;

const ArrowDownContainer = styled(IconContainer)`
  font-size: 17px;
  pointer-events: none;
`;

const ArrowContainer = styled.span`
  position: absolute;
  right: 17px;
  top: ${props => (props.downArrow ? '25px' : '25px')};
  pointer-events: none;
`;

const TagContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TagSelector = styled.div`
  display: inline-flex;
  padding: 5px 10px;
  border: 1px solid darkgrey;
  border-radius: 10px;
  background-color: #fefefe;
  cursor: pointer;
  &:hover {
    background-color: #f3f3f3;
  }
  margin: 5px 10px 5px 0;
`;

class TreeSelectComponent extends Component {
  state = {
    tsOpen: false,
    visible: false,
    valueSelected: [],
    searchValue: '',
    lv: { value: '0-0-0-value', title: 'spe label' },
    multipleValue: [],
    simpleSearchValue: '',
    simpleTreeData: [
      { key: 'Electric', pId: 0, title: 'Electric', value: 'Electric' },
      { key: 'Hidraulic', pId: 0, title: 'Hidraulic', value: 'Hidraulic' },
      { key: 'vehicleA', pId: 'Electric', title: 'Vehicle A', value: 'A' },
      { key: 'vehicleB', pId: 'Electric', title: 'Vehicle B', value: 'B' },
      { key: 'vehicleC', pId: 'Hidraulic', title: 'Vehicle C', value: 'C' },
      { key: 'Hybrid', pId: 0, title: 'Hybrid', value: 'Hybrid' }
    ],
    treeDataSimpleMode: {
      id: 'key',
      rootPId: 0
    }
  };

  element = (
    <span>
      <FontAwesomeIcon icon={faChevronDown} />
    </span>
  );

  onChange = (value, ...rest) => {
    this.props.onChange({ value, ...rest });
  };

  onSelect = (...args) => {
    // use onChange instead
    // console.log(args);
  };

  onBubbleClick = bubble => {
    this.setState({
      value: this.state.value.filter(val => bubble !== val)
    });
  };

  render() {
    const { value, simpleSearchValue, treeDataSimpleMode } = this.state;

    let {
      skf_TreeData,
      title,
      singleSelect,
      historySelect,
      spinner,
      downArrow,
      placeholder,
      valuesSelected
    } = this.props;

    skf_TreeData.forEach(z => {
      z.value = z.key;
    });
    skf_TreeData = skf_TreeData.filter(e => !e.isHidden);

    return (
      <ComponentContainer>
        <div style={{ minHeight: 18 }}>{title}</div>
        {spinner ? (
          <SpinnerContainer
            className="spinner-border spinner-border-sm skf_tiny_spinner"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </SpinnerContainer>
        ) : historySelect ? (
          <TreeSingleSelectContainerHistory
            dropdownStyle={{ maxHeight: 200, overflow: 'auto' }}
            placeholder={<span>{placeholder}</span>}
            searchValue={simpleSearchValue}
            onSearch={val => {
              this.setState({ simpleSearchValue: val });
            }}
            value={'Search or Select'}
            treeData={skf_TreeData}
            treeNodeFilterProp="title"
            onChange={this.onChange}
            onSelect={this.onSelect}
            notFoundContent="Fetching data..."
            treeLine
            treeDataSimpleMode={treeDataSimpleMode}
            showCheckedStrategy={SHOW_PARENT}
          />
        ) : singleSelect ? (
          <TreeSingleSelectContainer
            dropdownStyle={{ maxHeight: 200, overflow: 'auto' }}
            placeholder={<span>{placeholder}</span>}
            treeNodeFilterProp="title"
            searchValue={simpleSearchValue}
            onSearch={val => {
              this.setState({ simpleSearchValue: val });
            }}
            value={valuesSelected}
            treeData={skf_TreeData}
            onChange={this.onChange}
            onSelect={this.onSelect}
            notFoundContent="Fetching data..."
            treeLine
            treeDataSimpleMode={treeDataSimpleMode}
            showCheckedStrategy={SHOW_PARENT}
          />
        ) : (
          <TreeSingleSelectContainer
            dropdownStyle={{ maxHeight: 200, overflow: 'auto' }}
            placeholder={
              this.state.simpleSearchValue !== '' ? (
                ''
              ) : (
                <span>{placeholder}</span>
              )
            }
            searchValue={simpleSearchValue}
            onSearch={val => {
              this.setState({ simpleSearchValue: val });
            }}
            value={valuesSelected}
            treeData={skf_TreeData}
            treeNodeFilterProp="title"
            treeDataSimpleMode={treeDataSimpleMode}
            treeCheckable
            showCheckedStrategy={SHOW_PARENT}
            onChange={this.onChange}
            onSelect={this.onSelect}
            treeLine
            allowClear
            notFoundContent="Fetching data..."
          />
        )}
        {spinner ? (
          ''
        ) : (
          <ArrowContainer downArrow={downArrow}>
            <ArrowDownContainer icon={faChevronDown} />
          </ArrowContainer>
        )}

        <TagContainer>
          {value &&
            value.length > 0 &&
            value.map(bubble => {
              return (
                <TagSelector
                  onClick={() => this.onBubbleClick(bubble)}
                  key={bubble}
                >
                  {bubble} <IconContainer icon={faTimesCircle} />
                </TagSelector>
              );
            })}
        </TagContainer>
      </ComponentContainer>
    );
  }
}

TreeSelectComponent.propTypes = {
  onChange: PropTypes.func,
  skf_TreeData: PropTypes.array,
  title: PropTypes.string,
  downArrow: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

TreeSelectComponent.defaultProps = {
  onChange: () => null,
  skf_TreeData: [],
  title: '',
  value: [],
  downArrow: false
};

export default TreeSelectComponent;
