import React, { Component, Fragment } from 'react';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Input, InputAdornment } from '@material-ui/core';

const InputText = styled(Input)`
  width: 100%;
`;

const RowTopMargin = styled(Row)`
    margin-top: 10px;
`;
interface InputTextProps {
  inputText: string | number;
  titleText: string;
  onChange: Function;
  type?: string;
  cKey?: number;
}

class InputTextComponent extends Component<InputTextProps> {
  constructor(props: any) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  render() {
    return (
      <>
        <RowTopMargin>
          <b>{this.props.titleText}</b>
        </RowTopMargin>
        <Row>
          <Col md="12" sm="12" xs="12">
            <InputText
              disableUnderline
              className="skf_textinput"
              endAdornment={
                <InputAdornment position="end">
                  <FontAwesomeIcon color="#0f58d6" icon={faPen} />
                </InputAdornment>
              }
              type={this.props.type}
              defaultValue={this.props.inputText}
              value={this.props.inputText}
              onChange={this.onChange}
            />
          </Col>
        </Row>
      </>
    );
  }

  onChange(e: any) {
    var number = e.target.value;
    if (!isNaN(number) || this.props.type === 'text') {
      this.props.onChange(number, this.props.cKey);
    }
  }
}

export default InputTextComponent;
