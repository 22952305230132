import {
  fetchTrainDashboardAsyncReducer,
  TrainDashboardState
} from './trainDashboardAsyncReducer';
import { combineReducers } from 'redux';
import {
  TrainDashboardMapState,
  fetchLocationDashboardAsyncReducer
} from './trainDashboardMapAsyncReducer';


type OwnReducersState = TrainDashboardState &
  TrainDashboardMapState;

export interface ApplicationState {
  newReducers: OwnReducersState;
}

export const createRootReducer = () =>
  combineReducers({
    fetchTrainDashboardAsyncReducer,
    fetchLocationDashboardAsyncReducer,
  });
