import React from 'react';

const dashboardSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <path
        id="aDasboard"
        d="M1 11h8V1H1v10zm0 8h8v-6H1v6zm10 0h8V9h-8v10zm0-18v6h8V1h-8z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillRule="nonzero" d="M-727-879H381V995H-727z" />
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#aDasboard" />
      <g fill="#273342" mask="url(#b)">
        <circle cx="50" cy="50" r="50" transform="translate(-40 -39)" />
      </g>
    </g>
  </svg>
);

const fleetSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <path
        id="aFleet"
        d="M11.2 17H4.8L3 20H1l1.804-3.006A3 3 0 0 1 0 14V3a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v11a3 3 0 0 1-2.804 2.994L15 20h-2l-1.8-3zm-6.7-2a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm7 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM2 4l.857 4L8 9l5.143-1L14 4H2z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillRule="nonzero" d="M-725-632H383v1874H-725z" />
      <g transform="translate(2)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#273342" xlinkHref="#aFleet" />
        <g fill="#273342" mask="url(#b)">
          <circle cx="50" cy="50" r="50" transform="translate(-42 -40)" />
        </g>
      </g>
    </g>
  </svg>
);

const reportsSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <path
        id="aReports"
        d="M4 0h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4zM2.857 5.714V7.62h1.905V5.714H2.857zm0 3.81v1.905h1.905V9.524H2.857zm0 3.81v1.904h1.905v-1.905H2.857zm3.81-7.62V7.62h10.476V5.714H6.667zm0 3.81v1.905h10.476V9.524H6.667zm0 3.81v1.904h10.476v-1.905H6.667z"
      />
    </defs>
    <use fill="#000" fillRule="evenodd" xlinkHref="#aReports" />
  </svg>
);

const historySVG = (
  <svg
    version="1.1"
    id="aHistory"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    enableBackground="new 0 0 30 30"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill="#000000"
        d="M3,11c-0.553,0-1,0.448-1,1v8c0,0.553,0.447,1,1,1s1-0.447,1-1v-8C4,11.448,3.553,11,3,11z"
      />
      <path
        fill="#000000"
        d="M7,14c-0.553,0-1,0.448-1,1v10c0,0.553,0.447,1,1,1s1-0.447,1-1V15C8,14.448,7.553,14,7,14z"
      />
      <path
        fill="#000000"
        d="M11,8c-0.553,0-1,0.448-1,1v13c0,0.553,0.447,1,1,1s1-0.447,1-1V9C12,8.448,11.553,8,11,8z"
      />
      <path
        fill="#000000"
        d="M15,2c-0.553,0-1,0.448-1,1v24c0,0.553,0.447,1,1,1s1-0.447,1-1V3C16,2.448,15.553,2,15,2z"
      />
      <path
        fill="#000000"
        d="M19,7c-0.553,0-1,0.448-1,1v15c0,0.553,0.447,1,1,1s1-0.447,1-1V8C20,7.448,19.553,7,19,7z"
      />
      <path
        fill="#000000"
        d="M23,9c-0.553,0-1,0.448-1,1v9c0,0.553,0.447,1,1,1s1-0.447,1-1v-9C24,9.448,23.553,9,23,9z"
      />
      <path
        fill="#000000"
        d="M27,11c-0.553,0-1,0.448-1,1v9c0,0.553,0.447,1,1,1s1-0.447,1-1v-9C28,11.448,27.553,11,27,11z"
      />
    </g>
  </svg>
);

export { dashboardSVG, reportsSVG, fleetSVG, historySVG };
