export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  ASK_RESET_TOKEN_REQUEST: 'ASK_RESET_TOKEN_REQUEST',
  ASK_RESET_TOKEN_SUCCESS: 'ASK_RESET_TOKEN_SUCCESS',
  ASK_RESET_TOKEN_FAILURE: 'ASK_RESET_TOKEN_FAILURE',
  RESET_WITH_TOKEN_REQUEST: 'RESET_WITH_TOKEN_REQUEST',
  RESET_WITH_TOKEN_SUCCESS: 'RESET_WITH_TOKEN_SUCCESS',
  RESET_WITH_TOKEN_FAILURE: 'RESET_WITH_TOKEN_FAILURE',
  CHANGE_PWD_REQUEST: 'CHANGE_PWD_REQUEST',
  CHANGE_PWD_SUCCESS: 'CHANGE_PWD_SUCCESS',
  CHANGE_PWD_FAILURE: 'CHANGE_PWD_FAILURE',
  LOGOUT: 'USERS_LOGOUT',
  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE'
};