import React from 'react';

// icons
import SensorIconSource from '../../../../../../src/assets/img/ic_sensor.png';
import VibrationIconSource from '../../../../../../src/assets/img/ic_vibration.png';
import TemperatureIconSource from '../../../../../../src/assets/img/ic_temperature.png';


interface IVehicleEventsIconsProps {
  vibrationEvens: number;
  sensorEvents: number;
  temperatureEvents: number;
}

const VehicleEventsIcons = (props: IVehicleEventsIconsProps) => {
  let icons: JSX.Element[] = [];
  if (props.temperatureEvents > 0)
    icons = [
      ...icons,
      <img
        className="vehicleEventIcon"
        src={TemperatureIconSource}
        title="Temperature event"
        alt="Temperature event icon"
        key="1"
      />
    ];
  if (props.vibrationEvens > 0)
    icons = [
      ...icons,
      <img
        className="vehicleEventIcon"
        src={VibrationIconSource}
        title="Vibration event"
        alt="Vibration event icon"
        key="2"
      />
    ];
  if (props.sensorEvents > 0)
    icons = [
      ...icons,
      <img
        className="vehicleEventIcon"
        src={SensorIconSource}
        title="Sensor event"
        alt="Sensor event icon"
        key="3"
      />
    ];

  return <>{icons.map(Icon => Icon)}</>;
};

export default VehicleEventsIcons;
