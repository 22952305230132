import { fleetService } from '../services/fleet.service';
export const fromVehicleToHistory = action => dispatch => {
  dispatch({
    type: 'TO_HISTORY',
    payload: action
  });
};
export const cleanFromVehicleToHistory = () => dispatch => {
  dispatch({
    type: 'CLEAN_TO_HISTORY'
  });
};
export const forceUpdateFleet = () => dispatch => {
  dispatch({
    type: 'FORCE_UPDATE_FLEET'
  });
};
export const getHierarchy = () => async dispatch => {
  const response = await fleetService.fleet();
  dispatch({
    type: 'GET_HIERARCHY',
    payload: response
  });
};
export const cleanHierarchy = () => dispatch => {
  dispatch({
    type: 'CLEAN_HIERARCHY'
  });
};
export const setHashDashboardToVehicles = payload => dispatch => {
  dispatch({
    type: 'SET_HASH_DASHBOARD_TO_VEHICLES',
    payload
  });
};
export const setDataVehiclesToHistory = (payload, date) => dispatch => {
  dispatch({
    type: 'SET_DATA_VEHICLES_TO_HISTORY',
    payload,
    date
  });
};