import React from 'react';
/**
 * Red Screen Error at APP component
 * getDerivedStateFromError() is called during the “render” phase - side-effects are not permitted
 * componentDidCatch() is called during the “commit” phase - side effects permitted
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  /**
   * If there is an error during render, it sets hasError to true, triggering this Component.
   */
  static getDerivedStateFromError(error) {
    // TODO hasError set to false for development purposes, come back to true to active again this componente
    return { hasError: false }; // return { hasError: true };
  }

  componentDidCatch() {
    return null;
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="alert alert-danger">
          Something went wrong. Please try reloading the app
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
