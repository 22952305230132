import React from 'react';
import { connect } from 'react-redux';
import { history } from '../helpers';
import { cleanHierarchy } from '../actions';

import {Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,  } from '@fortawesome/free-solid-svg-icons';
import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitted: false,
      validated: false
    };
    this.intervalId = undefined;
  }

  getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  checkLogIn = () => {
    this.props.dispatch(cleanHierarchy());
    let chyperTken = this.getCookie('tokenSKF');

    if (chyperTken != '') {
      let expiresInSKF = this.getCookie('expiresInSKF');
      var bytes = AES.decrypt(
        chyperTken,
        ')J@NcRfUjXn2r5u8x/A%D*G-KaPdSgVkYp3s6v9y$B&E(H+MbQeThWmZq4t7w!z%'
      );
      var decryptedData = enc.stringify(bytes);

      if (Date.now() < expiresInSKF && decryptedData && decryptedData != '') {
        sessionStorage.setItem('loggedIn', true);
        sessionStorage.setItem(
          'idToken',
          'Bearer ' + decryptedData.replace('"', '').replace('"', '')
        );
        sessionStorage.setItem('expiresIn', expiresInSKF);
        clearInterval(this.intervalId);
        this.intervalId = undefined;
        history.push('/dashboard');
      } else {
        if (!this.intervalId)
          this.intervalId = window.setInterval(() => {
            this.checkLogIn();
          }, 1000);

        sessionStorage.setItem('loggedIn', false);
        sessionStorage.setItem('idToken', '');
        sessionStorage.setItem('tokenType', '');
        sessionStorage.setItem('refreshToken', '');
        sessionStorage.setItem('username', '');
        sessionStorage.setItem('expiresIn', '');
        //       this.props.showHeader(false);
      }
    } else if (!this.intervalId)
      this.intervalId = window.setInterval(() => {
        this.checkLogIn();
      }, 1000);
  };

  takeUrl = () => {
    const queryString = window.location.search;

    if (queryString.split('?tokenSKF=')[1]) {
      let query = queryString.split('?tokenSKF=')[1];
      let token = query.split('&expiresInSKF=')[0];
      let expired = query.split('&expiresInSKF=')[1];
      document.cookie = 'tokenSKF=' + token;
      document.cookie = 'expiresInSKF=' + expired;
    }
  };

  componentDidMount() {
    this.takeUrl();
    this.checkLogIn();
  }

  handleSubmit = e => {
    let url = process.env.REACT_APP_LOGIN_URL;
    window.location.replace(url);
  };

  handleLogout = () => {
    sessionStorage.setItem('loggedIn', false);
    sessionStorage.setItem('idToken', '');
    sessionStorage.setItem('tokenType', '');
    sessionStorage.setItem('refreshToken', '');
    sessionStorage.setItem('username', '');
    sessionStorage.setItem('expiresIn', '');
    this.forceUpdate();
  };

  render() {
    const { loginState } = this.props;
    const { alert } = this.props;

    const { username, password, submitted, validated } = this.state;
    const lsloggedin = sessionStorage.getItem('loggedIn');
    const lsidToken = sessionStorage.getItem('idToken');
    let isLoggedIn = lsidToken && lsloggedin;
    return (
      <div>
        <div className="logo" />
        <div className="full authBG">
          <div className="loginBox">
            <div className="loginTitle">
              <span className="SKFH1">Welcome to SKF</span>
              <br />
              <span className="SKFH1">Rail CoMo Visualization</span>
            </div>
            {isLoggedIn ? (
              <div>
                <div>You are already logged in</div>
                <button onClick={this.handleLogout}>Logout</button>
              </div>
            ) : (
              <div className=" centerText customMargin">
                <Button
                  className="btn btn-primary buttonlogin"
                  onClick={this.handleSubmit}
                >
                  Go Log in{' '}
                  {loginState && <FontAwesomeIcon icon={faSpinner} spin />}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const user = state.authentication.user;
  const { alert } = state;
  const error = user && user.message && user.statusCode == 401;
  let errMessage = error && user.message;
  return {
    loginState: loggingIn,
    alert,
    error,
    errMessage
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
