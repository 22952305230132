import React, { Component, Fragment } from 'react';
import FleetMapv02 from './FleetMap/FleetMapv02';

class FleetMapWrapperv02 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      googleMap: null,
      loading: true
    };
  }

  shouldComponentUpdate() {
    return true;
  }
  componentWillUnmount() {
    const allScripts = document.getElementsByTagName('script');
    [].filter
      .call(
        allScripts,
        scpt =>
          scpt.src.indexOf(
            'maps.googleapis.com/maps/api/js?libraries=visualization&key='
          ) >= 0
      )[0]
      .remove();
    window.google = {};
  }

  getGoogleMapsObj() {
    if (!this.googleMapsPromise) {
      this.googleMapsPromise = new Promise(resolve => {
        window.resolveGoogleMapsPromise = () => {
          resolve(google);
          delete window.resolveGoogleMapsPromise;
        };
        const script = document.createElement('script');
        const apiKey = 'AIzaSyA98aDw9x53SOG3hwsulSmdNB-Yq6d4juM';
        script.src = `https://maps.googleapis.com/maps/api/js?libraries=visualization&key=${apiKey}&language=en&callback=resolveGoogleMapsPromise`;
        script.async = true;
        document.body.appendChild(script);
      });
    }
    return this.googleMapsPromise;
  }

  componentDidMount() {
    this.initiateGoogleMapsObj();
  }

  initiateGoogleMapsObj() {
    this.getGoogleMapsObj().then(google => {
      if (google) this.setState({ loading: false });
    });
  }

  render() {
    let {
      tabSelected,
      scopedPoints,
      clickMarker,
      currentLocation
    } = this.props;

    if (scopedPoints == null) {
      scopedPoints = [];
    }
    // const markers = scopedPoints.map(scopedPoint => scopedPoint.marker);
    // TODO activar el siguiente código y desactivar la línea anterior cuando recibamos name y reference fuera del marker
    const markers =
      scopedPoints &&
      scopedPoints.map(scopedPoint => {
        let newMarker = scopedPoint.marker;
        newMarker.name = scopedPoint.name;
        newMarker.reference = scopedPoint.reference;
        newMarker.severityColor = scopedPoint.severityColor;
        return newMarker;
      });

    const heatMapPoints =
      scopedPoints &&
      scopedPoints.map(scopedPoint => scopedPoint.heatMapPoints);

    const FleetMapUx = this.state.loading ? null : (
      <FleetMapv02
        clickMarker={clickMarker}
        tabSelected={tabSelected}
        currentLocation={currentLocation}
        google={google}
        markers={markers}
        heatMapPoints={heatMapPoints}
      />
    );
    return <Fragment>{FleetMapUx}</Fragment>;
  }
}
export default FleetMapWrapperv02;
