import axios from 'axios';
import { customError, catchError } from './error.service';
import { authHeader } from '../helpers';
import { apiService, HTTPMethods } from '../services/apiService';
const getHierarchyCarriagesDetails = async () => {
  var company = sessionStorage.getItem('client');
  const url = `${process.env.REACT_APP_API_URL}/api/Events/GetHierarchyCarriagesDetails/${company}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  }).then(response => customError(response));
};
const getAllEventsByCustomer = async () => {
  var customer = sessionStorage.getItem('client');
  const url = `${process.env.REACT_APP_API_URL}/api/Events/GetAllEventsByCustomer/${customer}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
};
const saveEventsByCustomer = async event => {
  var response = await apiService(`${process.env.REACT_APP_API_URL}/api/Events/UpdateEventsByCustomer`, HTTPMethods.PUT, event);
  if (response.status === 200) {
    return Promise.resolve(response.json());
  }
  return undefined;
};
const getEventbyVehicleDetails = async () => {
  var customer = sessionStorage.getItem('client');
  const url = `${process.env.REACT_APP_API_URL}/api/Events/GetEventbyVehicleByCompanyDetails/${customer}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
};
const getHierarchyCarriagesbyVehicle = async () => {
  var company = sessionStorage.getItem('client');
  const url = `${process.env.REACT_APP_API_URL}/api/Taxonomy/GetHierarchyCarriagesbyVehicle/${company}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
};
const GetHierarchybyVehicleDetails = async () => {
  const url = `${process.env.REACT_APP_API_URL}/api/Taxonomy/GetHierarchybyVehicleDetails `;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  }).then(response => {
    return response.data.map(res => {
      return {
        ...res,
        value: res.title
      };
    });
  });
};
const GetHierarchyHistoryDetails = async (company, level) => {
  const url = `${process.env.REACT_APP_API_URL}/api/Taxonomy/GetHierarchyHistoryDetails/company/${company}/level/${level}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  }).then(response => {
    return response.data.map(res => {
      return {
        ...res,
        value: res.key
      };
    });
  });
};
const GetHierarchyForFleetDropdown = async (company, level) => {
  const url = `${process.env.REACT_APP_API_URL}/api/Taxonomy/GetHierarchyFleetbyLevelDetails/company/${company}/level/${level}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
};
const GetHierarchyForReportsDropdown = async (company, level) => {
  const url = `${process.env.REACT_APP_API_URL}/api/Taxonomy/GetHierarchyReportstbyLevelDetails/company/${company}/level/${level}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  });
};
const GetHelpImages = async view => {
  const url = `${process.env.REACT_APP_API_URL}/api/Main/GetHelpImages/${view}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  });
};
export { getHierarchyCarriagesDetails, getAllEventsByCustomer, saveEventsByCustomer, getHierarchyCarriagesbyVehicle, getEventbyVehicleDetails, GetHierarchybyVehicleDetails, GetHierarchyHistoryDetails, GetHierarchyForFleetDropdown, GetHierarchyForReportsDropdown, GetHelpImages };