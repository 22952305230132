/** This component is used to render the rows from the table */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import FeedBack from '../feedback';
import EventLog from '../eventLog';
import moment from 'moment';
import ActionStatusDropDown from './actionStatusDropDown';
import { EventStatusEnum } from '../../../constants/eventStatusEnum';
import { AssetStatusEnum } from '../../../constants/assetStatusEnum';
import { FeedBackModel } from '../../../models/feedback';

import { getEventCase, postEventCase } from '../../../services/reports.service';
import { Typography } from '@material-ui/core';

const ComponentContainer = styled.tbody`
  border-top: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6;
  border-left: 3px solid
    ${props => (props.borderColor ? props.borderColor : '')};
`;

const DataContainer = styled.td`
  text-align: center;
`;

const ArrowContainer = styled(FontAwesomeIcon)`
  transition-duration: 0.3s;
  transition-property: transform;
`;

const DetailbackButton = styled.button`
  background-color: ${props => (!props.toggled ? '#0f58d6' : 'grey')};
  border: 0 !important;
  min-height: 38px !important;
  cursor: pointer;
  outline: 0px auto -webkit-focus-ring-color !important;
  color: #ffffff !important;
  border-radius: 3px !important;
  padding: 0 10px !important;
`;

const TimeStatusContainer = styled.span`
  display: inline-flex;
  padding: 1px 10px;
  color: ${props =>
    props.actNow
      ? '#ffffff'
      : props.act
      ? '#eb202a'
      : props.plan
      ? '#ffd615'
      : ''};
  background-color: ${props => (props.actNow ? '#eb202a' : '')};
  border-radius: ${props => (props.actNow ? '50px' : '')};
`;

const EventStatusContainer = styled.span`
  display: inline-flex;
  padding: 1px 10px;
  background-color: #eae8e8;
  border-radius: 50px;
`;

const NoPadding = styled.td`
  padding: 0 20px !important;
  border-top: 0 !important;
`;

class TableBody extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newStatusSelected: this.props.actionStatus,
      ProcessingActionStatus: false,
      isToggled: false,
      closedOn: false,
      isOpenEventLog: false,
      eventCaseStatus: {
        sourceId: this.props.sourceId,
        status: EventStatusEnum[this.props.actionStatus],
        dateTime: '',
        userName: sessionStorage.getItem('username')
      }
    };
    var _this = this;
  }

  statusSelected = (value, userName) => {
    this.setState({
      ProcessingActionStatus: true,
      newStatusSelected: EventStatusEnum[value]
    });
    const eventCase = {
      feedBackId: this.props.feedBackId,
      sourceType: this.props.sourceType,
      sourceKey: this.props.sourceKey,
      sourceId: this.props.sourceId,
      sourceFlag: this.props.sourceFlag,
      userName: userName,
      dateTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
      status: value
    };
    var _this = this;
    postEventCase(eventCase).then(h => {
      if (h.includes('Succeded')) {
        getEventCase(this.props.sourceKey).then(eventCaseStatus => {
          if (eventCaseStatus.sourceId != '') {
            _this.setState({
              eventCaseStatus: eventCaseStatus
            });
          }
          this.setState({ ProcessingActionStatus: false });
        });
        _this.props.changeActionStatus(true);
      } else {
        _this.props.changeActionStatus(false);
      }
    });
  };

  /** This method is used to toggle the arrow based on a state change */
  isBeingToggled = () => {
    if (this.state.isToggled) {
      this.setState({ isToggled: false });
    } else {
      this.setState({ isToggled: true });
    }
  };

  render() {
    const {
      asset,
      measurementType,
      temperature,
      createdOn,
      recommendedActions,
      eventId,
      assetStatus,
      diagnosis,
      eventCaseID,
      headerDescription,
      identifier,
      isFeedBack,
      status,
      sourceType,
      sourceKey,
      sourceId,
      sourceFlag,
      companyId,
      FeedBackId
    } = this.props;
    /** Here we declare which text must be rendered based on his number os asset status */
    const assetStatusString =
      (assetStatus === AssetStatusEnum.ActNow && 'Act Now') ||
      (assetStatus === AssetStatusEnum.Act && 'Act') ||
      (assetStatus === AssetStatusEnum.Plan && 'Plan');
    const borderColor =
      (assetStatus === AssetStatusEnum.ActNow && '#cb0000') ||
      (assetStatus === AssetStatusEnum.Act && '#cb0000') ||
      (assetStatus === AssetStatusEnum.Plan && '#ffd615');

    var eventStatus = 0;
    var closedOn = '';
    if (this.state.eventCaseStatus.eventCaseId != '') {
      eventStatus = this.state.eventCaseStatus.status;
      if (eventStatus == 3) {
        closedOn = moment(this.state.eventCaseStatus.dateTime).format('lll');
      }
    }
    return (
      <ComponentContainer borderColor={borderColor} className="Table_Body">
        <tr>
          <td className="align-middle" id={sourceId}>
            {sourceId}
          </td>
          <DataContainer className="align-middle">{asset}</DataContainer>
          <DataContainer className="align-middle">
            <TimeStatusContainer
              actNow={assetStatusString === 'Act Now'}
              act={assetStatusString === 'Act'}
              plan={assetStatusString === 'Plan'}
            >
              {assetStatusString}
            </TimeStatusContainer>
          </DataContainer>
          <DataContainer className="align-middle">
            {identifier !== '' ? identifier : 'n/a'}
          </DataContainer>
          <DataContainer className="align-middle">
            {measurementType}
          </DataContainer>
          <DataContainer className="align-middle">
            {measurementType && measurementType.toLowerCase() === 'temperature'
              ? `${temperature} ºC`
              : null}
          </DataContainer>
          <DataContainer className="align-middle">
            {status === 2 ? 'Closed' : 'Open'}
          </DataContainer>
          <td className="align-middle hiddenResponsive">
            {recommendedActions && recommendedActions.length > 50
              ? recommendedActions
                  .split(' ')
                  .slice(0, 9)
                  .join(' ')
                  .concat('...')
              : recommendedActions}
          </td>
          <DataContainer className="align-middle">{createdOn}</DataContainer>
          <DataContainer className="align-middle" hidden={true}>
            {closedOn}
          </DataContainer>
          <DataContainer className="align-middle" colSpan="2">
            <ActionStatusDropDown
              options={[
                { value: EventStatusEnum.Pending, label: 'Pending' },
                { value: EventStatusEnum.Rejected, label: 'Rejected' },
                { value: EventStatusEnum.Done, label: 'Done' }
              ]}
              eventCaseID={sourceId}
              defaultValue={this.state.newStatusSelected}
              selected={this.statusSelected}
              ProcessingActionStatus={this.state.ProcessingActionStatus}
            />
          </DataContainer>
          {/* <EventStatusContainer>{eventStatusString}</EventStatusContainer> */}

          <td className="align-middle">
            <FeedBack
              headerDescription={headerDescription}
              eventReference={eventId}
              diagnosis={diagnosis}
              recomendation={recommendedActions}
              eventCaseID={eventCaseID}
              asset={asset}
              assetStatus={assetStatus}
              saveFeddBack={this.props.saveFeddBack}
              isFeedBack={isFeedBack}
              sourceKey={sourceKey}
              sourceId={sourceId}
              sourceType={sourceType}
              sourceFlag={sourceFlag}
              companyId={companyId}
            />
          </td>
          <td
            data-toggle="collapse"
            data-target={`#${eventId}`}
            onClick={() => this.isBeingToggled()}
          >
            <DetailbackButton toggled={this.state.isToggled}>
              {!this.state.isToggled ? 'View detail' : 'Close detail'}
            </DetailbackButton>
          </td>
        </tr>
        <tr>
          <td colSpan="12" className="withoutpaddingtopOrButtom">
            <div id={eventId} className="collapse">
              <div className="paddingTopBottom">
                <p>{diagnosis && diagnosis.length > 1 ? 'ASSESSMENT' : ''}</p>
                {/*<p>
                Symptoms:
                {symptoms && symptoms.length > 1
                  ? ` ${symptoms}`
                  : " No data.."}
              </p>
              <p>
                Diagnosis:
                {diagnosis && diagnosis.length > 1
                  ? ` ${diagnosis}`
                  : " No data.."}
              </p>*/}
                <p>
                  {diagnosis && diagnosis.length > 1 ? ` ${diagnosis}` : ''}
                </p>
                <p>
                  {recommendedActions && recommendedActions.length > 1
                    ? 'RECOMMENDATION'
                    : ''}
                </p>
                <p>
                  {recommendedActions && recommendedActions.length > 1
                    ? recommendedActions
                    : ''}
                </p>
                <div
                  style={{ cursor: 'pointer', padding: '21px 0px 25px 0px' }}
                >
                  <a
                    className="customLink highlighted"
                    onClick={() => this.openEventLogModal()}
                  >
                    Show Event Log >>
                  </a>
                  {this.state.isOpenEventLog === true && (
                    <EventLog
                      createdOn={this.props.createdOn}
                      createdBy={this.props.createdBy}
                      assetStatus={assetStatus}
                      closeModal={() => this.closeEventLogModal()}
                      isOpen={this.state.isOpenEventLog}
                      eventReference={sourceId}
                      sourceKey={sourceKey}
                    />
                  )}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ComponentContainer>
    );
  }

  closeEventLogModal() {
    this.setState({
      isOpenEventLog: false
    });
  }
  openEventLogModal = () => {
    this.setState({
      isOpenEventLog: true
    });
  };
}

TableBody.propTypes = {
  /** Displays the status of the Axlebox */
  asset: PropTypes.string,
  /** Displays the type of sensor used */
  measurementType: PropTypes.string,
  /** Displays how much time is left to treat the issue */
  timeStatus: PropTypes.string,
  /** Displays the level of action needed for the event */
  assetStatus: PropTypes.number,
  /** Displays when was created the event */
  createdOn: PropTypes.string,
  /** Displays the state of the event */
  eventStatus: PropTypes.number,
  /** Displays a description of the actions recommended */
  recommendedActions: PropTypes.string,
  /** is used to allow unique collapse among the rows */
  eventId: PropTypes.string,
  /** Displays ID event case */
  eventCaseID: PropTypes.string,
  headerDescription: PropTypes.string,
  feedBack: FeedBackModel
};

TableBody.defaultProps = {
  asset: 'Bearing',
  measurementType: 'Temperature',
  timeStatus: 'X days Left',
  createdOn: '31 dec, 2019',
  eventStatus: 999,
  recommendedActions: 'Act NOW! Bearing change',
  eventId: 'kajhsdkakjahsd',
  assetStatus: 1
};

export default TableBody;
