import React, { useState, Fragment } from 'react';
import { Col, Row, Container } from 'react-bootstrap';

interface AssetProps {
  trainOperator: string;
  wheelset: string;
  axlebox: string;
  bearingSN: string;
  bogie: string;
  diameter: string;
  brand: string;
  designation: string;
  trainName: string;
  installationDate: string;
}
interface SensorProps {
  id: string;
  firmware: string;
  axleboxLocation: number;
  axleboxOrientation: number;
}

interface UpdateProps {
  loadingAssetInformationTab: boolean;
}

interface AssetSensorProps extends AssetProps, SensorProps, UpdateProps {}

class AssetInformation extends React.Component<AssetSensorProps> {
  //customText14
  spanValue(name: string, value: string, xl: number, ml: number) {
    return (
      <Col xl={xl} md={ml}>
        {value === 'empty' ? (
          <span className="customText14">{name}</span>
        ) : (
          <Fragment>
            <span className="eventTitle">{name}</span>
            <br />
            <span className="eventTitle dark">{value}</span>
          </Fragment>
        )}
      </Col>
    );
  }

  loadingContainer() {
    return (
      <div className="loadingContainer">
        <div className="spinner-border sfk_spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Container className="pt-3">
        <Row className="resetMargins">
          <Col xl={6} md={6} className="margin-element tabsAsset">
            <Row className="paddingAssetTab">
              {this.spanValue('Asset', 'empty', 12, 12)}
            </Row>
            <Row className="paddingAssetTab">
              {this.spanValue('Train Operator', this.props.trainOperator, 6, 6)}
              {this.spanValue('Train Name', this.props.trainName, 6, 6)}
            </Row>
            <Row className="paddingAssetTab">
              {this.spanValue('Bogie', this.props.bogie, 6, 6)}
              {this.spanValue('Wheelset', this.props.wheelset, 6, 6)}
            </Row>
            <Row className="paddingAssetTab">
              {this.spanValue('Wheel Diameter', this.props.diameter, 6, 6)}
              {this.spanValue('Axlebox ID', this.props.axlebox, 6, 6)}
            </Row>
            <Row className="paddingAssetTab">
              {this.spanValue('Brand & Designation', this.props.brand, 6, 6)}
              {this.spanValue(
                'Bearing Serial Number',
                this.props.bearingSN,
                6,
                6
              )}
            </Row>
          </Col>
          <Col xl={6} md={6} className="margin-element tabsAsset">
            {this.props.loadingAssetInformationTab ? (
              this.loadingContainer()
            ) : (
              <Fragment>
                <Row className="paddingAssetTab">
                  {this.spanValue('Sensor', 'empty', 12, 12)}
                </Row>
                <Row className="paddingAssetTab">
                  {this.spanValue('Sensor ID', this.props.id, 6, 6)}
                  {this.spanValue(
                    'Installation date',
                    this.props.installationDate,
                    6,
                    6
                  )}
                </Row>
                <Row className="paddingAssetTab">
                  {this.spanValue('Firmware', this.props.firmware, 6, 6)}
                  {this.spanValue('', '', 6, 6)}
                </Row>
                <Row className="paddingAssetTab">
                  {this.spanValue(
                    'Axlebox Location Angle',
                    String(this.props.axleboxLocation),
                    6,
                    6
                  )}
                  {this.spanValue('', '', 6, 6)}
                </Row>
                <Row className="paddingAssetTab">
                  {this.spanValue(
                    'Axlebox Orientation Angle',
                    String(this.props.axleboxOrientation),
                    6,
                    6
                  )}
                </Row>
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AssetInformation;
