import React, { Component } from 'react';
import { connect } from 'react-redux';
import Navigation from './Navigation/Navigation';
import config from 'config';
import { createBrowserHistory } from 'history';
import dotenv from 'dotenv';

class App extends Component {
  render() {
    const replaceHashPath = () => {
      const history = createBrowserHistory();
      const hash = history.location.hash;
      if (hash) {
        const path = hash.replace(/^#/, '');
        if (path) {
          history.replace(path);
        }
      }
    };
    replaceHashPath();

    return (
      <div>
        <Navigation />
      </div>
    );
  }
  componentDidMount() {
    dotenv.config();

    window.ENV = config;
  }
}
export default connect()(App);
