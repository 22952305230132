import { SET_TIME_ZONE } from './timeZoneActions';
const initialState = {
  timeZone: ''
};
const timeZoneReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_TIME_ZONE:
      return {
        ...state,
        timeZone: action.payload
      };
    default:
      return state;
  }
};
export default timeZoneReducer;