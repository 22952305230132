import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const ComponentContainer = styled.div`
  margin-top: 20px;
  margin-left: 20px;
`;

const TagContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const TagSelector = styled.div`
  display: inline-flex;
  padding: 5px 10px;
  border: 1px solid darkgrey;
  border-radius: 10px;
  background-color: #fefefe;
  cursor: pointer;
  &:hover {
    background-color: #f3f3f3;
  }
  margin: 5px 10px 5px 0;
`;

const IconContainer = styled(FontAwesomeIcon)`
  padding-top: 3px;
  margin-left: 5px;
  color: lightgray;
`;

const SelectedTextContainer = styled.div`
  font-weight: 700;
`;

const createBubbles = ({ value, type, handleBubbleClick, treeData }) => {
  return (
    value &&
    value.length > 0 &&
    value.map(bubble => {
      return (
        <TagSelector
          onClick={() => handleBubbleClick(bubble, type)}
          key={bubble}
        >
          {treeData?.find(e => e.key === bubble)?.title}{' '}
          <IconContainer icon={faTimesCircle} />
        </TagSelector>
      );
    })
  );
};

const BubbleTreeReport = ({
  hierarchySelected,
  measuramentTypeSelected,
  assetStatusSelected,
  handleBubbleClick,
  treeData
}) => {
  const onBubbleClick = (bubble, type) => {
    handleBubbleClick({ value: bubble, root: type });
  };

  return (
    <ComponentContainer>
      <SelectedTextContainer>Selected:</SelectedTextContainer>
      <TagContainer>
        {createBubbles({
          value: hierarchySelected,
          type: 'hierarchySelected',
          handleBubbleClick: onBubbleClick,
          treeData
        })}
        {createBubbles({
          value: assetStatusSelected,
          type: 'assetStatusSelected',
          handleBubbleClick: onBubbleClick,
          treeData: [
            {
              key: 'ActNow',
              pId: 0,
              title: 'Act Now',
              value: 'ActNow'
            },
            { key: 'Act', pId: 0, title: 'Act', value: 'Act' },
            { key: 'Plan', pId: 0, title: 'Plan', value: 'Plan' }
          ]
        })}
        {createBubbles({
          value: measuramentTypeSelected,
          type: 'measuramentTypeSelected',
          handleBubbleClick: onBubbleClick,
          treeData: [
            {
              key: 'Temperature',
              pId: 0,
              title: 'Temperature',
              value: 'Temperature'
            },
            {
              key: 'Vibration',
              pId: 0,
              title: 'Vibration',
              value: 'Vibration'
            },
            {
              key: 'Sensor',
              pId: 0,
              title: 'Sensor',
              value: 'Sensor'
            }
          ]
        })}
      </TagContainer>
    </ComponentContainer>
  );
};

BubbleTreeReport.propTypes = {
  assetStatusSelected: PropTypes.array,
  handleBubbleClick: PropTypes.func,
  hierarchySelected: PropTypes.array,
  measuramentTypeSelected: PropTypes.array
};

BubbleTreeReport.defaultProps = {
  assetStatusSelected: [],
  handleBubbleClick: () => null,
  hierarchySelected: [],
  measuramentTypeSelected: []
};

export default BubbleTreeReport;
