/** This component is used to render the big header that goes before the table */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ComponentContainer = styled.div`
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
  border-top: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6;
  border-left: ${props => (props.color ? `3px solid ${props.color}` : '')};
`;

const BottomBar = styled.div`
  width: 100%;
  border-bottom: 1px solid #dee2e6;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ebecee;
  padding: 20px 10px;
`;

const LeftContainer = styled.div`
  display: flex;
`;

const LocationStatusContainer = styled.div`
  transform: translateX(100px);
`;

const GreyText = styled.div`
  color: #808895;
`;

const BoldText = styled.div`
  font-weight: 700;
`;

const TableMainHeader = props => {
  let statusColor = '';

  /** This switch is used to decide which color must use at the left side */
  switch (props.status) {
    case 3:
      statusColor = '#ffca10';
      break;
    case 2:
    case 1:
      statusColor = '#dc3545';
      break;
    default:
      statusColor = '#dee2e6';
      break;
  }

  const assetStatusString =
    (props.status === 1 && 'Act Now') ||
    (props.status === 2 && 'Act') ||
    (props.status === 3 && 'Plan');

  return (
    <ComponentContainer color={statusColor} className="Table_Main_Header">
      <HeaderContainer>
        <LeftContainer>
          <div>
            <GreyText>Functional Location</GreyText>
            <BoldText>Functional Location ID: {props.axleboxID}</BoldText>
          </div>
          <LocationStatusContainer>
            <GreyText>Functional Location Status</GreyText>
            <BoldText>{assetStatusString}</BoldText>
          </LocationStatusContainer>
        </LeftContainer>
        <GreyText>{props.headerDescription}</GreyText>
      </HeaderContainer>
      <BottomBar />
    </ComponentContainer>
  );
};

TableMainHeader.propTypes = {
  /** Contains the status of the Axlebox */
  status: PropTypes.number,
  /** Contains the breadcrumb information for the selected event flow: Fleet - Train - Vehicle - Axlebox */
  headerDescription: PropTypes.string,
  /** this string is used at the right side for the info flow */
  axleboxID: PropTypes.string
  /** this string is used at the right side for the info flow */
};

TableMainHeader.defaultProps = {
  status: 'bad',
  headerDescription: '',
  axleboxID: '23'
};

export default TableMainHeader;
