import styled from 'styled-components';

const InfoBar = styled.div`
  min-height: 7.2rem;
  background-color: #ffffff;
  box-shadow: inset 0 0px 1px 1px var(--light-blue-grey);
`;

const MainHeader = styled.span`
  font-family: Arial;
  font-size: 1.8vw;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
  margin-left: 2vw;
`;

const Wherealarm = styled.span`
  position: relative;
  top: 5px;
  left: 10px;
`;

const BorderContainer = styled.div`
  border-bottom: 1px solid #c7cad0;
  background-color: #ffffff;
`;
const FlexContainer = styled.div`
  display: flexbox;
`;
const ComponentContainer = styled(FlexContainer)`
  justify-content: space-between;
  margin: 0 40px;
  align-items: center;
  height: 115px;
`;
const LeftContainer = styled(FlexContainer)`
  align-items: center;
  font-family: Arial;
  font-size: 36px;
  font-weight: 400;
`;
const RightContainer = styled.div`
  font-family: Arial;
  font-size: 18px;
  font-weight: 700;
`;
const DataContainer = styled.div`
  display: flexbox;
  justify-content: space-between;
  margin-left: 175px;
  line-height: 1;
`;
const DataNumber = styled.span`
  font-size: 70px;
  font-weight: 700;
  position: relative;
`;
const FirstNumber = styled(DataNumber)`
  color: #676f7c;
`;
const SymbolContainer = styled.div`
  margin-right: 25px;
`;
const SecondNumberContainer = styled(FlexContainer)`
  align-items: center;
  margin-left: 25px;
  margin-right: 75px;
`;

const SecondSubText = styled.div`
  font-size: 14px;
  transform: translate(5px, -24px);
`;

const DataText = styled.div`
  font-size: 12px;
  text-align: center;
  color: #676f7c;
`;
const ThirdNumberContainer = styled.div`
  display: inline-flex;
  flex-flow: column;
  margin-left: 25px;
`;

const ThirdNumber = styled(DataNumber)`
  border-left: 3px solid #ddd;
  padding-left: 57px;
`;

const ThirdDataText = styled.div`
  font-size: 12px;
  text-align: center;
  color: #676f7c;
  padding-left: 57px;
`;

const ThirdSubText = styled.div`
  font-size: 14px;
  transform: translate(5px, 10px);
`;

const IconContainer = styled.div`
  font-size: 12px;
  position: absolute;
  top: 0;
  left: -12px;
`;
const ThirdIconContainer = styled(IconContainer)`
  left: 40px;
`;

export {
  BorderContainer,
  FlexContainer,
  ComponentContainer,
  LeftContainer,
  RightContainer,
  DataContainer,
  DataNumber,
  FirstNumber,
  SymbolContainer,
  SecondNumberContainer,
  SecondSubText,
  DataText,
  ThirdNumberContainer,
  ThirdNumber,
  ThirdDataText,
  ThirdSubText,
  IconContainer,
  ThirdIconContainer,
  InfoBar,
  MainHeader,
  Wherealarm
};
