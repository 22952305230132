import React from 'react';
import { Row, Col } from 'react-bootstrap';

const AssetTypeCardv02 = props => {
  const { red, amber, assetType, numberClick } = props;
  let countAmber, countRed, countSensorEvents;
  countAmber = amber;
  countRed = red;
  if (assetType === 'Sensors') countSensorEvents = countAmber + countRed;
  const classesCountRed = `text-right dashboardSubHeader ${
    countRed === 0 ? 'battleship' : 'red'
  }`;
  const classesCountYellow = `text-left dashboardSubHeader ${
    countAmber === 0 ? 'battleship' : 'yellow'
  }`;
  const classesCountSensors = `text-center dashboardSubHeader ${
    countSensorEvents === 0 ? 'battleship' : 'yellow'
  }`;
  let assetCard;

  if (assetType === 'Sensors') {
    assetCard = (
      <Col className="assetCard page-box-tab ml-1 mr-1 mt-0">
        <h5 className="page-box-map-title text-center mb-3">{assetType}</h5>
        <Row>
          <Col
            className={
              numberClick ? 'text-center clickable-block' : 'text-center'
            }
            onClick={() => {
              if (numberClick) {
                numberClick('AMBER');
              }
            }}
          >
            <p className={classesCountSensors}>{countSensorEvents}</p>
            <p className="customText12 battleship">PLAN</p>
          </Col>
        </Row>
      </Col>
    );
  } else {
    assetCard = (
      <Col className="assetCard page-box-tab ml-1 mr-1 mt-0">
        <h5 className="page-box-map-title text-center mb-3">{assetType}</h5>
        <Row>
          <Col
            className={numberClick ? 'clickable-block' : ''}
            lg={5}
            md={5}
            sm={5}
            xs={5}
            onClick={() => {
              if (numberClick) {
                numberClick('RED');
              }
            }}
          >
            <p className={classesCountRed}>{countRed}</p>
            <p className="text-right customText12 battleship">ACT</p>
          </Col>
          <Col
            lg={1}
            md={1}
            sm={1}
            xs={1}
            md="1"
            className=" d-md-block assetsSeparator"
          />
          <Col
            className={numberClick ? 'clickable-block' : ''}
            lg={5}
            md={5}
            sm={5}
            xs={5}
            onClick={() => {
              if (numberClick) {
                numberClick('AMBER');
              }
            }}
          >
            <p className={classesCountYellow}>{countAmber}</p>
            <p className="text-left customText12 battleship">PLAN</p>
          </Col>
        </Row>
      </Col>
    );
  }
  return assetCard;
};

export default AssetTypeCardv02;
