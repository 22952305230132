import React from 'react';
import { Modal } from '@material-ui/core';
import styled, { StyledComponent } from 'styled-components';

export interface ISkfModalProps {
  isOpen: boolean;
  handleClose: () => void;
  size?: 'small' | 'large';
}

class SkfModal extends React.Component<ISkfModalProps, {}> {
  static Header: React.FC<{ handleClose: () => void }> = ({
    children,
    handleClose
  }) => (
    <ModalHeader>
      <>{children}</>
      <div className="times" onClick={handleClose}>
        &times;
      </div>
    </ModalHeader>
  );
  static Content: React.FC<{}> = ({ children }) => (
    <ModalContent>{children}</ModalContent>
  );

  static Footer: React.FC<{}> = ({ children }) => (
    <ModalFooter>{children}</ModalFooter>
  );

  render() {
    const { children, isOpen, handleClose } = this.props;
    return (
      <Modal disableBackdropClick open={isOpen} onClose={handleClose}>
        <ModalContainer size={this.props.size}>{children}</ModalContainer>
      </Modal>
    );
  }
}

export default SkfModal;

// Styled components

interface IModalContainerProps {
  size?: 'small' | 'large';
}

const ModalContainer: StyledComponent<
  'div',
  any,
  IModalContainerProps,
  never
> = styled.div`
  ${(props: IModalContainerProps) => {
    if (props.size && props.size.toLowerCase() === 'small') {
      return `max-width: 90%;
        width: 600px;`;
    } else {
      return `max-width:90%;
      width: 1224px;
      height: 80%;
      `;
    }
  }}
  background: #ffffff;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 80%;
  width: 100%;
  text-align: justify;
  position: relative;
  padding-top: 2rem;
  padding-left: 3rem;
  border-top: 25px solid #0f58d6;

  & .row {
    margin-left: 0;
    margin-right: 0;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
  }

  &::-webkit-scrollbar-thumb {
    background: #0f58d6;
    border-radius: 50px;
  }
`;

const ModalFooter = styled.footer`
  min-height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  // border-top: 1px solid #ddd;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  & > * {
    margin: 0.5rem !important;
  }
`;

const ModalHeader = styled.header`
  border-bottom: 1px solid #ddd;
  color: #7e7f83;
  font-size: x-large;
  font-weight: 500;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .times {
    font-size: 2rem;
    &:hover {
      cursor: pointer;
    }
  }
`;
