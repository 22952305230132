import { createReducer } from 'typesafe-actions';
import {
  fetchTrainDashboard,
  fetchTrainDashboardFulfilled,
  fetchTrainDashboardFailed,
  fetchTrainDashboardTimeout
} from '../actions/trainsDashboardAsyncAction';
import {
  trainDashboardFilter,
  trainDashboardFilterClear
} from '../actions/trainsDashboardAction';
import { Train, TrainIdentifier } from '../models/train';

export interface TrainDashboardStateSlice {
  vehicles: Train[];
  filteredVehicles: TrainIdentifier[];
  isFetchingVehicles?: boolean;
  fetchStatus?: string;
}

export interface TrainDashboardState {
  fetchTrainDashboardAsyncReducer: TrainDashboardStateSlice;
}

const defaultSlice: TrainDashboardStateSlice = {
  vehicles: [],
  filteredVehicles: [],
  isFetchingVehicles: false,
  fetchStatus: ''
};

export const fetchTrainDashboardAsyncReducer = createReducer<
  TrainDashboardStateSlice
>(defaultSlice)
  .handleAction(fetchTrainDashboard, (state, _) => {
    return {
      ...state,
      isFetchingVehicles: true,
      vehicles: [],
      filteredVehicles: []
    };
  })
  .handleAction(fetchTrainDashboardFulfilled, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      vehicles: payload.vehicles,
      fetchStatus: payload.status,
      isFetchingVehicles: false
    };
  })
  .handleAction(
    [fetchTrainDashboardFailed, fetchTrainDashboardTimeout],
    (state, action) => {
      const { payload } = action;
      return {
        ...state,
        isFetchingVehicles: false,
        fetchStatus: payload.status
      };
    }
  )
  .handleAction(trainDashboardFilter, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      filteredVehicles: payload
    };
  })
  .handleAction(trainDashboardFilterClear, (state, _) => {
    return {
      ...state,
      filteredVehicles: []
    };
  });
