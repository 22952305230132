import axios from 'axios';
import { authHeader } from '../helpers';
import { customError, catchError } from './error.service';
const getAxleboxByFunctionalLocation = async _ref => {
  let {
    axleboxReference,
    vehicleReference,
    startDate,
    endDate
  } = _ref;
  const url = `${process.env.REACT_APP_API_URL}/api/Trends/GetTrendHistoryByFunctionalLocation/${axleboxReference}/vehicle/${vehicleReference}/startdate/${startDate}/enddate/${endDate}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  });
};
const getArrEvents = async _ref2 => {
  let {
    carriageReference
  } = _ref2;
  const url = `${process.env.REACT_APP_API_URL}/api/Events/GetEventbyVehicleDetailsHistory/carriage/${carriageReference}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
};
const getSKFcarriages = async company => {
  const url = `${process.env.REACT_APP_API_URL}/api/Events/GetHierarchyHistoryEventCarriageDetails/company/${company}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  }).then(response => customError(response)).catch(error => {
    // Error 😨
    console.log('Error 😨', error);
    return error;
  });
};
const getSKFAxleboxes = async company => {
  const url = `${process.env.REACT_APP_API_URL}/api/Events/GetHierarchyHistoryEventFunctionalLocationDetails/company/${company}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
};
export { getAxleboxByFunctionalLocation, getArrEvents, getSKFcarriages, getSKFAxleboxes };