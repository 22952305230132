import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const ComponentContainer = styled.div`
  margin-top: 15px;
`;

const TagContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const TagSelector = styled.div`
  display: inline-flex;
  padding: 5px 10px;
  border: 1px solid darkgrey;
  border-radius: 10px;
  background-color: #fefefe;
  cursor: pointer;
  &:hover {
    background-color: #f3f3f3;
  }
  margin: 5px 10px 5px 0;
`;

const IconContainer = styled(FontAwesomeIcon)`
  padding-top: 3px;
  margin-left: 5px;
  color: lightgray;
`;

const createBubbles = ({ value, type, handleBubbleClick }) => {
  return (
    value &&
    value.length > 0 &&
    value.map(bubble => {
      let bubbleValue = bubble;
      let bubblekey = bubble;
      if (typeof bubble !== 'string') {
        bubbleValue = bubble[1];
        bubblekey = bubble[0];
      }
      return (
        <TagSelector
          onClick={() => handleBubbleClick(bubblekey, type)}
          key={bubblekey}
        >
          {bubbleValue} <IconContainer icon={faTimesCircle} />
        </TagSelector>
      );
    })
  );
};

const BubbleTree = ({
  hierarchyFleetData,
  hierarchyMTypeData,
  assetStatusSelected,
  handleBubbleClick
}) => {
  const onBubbleClick = (bubble, type) => {
    handleBubbleClick({ value: bubble, root: type });
  };

  return (
    <ComponentContainer>
      {/*<SelectedTextContainer>Selected:</SelectedTextContainer>*/}

      <TagContainer>
        {createBubbles({
          value: hierarchyFleetData,

          type: 'hierarchyFleetData',
          handleBubbleClick: onBubbleClick
        })}
        {createBubbles({
          value: hierarchyMTypeData,
          key: hierarchyMTypeData,
          type: 'mType'
        })}
        {createBubbles({
          value: assetStatusSelected,
          key: assetStatusSelected,
          type: 'assetStatusSelected',
          handleBubbleClick: onBubbleClick
        })}
      </TagContainer>
    </ComponentContainer>
  );
};

export default BubbleTree;
