/** This component is a filter that allows multiple selection and return an array of the value.
 * @param { Array } options must be an array of objects with the params value and label
 * */
import React, { Component } from 'react';
import Select from 'react-select';
import DropDown from './dropDown';

interface IActionStatusDropDownProps {
  options: { label: string; value: number }[];
  defaultValue: string;
  eventCaseID: string;
  selected: Function;
  ProcessingActionStatus: boolean;
}

class ActionStatusDropDown extends Component<IActionStatusDropDownProps> {
  constructor(props: any) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  render() {
    var _this = this;
    const { options, defaultValue, ProcessingActionStatus } = this.props;
    var valueSelected: any = 0;
    this.props.options.forEach(item => {
      if (_this.props.defaultValue && item.label === _this.props.defaultValue)
        valueSelected = item;
    });
    // const { filterStatus } = this.state;
    let newOptions = options;

    return (
      <Select
        className="customSelect customSelectBlue"
        classNamePrefix="fleetFilter"
        value={valueSelected}
        onChange={this.onChange}
        options={options}
        isDisabled={ProcessingActionStatus}

        // defaultValue={defaultValue}
      />
    );
  }

  onChange(e: any) {
    this.props.selected(
      e.value,
      this.props.eventCaseID,
      sessionStorage.getItem('username')
    );
  }
}

export default ActionStatusDropDown;
