import React, { Component } from 'react';

import { getSKFcarriages } from '../../../services/history.service';

class DummyComp extends Component {
  state = { data: {} };

  async componentDidMount() {
    const skf_carriages = await getSKFcarriages();
    this.setState({ skf_carriages });
  }
  render() {
    return <div>hola</div>;
  }
}

export default DummyComp;
