import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  TimeScale,
  Tooltip,
  Legend,
  Interaction
} from 'chart.js';

import zoomPlugin from 'chartjs-plugin-zoom';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { temperatureOptions } from './options';

import 'chartjs-adapter-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThermometerHalf } from '@fortawesome/free-solid-svg-icons';

import expandir from '../../../assets/img/expandir.png';
import PropTypes from 'prop-types';
Chart.register(
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
  //CrosshairPlugin
);
//Interaction.modes.interpolate = Interpolate;

const ComponentContainer = styled.div`
  background-color: #fefefe;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
`;

const IconContainer = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const TitleContainer = styled.div`
  padding-bottom: 15px;
`;

class TemperatureChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scales: [],
      setColors: false,
      iszoom: false
    };
    this.myRef2 = React.createRef();
  }

  componentDidMount() {
    this.ctx2 = this.myRef2.current.getContext('2d');

    this.chart2 = new Chart(this.ctx2, {
      type: 'line',
      data: this.createData(),
      options: this.chartOptions()
    });
    this.getChart();
  }
  componentWillUnmount() {
    if (this.chart2) this.chart2.destroy();
  }

  getChart = () => {
    this.props.updateCharts();
    const data = this.createData();
    this.updateData(this.chart2, data.labels, data.datasets);
    this.setState({ setColors: false });
  };

  fullSize = () => {
    this.props.setFullSize('temperature');
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.axleboxData !== this.props.axleboxData ||
      prevProps.sideBarSelected !== this.props.sideBarSelected
    ) {
      const data = this.createData();
      this.addData(this.chart2, data.labels, data.datasets);
      const axes = this.chartOptions();
      this.updOptions(this.chart2, axes);
      this.setState({ setColors: false });
    }

    if (
      prevProps.endDate !== this.props.endDate ||
      prevProps.startDate !== this.props.startDate
    ) {
      const data = this.createData();
      this.addData(this.chart2, data.labels, data.datasets);
    }

    if (
      this.state.yticks &&
      this.state.yticks.length > 0 &&
      !this.state.setColors
    ) {
      this.updateColors(this.chart2);
    }
  }

  newTickColors = () => {
    if (
      this.props.threshold &&
      this.props.threshold.length > 0 &&
      this.state.yticks
    ) {
      let tickNumbers = [...this.state.yticks];
      const lowerThreshold = this.props.threshold[0][0];
      const upperThreshold = this.props.threshold[0][1];

      const lowerTick = tickNumbers.findIndex(
        tick => tick.value === lowerThreshold
      );
      const upperTick = tickNumbers.findIndex(
        tick => tick.value === upperThreshold
      );

      let tickColors = tickNumbers.map(() => {
        return 'lightgray';
      });
      if (!this.state.iszoom) {
        tickColors[lowerTick] = 'rgba(255, 193, 7)';
        tickColors[upperTick] = 'rgb(183, 28, 28)';
      }

      return tickColors;
    } else {
      return 'lightgray';
    }
  };

  setZoom = (min, max) => {
    this.props.setZoomIn(min, max);
  };

  chartOptions = () => {
    let getchart = this.getChart;
    const upperThreshold =
      (this.props.threshold &&
        this.props.threshold.length > 0 &&
        this.props.threshold[0][1]) ||
      false;
    const lowerThreshold =
      (this.props.threshold &&
        this.props.threshold.length > 0 &&
        this.props.threshold[0][0]) ||
      false;

    const stepSize = upperThreshold - lowerThreshold;

    // calcular el minimo valor para el chart. Conseguir el múltiplo más cercano al stepsize.
    const { axleboxData, sideBarSelected } = this.props;
    let minForChart = 0;

    axleboxData.forEach(data => {
      let tmpMinForChart = data.temperature
        ? Math.min(...Object.values(data.temperature))
        : 0;
      minForChart = minForChart < tmpMinForChart ? minForChart : tmpMinForChart;
    });

    minForChart =
      minForChart >= 0
        ? Math.ceil(minForChart / stepSize) * stepSize
        : Math.ceil((minForChart - stepSize) / stepSize) * stepSize;

    let maxForChart = 0;

    axleboxData.forEach(data => {
      let tmpMaxForChart = data.temperature
        ? Math.max(...Object.values(data.temperature))
        : 0;
      maxForChart = maxForChart > tmpMaxForChart ? maxForChart : tmpMaxForChart;
    });

    maxForChart =
      maxForChart > upperThreshold + stepSize
        ? Math.ceil(maxForChart / stepSize) * stepSize
        : upperThreshold + stepSize;

    const that = this;
    // minForChart = -75;
    return {
      ...temperatureOptions,
      scales: {
        x: {
          id: 'XTemp',
          beginAtZero: true,
          display: true,
          gridLines: { color: 'rgba(255,255,255,0)' },

          type: 'time',
          min: this.props.startDate,
          max: this.props.endDate,
          time: {
            ...temperatureOptions.scales.x.time,
            min: this.props.startDate,
            max: this.props.endDate
          },
          ticks: {
            fixedStepSize: 2,
            maxTicksLimit: 15,
            sampleSize: 15,
            autoSkip: true
          }
        },
        y: {
          afterFit: scaleInstance => {
            scaleInstance.width = 35; // sets the width to 100px
            this.props.threshold &&
              this.setState({
                yticks: scaleInstance.ticks
              });
          },
          id: 'YTemp',
          position: 'left',
          display: true,
          min: minForChart,
          max: maxForChart,
          ticks: {
            // maxTicksLimit: 10,
            //upperThreshold + stepSize,
            stepSize: stepSize,
            fontSize: 10,
            display: true,
            callback: function(value) {
              if (Math.floor(value) === value) {
                return value;
              } else return Math.floor(value);
            },
            showLabelBackdrop: true
          },
          gridLines: {
            borderDash: [4, 4],
            borderDashOffset: 10,
            color: 'lightgray',
            scaleLabel: {
              labelString: 'Value'
            }
          },

          pointLabels: {
            display: true
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        zoom: {
          zoom: {
            drag: {
              enabled: true
            },
            onZoomComplete: ({ chart }) => {
              this.setState({ iszoom: true }, () =>
                this.updateColors(this.chart2)
              );
              chart.scales.y.start = Math.trunc(chart.scales.y.start);
              chart.scales.y.min = Math.trunc(chart.scales.y.min);
              chart.scales.y.ticks.forEach((e, i) => {
                chart.scales.y.ticks[i].value = Math.trunc(
                  chart.scales.y.ticks[i].value
                );
                chart.scales.y.ticks[i].label = Math.trunc(
                  chart.scales.y.ticks[i].value
                );
              });
              this.setZoom(chart.scales.x.min, chart.scales.x.max);
            },
            mode: 'xy'
          }
        },
        tooltip: {
          mode: 'nearest',
          intersect: false
        },
        crosshair: {
          line: {
            color: '#F66', // crosshair line color
            width: 1 // crosshair line width
          },
          sync: {
            enabled: true, // enable trace line syncing with other charts
            group: 2, // chart group
            suppressTooltips: false // suppress tooltips when showing a synced tracer
          },
          zoom: {
            enabled: true, // enable zooming
            zoomboxBackgroundColor: 'rgba(66,133,244,0.2)', // background color of zoom box
            zoomboxBorderColor: '#48F', // border color of zoom box
            zoomButtonText: 'Reset Zoom', // reset zoom button text
            zoomButtonClass: 'reset-zoom' // reset zoom button class
          },
          callbacks: {
            beforeZoom: () =>
              function(start, end) {
                // getchart(); called before zoom, return false to prevent zoom
                return true;
              },
            afterZoom: () =>
              function(start, end) {
                getchart();
              }
          }
        }
      },

      onClick: (e, items) => {
        if (items.length == 0) return;
        this.callCleanDataSheet();
        items.map(point => {
          const label =
            this.chart2.data.datasets[point.datasetIndex].label || '';
          const timeStamp = this.chart2.data.datasets[point.datasetIndex].data[
            point.index
          ]['x'];
          const value =
            Math.round(
              this.chart2.data.datasets[point.datasetIndex].data[point.index][
                'y'
              ] * 100
            ) / 100;

          this.callbackHover({ label, timeStamp, value });
        });
      }
    };
  };

  createData = () => {
    const { axleboxData, sideBarSelected } = this.props;

    const result = { labels: [], datasets: [] };
    if (!axleboxData.length) {
      return result;
    }

    axleboxData.forEach(data => {
      // result.labels = Object.keys(data.temperature);
      let dataXY = [];
      if (data.temperature) {
        Object.keys(data.temperature).map(key => {
          dataXY.push({ x: key, y: data.temperature[key] });
        });
      }
      result.datasets.push({
        label: data.functionaLocation.locationName,
        fill: false,
        lineTension: 0,
        borderWidth: 0.7,
        pointRadius:
          Math.floor(
            (new Date(this.props.endDate).getTime() -
              new Date(this.props.startDate).getTime()) /
              (1000 * 60 * 60 * 24)
          ) > 30
            ? 2
            : 0,
        pointHoverRadius: 5,
        backgroundColor: `${
          sideBarSelected[data.functionaLocation.reference]
            ? 'rgba(15, 88, 214,1)'
            : 'grey'
        } `,
        borderColor: `${
          sideBarSelected[data.functionaLocation.reference]
            ? 'rgba(15, 88, 214,1)'
            : 'grey'
        } `,
        pointHoverBackgroundColor: 'rgba(15, 88, 214,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        // data: Object.values(data.temperature)
        data: dataXY,
        normalized: true
      });
    });
    return result;
  };

  updateData = (chart, label, datasets) => {
    chart.data.labels = label;
    chart.data.datasets = datasets;
    chart.update();
  };

  addData = (chart, label, datasets) => {
    chart.data.labels = label;
    chart.data.datasets = datasets;
    chart.options.scales.x.min = this.props.startDate;
    chart.options.scales.x.max = this.props.endDate;
    chart.update();
  };

  updOptions = (chart, axesOptions) => {
    chart.options = axesOptions;
    chart.update();
  };

  updateColors = chart => {
    chart.options.scales.y.grid.color = this.newTickColors();
    chart.options.scales.y.grid.borderDash = [4, 4];
    chart.options.scales.x.grid.display = false;
    this.setState({ setColors: true });
    chart.update();
  };

  callbackHover({ label, timeStamp, value }) {
    this.props.dataHandler({ label, timeStamp, value });
  }

  callCleanDataSheet() {
    this.props.handleCleandDataSheet();
  }

  render() {
    return (
      <ComponentContainer>
        <TitleContainer>
          <div>
            <IconContainer icon={faThermometerHalf} />
            <span>Temperature (ºC)</span>
            <img
              src={expandir}
              onClick={() => this.fullSize()}
              alt=""
              style={{ float: 'right', height: 20, cursor: 'pointer' }}
            />
          </div>
        </TitleContainer>

        <canvas
          ref={this.myRef2}
          id="chart2"
          width="800px"
          height={'140px'}
          className="canvasForCharts"
        />
        {this.state.iszoom || this.props.zoom ? (
          <button
            onClick={() => {
              this.chart2.resetZoom();
              this.setState({ iszoom: false });
              this.props.setZoomOut();
            }}
          >
            Reset zoom
          </button>
        ) : (
          <div></div>
        )}
      </ComponentContainer>
    );
  }
}

TemperatureChart.defaultProps = {
  dataHandler: () => null,
  axleboxData: [],
  sideBarSelected: {},
  startDate: null,
  endDate: null,
  minY: null,
  maxY: null
};

TemperatureChart.propTypes = {
  dataHandler: PropTypes.func,
  axleboxData: PropTypes.array,
  sideBarSelected: PropTypes.object,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date)
};

export default TemperatureChart;
