export function authHeader() {
  // return authorization header with jwt token
  const idToken = sessionStorage.getItem('idToken');
  const userName = sessionStorage.getItem('username');
  // let tokenType = sessionStorage.getItem("tokenType");

  if (idToken) {
    return {
      Authorization: `${idToken}`,
      UserName: userName,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    };
  }
  return {};
}