import React from 'react';
import { Provider } from 'react-redux';
import { store } from './helpers';
import ReactDOM from 'react-dom';
import App from './App/App';
import ErrorBoundary from './App/ErrorBoundary';
import httpService from './services/network-service';
import './styles.scss';
import './trains.scss';
import './switch.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import Amplify from 'aws-amplify';
import Favicon from 'react-favicon';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_APP_CLIENT_ID
  }
});

httpService.setupInterceptors(store);

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <Favicon url="img/favicon.ico" />
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('app')
);
