import { userConstants } from '../constants';
let user = JSON.parse(sessionStorage.getItem('user'));
const initialState = user ? {
  loggedIn: true,
  user
} : {};
export function authentication() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        user: action.user
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}