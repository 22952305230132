import styled from 'styled-components';
export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 0px 20px;
  padding: 60px 0;
  font-size: 32px;
  text-align: center;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
`;
