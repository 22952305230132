import React, { Component } from 'react';
import SkfModal from '../reports/SkfModal';
import styled from 'styled-components';
import { Table, Form, Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretRight,
  faCaretLeft,
  faEdit,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import ModalNewCategoy from './modalNewCategory';
import ModalDeleteCategoy from './modaDeleteCategory';

interface IEventLog {
  userName: string;
  eventCaseId: string;
  comment: string;
  dateTime: Date;
  status: string;
  color: string;
}

export interface IEventLogProps {
  eventReference: string;
  isOpen: boolean;
  closeModal: any;
  assetStatus: number;
  createdOn: string;
  createdBy: string;
  categorys: any;
  setCategorys: any;
  roles: string[];
}

export interface StateProps {
  events: IEventLog[];
  isLoading: boolean;
  page: number;
  numPerPage: number;
  pages: number;
  category: string;
  categorySelected: any;
  newCategory: boolean;
  deleteCategory: boolean;
}

class ModalCategoy extends Component<IEventLogProps, StateProps> {
  constructor(props: any) {
    super(props);
  }

  public readonly state: Readonly<StateProps> = {
    events: [],
    isLoading: true,
    page: 0,
    numPerPage: 10,
    pages: 0,

    newCategory: false,
    deleteCategory: false
  };

  beforePage = () => {
    let page = this.state.page;
    page = page - 1;
    if (page < 0) {
      page = 0;
    }
    this.setState({
      page
    });
  };

  nextPage = () => {
    let lastPage = this.state.pages - 1;
    let page = this.state.page;
    page = page + 1;
    if (page > lastPage) {
      page = lastPage + 1;
    }
    this.setState({
      page
    });
  };

  setnewCategory = () => {
    this.setState({
      newCategory: !this.state.newCategory,
      categorySelected: undefined
    });
  };

  setDeleteCategory = () => {
    this.setState({ deleteCategory: !this.state.deleteCategory });
  };

  setCategorySelected = (categorySelected: any) => {
    this.setState({ categorySelected, newCategory: !this.state.newCategory });
  };

  setCategorySelectedDeleted = (categorySelected: any) => {
    this.setState({
      categorySelected,
      deleteCategory: !this.state.deleteCategory
    });
  };

  render() {
    return (
      <div>
        <SkfModal
          size="large"
          isOpen={this.props.isOpen}
          handleClose={this.props.closeModal}
        >
          <ModalNewCategoy
            setCategorys={this.props.setCategorys}
            category={this.state.categorySelected}
            categorys={this.props.categorys}
            closeModal={this.setnewCategory}
            isOpen={this.state.newCategory}
          ></ModalNewCategoy>
          <ModalDeleteCategoy
            setCategorys={this.props.setCategorys}
            category={this.state.categorySelected}
            categorys={this.props.categorys}
            closeModal={this.setDeleteCategory}
            isOpen={this.state.deleteCategory}
          ></ModalDeleteCategoy>
          <SkfModal.Header handleClose={this.props.closeModal}>
            Online Repository
          </SkfModal.Header>
          <SkfModal.Content>
            <Form>
              <Row>
                {this.props.roles.includes(
                  'CoMo-Visualization-User-Admin-Document-Categories_Create'
                ) && (
                  <Button variant="primary" onClick={this.setnewCategory}>
                    New
                  </Button>
                )}
              </Row>
              <br />
            </Form>
            <div style={{ width: '90%', marginBottom: 15 }}>
              <Table className="border font-weight-bold" size="sm">
                <thead>
                  <tr className="text-center">
                    <th className="align-middle">Category</th>
                    <th className="align-middle">Position</th>
                    <th className="align-middle">Status</th>
                    <th className="align-middle"></th>
                  </tr>
                </thead>
                <tbody className="battleship">
                  {this.props.categorys.map((e: any, index: number) => {
                    return (
                      <tr key={index} className={`text-center`}>
                        <td> {e.category}</td>
                        <td> {e.position}</td>
                        <td
                          style={e.status === 'DELETED' ? { color: 'red' } : {}}
                        >
                          {' '}
                          {e.status}
                        </td>
                        <td>
                          {e.status != 'DELETED' &&
                            this.props.roles.includes(
                              'CoMo-Visualization-User-Admin-Document-Categories_Create'
                            ) && (
                              <div>
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  style={{ marginRight: 10 }}
                                  onClick={() => this.setCategorySelected(e)}
                                />
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  onClick={() =>
                                    this.setCategorySelectedDeleted(e)
                                  }
                                ></FontAwesomeIcon>
                              </div>
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <BtnPaginator onClick={() => this.beforePage()}>
              <FontAwesomeIcon icon={faCaretLeft} />
            </BtnPaginator>
            <Legend>
              &nbsp;Page {this.state.page + 1} / {this.state.pages || 1} of{' '}
              {this.props.categorys.length}
              {' categorys'}
              &nbsp;
            </Legend>
            <BtnPaginator onClick={() => this.nextPage()}>
              <FontAwesomeIcon icon={faCaretRight} />
            </BtnPaginator>
          </SkfModal.Content>
        </SkfModal>
      </div>
    );
  }
}

const BtnPaginator = styled.div`
  background: lightgray;
  border-radius: 35px;
  width: 20px;
  text-align: center;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  float: left;
`;
const Legend = styled.div`
  margin-right: 5px;
  float: left;
`;

export default ModalCategoy;
