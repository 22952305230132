const initialState = {
  toHistory: '',
  hierarchy: []
};
export function fleet() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    // case "TO_HISTORY":
    //   return {
    //     ...state,
    //     toHistory: action.payload
    //   };
    case 'CLEAN_TO_HISTORY':
      return {
        ...state,
        toHistory: ''
      };
    case 'GET_HIERARCHY':
      return {
        ...state,
        hierarchy: action.payload
      };
    case 'CLEAN_HIERARCHY':
      return {
        ...state,
        hierarchy: []
      };
    default:
      return state;
  }
}