/** This component is the main one for the page reports, it contains all the subsequent components aswell
 *  a good piece of logic */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Header from '../utils/header/header';
import TableMainHeader from './reportsTable/tableMainHeader';
import Table from './reportsTable/table';
import { referenceForFilterConstructor } from '../utils/state';
import mergeArrays from '../utils/arraysHelper/mergeArrays';
import FiltersContainerReports from '../utils/filters/filtersContainerReports';
import MainFooter from '../footer/mainFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { resetFault } from '../../services/feedBack.service';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';
import {
  getHierarchyCarriagesDetails,
  getHierarchyCarriagesbyVehicle,
  getEventbyVehicleDetails,
  getAllEventsByCustomer,
  GetHierarchyForReportsDropdown
} from '../../services/common.service';
import { getEventtKeySelector } from '../../selectors/getEventtSelector';
import Notify from '../utils/notify/Notify';
import { toast } from 'react-toastify';
// import { ErrorBoundary, RecordPageView } from '../../App/AwsRum';

const LoadingContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 0px 20px;
  padding: 60px 0;
  font-size: 32px;
  text-align: center;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
  transform: translateY(18px);
`;

const ComponentContainer = styled.div`
  min-height: calc(100vh - 88px);
  display: flex;
  flex-direction: column;
`;

const TablesContainer = styled.div`
  padding: 20px;
  background-color: #f6f6f6;
  position: relative;
  z-index: 0;
`;

const TableBox = styled.div`
  padding: 20px;
  background-color: #ffffff;
`;

class ReportsMain extends Component {
  constructor(props) {
    super(props);
    this.changeActionStatus = this.changeActionStatus.bind(this);
    this.saveFeddBack = this.saveFeddBack.bind(this);
    this.state = {
      hierarchySelected: [],
      measuramentTypeSelected: [],
      assetStatusSelected: [],
      startDate: null,
      endDate: null,
      hideCompletedEventsSelected: false,
      reduxReceived: false,
      eventsFleet: [],
      carriages: [],
      vehicles: [],
      vehiclesDesignation: [],
      isLoading: false,
      eventSelected: undefined,
      onlyOpen: true
    };
  }

  setOnlyOpen = () => {
    this.setState({ onlyOpen: !this.state.onlyOpen });
  };

  checkOpenEvents = events => {
    let findOpen = false;
    events.map(e => {
      if (e.status !== 2) findOpen = true;
    });

    return findOpen;
  };

  async componentDidMount() {
    var searchParams = new URLSearchParams(window.location.href);
    const eventSelected = searchParams.get('event');
    this.setState({ eventSelected });

    this.mounted = true;
    this.props.changeMenu('reports');
    var client = sessionStorage.getItem('client');
    var level = sessionStorage.getItem('typeVehicle');

    var _this = this;
    Promise.all([
      GetHierarchyForReportsDropdown(client, level),
      getAllEventsByCustomer(),
      getHierarchyCarriagesDetails(),
      getHierarchyCarriagesbyVehicle()
    ])
      .then(
        ([{ data: treeData }, eventsFleet, carriages, vehiclesDesignation]) => {
          _this.setState({ isLoading: true }, () => {
            _this.updatefolder(
              treeData,
              eventsFleet,
              carriages,
              vehiclesDesignation
            );
          });
        }
      )
      .catch(exception => {
        toast.error(
          'Process is taking longer than expected, please try again later',
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: false
          }
        );
      });
  }

  updatefolder = (
    treeData,
    eventsFleet,
    carriages,

    vehiclesDesignation,
    referencesForFilter
  ) => {
    if (this.mounted) {
      this.setState({
        eventsFleet,
        carriages,

        treeData,
        referencesForFilter,
        vehiclesDesignation,
        isLoading: false
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (state.eventsFleet.length && !state.reduxReceived) {
      return {
        reduxReceived: true,
        startDate: new Date(
          state.eventsFleet
            .map(eventFloat => {
              return eventFloat.alarmTimestamp;
            })
            .sort()[0]
        ),
        endDate: new Date(
          state.eventsFleet
            .map(eventFloat => {
              return eventFloat.alarmTimestamp;
            })
            .sort()
            .reverse()[0]
        ),
        minDate: new Date(
          state.eventsFleet
            .map(eventFloat => {
              return eventFloat.alarmTimestamp;
            })
            .sort()[0]
        ),
        maxDate: new Date(
          state.eventsFleet
            .map(eventFloat => {
              return eventFloat.alarmTimestamp;
            })
            .sort()
            .reverse()[0]
        )
      };
    }
    return {};
  }

  /** this function gives the main header component the name of the highest asset status among the childrens,
   *  altough it goes with a separate logic used to calculate them. */
  getMaxLvlEvent = event => {
    let result = '';
    event.forEach(element => {
      if (element.status !== 2) {
        const activeAlarm =
          element.activeAlarm && element.activeAlarm.toLowerCase();
        const isAlarmRed = Object.is(activeAlarm, 'red');
        const isTemperature =
          element.alarmType &&
          element.alarmType.toLowerCase().includes('temperature');

        result =
          (isAlarmRed && isTemperature && 1) ||
          (isAlarmRed && result !== 1 && 2) ||
          (!isAlarmRed && result !== 1 && result !== 2 && 3) ||
          result;
      }
    });
    return result;
  };

  reportsMainCall = hierarchySelected => {
    this.setState({
      hierarchySelected: hierarchySelected
    });
  };

  handleMeasuramentTypeChange = value => {
    let measuramentTypeSelected = this.state.measuramentTypeSelected;
    if (measuramentTypeSelected.includes(value)) {
      measuramentTypeSelected = measuramentTypeSelected.filter(
        element => element !== value
      );
    } else {
      measuramentTypeSelected.push(value);
    }
    this.setState({
      measuramentTypeSelected: measuramentTypeSelected
    });
  };

  handleAssetStatusChange = value => {
    let assetStatusSelected = this.state.assetStatusSelected;
    if (assetStatusSelected.includes(value)) {
      assetStatusSelected = assetStatusSelected.filter(
        element => element !== value
      );
    } else {
      assetStatusSelected.push(value);
    }
    this.setState({
      assetStatusSelected: assetStatusSelected
    });
  };

  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndDateChange = date => {
    this.setState({
      endDate: date,
      endSelectedDate: date
    });
  };

  handleHideCompletedEventsChange = value => {
    this.setState({ hideCompletedEventsSelected: value });
  };

  sortEvents = (a, b) => {
    const firstValue = this.getMaxLvlEvent(a);
    const secondValue = this.getMaxLvlEvent(b);
    return firstValue - secondValue;
  };

  handleBubbleClick = ({ value, root }) => {
    this.setState({ [root]: this.state[root].filter(val => value !== val) });
  };

  changeActionStatus(isSuccess) {
    var _this = this;
    if (isSuccess === true) {
      toast.success('Action Status has been successfully changed', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 6000
      });
    } else {
      toast.error("Action Status wasn't saved, please try again later", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 60000
      });
    }
    //    });
    //  }
  }

  saveFeddBack(isSuccess) {
    if (isSuccess === true) {
      toast.success('Feedback has been successfully saved', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 6000
      });
    } else {
      toast.error("Feedback wasn't saved, please try again later", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 60000
      });
    }
  }

  render() {
    const {
      measuramentTypeSelected,
      startDate,
      endDate,
      minDate,
      maxDate,
      endSelectedDate,
      assetStatusSelected,
      hierarchySelected,
      hideCompletedEventsSelected,
      eventsFleet,
      carriages,
      treeData,
      vehiclesDesignation,
      referencesForFilter,

      isLoading
    } = this.state;

    let arrayDeconstructed = [];
    let eventsToRender = eventsFleet;
    if (eventsFleet.length) {
      if (hierarchySelected.length) {
        eventsToRender = eventsToRender.filter(event => {
          let result = false;
          hierarchySelected.forEach(hierarchy => {
            const carriagesSelected = carriages.filter(carriage => {
              return (
                carriage.reference === hierarchy ||
                carriage.fleetDesignation === hierarchy ||
                carriage.vehicleReference === hierarchy
              );
            });
            carriagesSelected.forEach(carriage => {
              if (carriage.reference === event.carriageReference) {
                result = true;
              }
            });
          });
          return result;
        });
      }
      eventsToRender = eventsToRender.filter(event => {
        return (
          (hideCompletedEventsSelected &&
            (event.alarmClosedTimestamp == '' ||
              event.alarmClosedTimestamp == undefined)) ||
          !hideCompletedEventsSelected
        );
      });
      if (!eventsToRender) eventsToRender = [];
      if (assetStatusSelected.length) {
        eventsToRender = eventsToRender.filter(value => {
          let result = false;
          assetStatusSelected.forEach(element => {
            let eventType = 'Plan';
            switch (value.activeAlarm.toLowerCase()) {
              case 'amber':
                break;
              case 'red':
                eventType = 'Act';
                if (
                  value.measurementType.toLowerCase().startsWith('temperature')
                ) {
                  eventType = 'ActNow';
                }

                break;

              default:
                break;
            }
            if (eventType === element) {
              result = true;
            }
          });
          return result;
        });
      }

      if (measuramentTypeSelected.length) {
        eventsToRender = eventsToRender.filter(value => {
          let result = false;
          measuramentTypeSelected.forEach(element => {
            if (value.measurementType.startsWith(element)) {
              result = true;
            }
          });
          return result;
        });
      }

      if (startDate && endDate) {
        eventsToRender = eventsToRender.filter(value => {
          return (
            (new Date(value.alarmTimestamp) >= startDate &&
              new Date(value.alarmTimestamp) <= endDate) ||
            (startDate.toDateString() ===
              new Date(value.alarmTimestamp).toDateString() &&
              endDate.toDateString() ===
                new Date(value.alarmTimestamp).toDateString())
          );
        });
      } else if (startDate && endDate && startDate === endDate) {
      }

      [...arrayDeconstructed] = Object.values(
        eventsToRender.reduce((c, v) => {
          const result = c;
          /** here we declare with which property we reorder the events */
          const k = v.functionalLocationID;
          result[k] = result[k] || [];
          result[k].push(v);
          return result;
        }, {})
      );
    }

    /** Here we reorder all the events and push them in differents arrays based on which Axlebox they are */
    return (
      <Fragment>
        <ComponentContainer className="Reports_Main">
          <Header
            title="Reports"
            subtitle={sessionStorage.getItem('username')}
            showNumbers={false}
          />
          <FiltersContainerReports
            onlyOpen={this.state.onlyOpen}
            setOnlyOpen={this.setOnlyOpen}
            sfk_VehiclesReferences={vehiclesDesignation}
            skf_ReferencesForFilter={referencesForFilter}
            mergeArrays={mergeArrays}
            reportsMainCall={this.reportsMainCall}
            handleMeasuramentTypeChange={this.handleMeasuramentTypeChange}
            handleAssetStatusChange={this.handleAssetStatusChange}
            startDate={startDate || minDate}
            endDate={endDate || new Date()} //maxDate}
            minDate={minDate}
            maxDate={new Date()} //maxDate}
            selectedDate={endSelectedDate || new Date()}
            handleStartDateChange={date => this.handleStartDateChange(date)}
            handleEndDateChange={this.handleEndDateChange}
            handleHideCompletedEventsStatusChange={
              this.handleHideCompletedEventsChange
            }
            hierarchySelected={hierarchySelected}
            assetStatusSelected={assetStatusSelected}
            measuramentTypeSelected={measuramentTypeSelected}
            skf_TreeData={treeData}
            eventsFleet={arrayDeconstructed}
            handleBubbleClick={this.handleBubbleClick}
            dataPdf={arrayDeconstructed}
          />
          <Notify />
          {this.state.eventSelected && (
            <Row style={{ marginLeft: '20px' }}>
              <Col>
                <button
                  onClick={e => this.setState({ eventSelected: undefined })}
                  className="btn btn-filters"
                >
                  {this.state.eventSelected}
                  <FontAwesomeIcon
                    className="bubbleDashboard"
                    icon={faTimesCircle}
                  />
                </button>
              </Col>
            </Row>
          )}
          {vehiclesDesignation.length > 0 &&
          eventsToRender.length > 0 &&
          !isLoading ? (
            <TablesContainer>
              <TableBox>
                {arrayDeconstructed
                  .sort((a, b) => this.sortEvents(a, b))
                  .map((event, index) => {
                    if (
                      this.state.eventSelected &&
                      event[0].eventCaseID !== this.state.eventSelected
                    )
                      return false;

                    const alarmLevel = this.getMaxLvlEvent(event);

                    const headerDescription = `${event[0].train_Fleet} > ${event[0].train_ID} > ${event[0].vehicle_Nickname} > ${event[0].functionalLocationID}`;

                    if (!this.checkOpenEvents(event) && this.state.onlyOpen) {
                      return <Fragment key={index}></Fragment>;
                    }
                    return (
                      <Fragment key={index}>
                        <div className="report_main_div">
                          <TableMainHeader
                            status={alarmLevel}
                            headerDescription={headerDescription}
                            axleboxID={event[0].functionalLocationID}
                          />
                          <Table
                            changeActionStatus={this.changeActionStatus}
                            saveFeddBack={this.saveFeddBack}
                            events={event}
                            onlyOpen={this.state.onlyOpen}
                            alarmLevel={alarmLevel}
                            headerDescription={headerDescription}
                          />
                        </div>
                        <br></br>
                      </Fragment>
                    );
                  })}
              </TableBox>
            </TablesContainer>
          ) : (
            <div>
              {vehiclesDesignation.length === 0 && (
                <LoadingContainer>
                  <div className="spinner-border sfk_spinner" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </LoadingContainer>
              )}
            </div>
          )}
        </ComponentContainer>

        <MainFooter />
      </Fragment>
    );
  }
}

/** This function is  used to change between the differents menus from the navBar */
const mapDispatchToProps = dispatch => {
  return {
    changeMenu: menu => dispatch({ type: 'CHANGE_MENU', menu })
  };
};

/** Here we populate props from the state of redux */
const mapStateToProps = state => {
  return {};
};

const connectedReportsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsMain);

ReportsMain.defaultProps = {
  changeMenu: () => null,
  skf_vehicles: [],
  vehiclesDesignation: [],
  eventsFleet: []
};

ReportsMain.propTypes = {
  /** This function is used to push a different route  */
  changeMenu: PropTypes.func,
  /** Contains an array of the events separated by the corresponding Axlebox  */
  eventsFleet: PropTypes.array,
  /** Contains an array of the events separated by the corresponding Axlebox  */
  vehiclesDesignation: PropTypes.array,
  /** This function is used to push a different route  */
  skf_vehicles: PropTypes.array
};

// eslint-disable-next-line
export { connectedReportsPage as ReportsMain };
