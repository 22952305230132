import React from 'react';
import styled from 'styled-components';

const SVGLogo = styled.svg`
  width: 200px;
  height: 200px;
`;

const analyzeIcon = (
  <SVGLogo
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        fill="#d3d3d3"
        d="M3,11c-0.553,0-1,0.448-1,1v8c0,0.553,0.447,1,1,1s1-0.447,1-1v-8C4,11.448,3.553,11,3,11z"
      />
      <path
        fill="#d3d3d3"
        d="M7,14c-0.553,0-1,0.448-1,1v10c0,0.553,0.447,1,1,1s1-0.447,1-1V15C8,14.448,7.553,14,7,14z"
      />
      <path
        fill="#d3d3d3"
        d="M11,8c-0.553,0-1,0.448-1,1v13c0,0.553,0.447,1,1,1s1-0.447,1-1V9C12,8.448,11.553,8,11,8z"
      />
      <path
        fill="#d3d3d3"
        d="M15,2c-0.553,0-1,0.448-1,1v24c0,0.553,0.447,1,1,1s1-0.447,1-1V3C16,2.448,15.553,2,15,2z"
      />
      <path
        fill="#d3d3d3"
        d="M19,7c-0.553,0-1,0.448-1,1v15c0,0.553,0.447,1,1,1s1-0.447,1-1V8C20,7.448,19.553,7,19,7z"
      />
      <path
        fill="#d3d3d3"
        d="M23,9c-0.553,0-1,0.448-1,1v9c0,0.553,0.447,1,1,1s1-0.447,1-1v-9C24,9.448,23.553,9,23,9z"
      />
      <path
        fill="#d3d3d3"
        d="M27,11c-0.553,0-1,0.448-1,1v9c0,0.553,0.447,1,1,1s1-0.447,1-1v-9C28,11.448,27.553,11,27,11z"
      />
    </g>
  </SVGLogo>
);

export default analyzeIcon;
