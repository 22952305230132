import axios from 'axios';
import { authHeader } from '../helpers';
const GetConfigurationPanel = async companies => {
  if (companies.join(',') === '') return;
  const url = `${process.env.REACT_APP_API_URL}/api/users/GetConfigurationPanel?companies=${companies.join(',')}`;
  return axios({
    method: 'get',
    url,
    headers: authHeader()
  });
};
const PostConfigurationPanel = async (companyId, level, timezone) => {
  const url = `${process.env.REACT_APP_API_URL}/api/users/PostConfigurationPanel`;
  return axios({
    method: 'post',
    url,
    headers: authHeader(),
    data: [{
      companyName: companyId,
      level,
      timezone: timezone
    }]
  });
};
const PostInsertUser = async (userName, password) => {
  const url = `${process.env.REACT_APP_API_URL}/api/users/InsertUser`;
  return axios({
    method: 'post',
    url,
    headers: authHeader(),
    data: [{
      userName,
      password
    }]
  });
};

// Awaiting for Backend solution.
const setTimeZonePerApp = arg => {
  return null;
};
export { GetConfigurationPanel, PostConfigurationPanel, PostInsertUser };