import { Row, Col } from 'react-bootstrap';
import React, { Fragment } from 'react';
import { LoadingWrapper } from '../loadingWrapper';
import AssetTypeCardv02 from '../../Home/Dashboard/DashboardContainer/Dashboard/AssetTypeCard/AssetTypeCardv02';
import { AlertStatus } from '../../models/alerts';
import { DasboardTreeSelector } from './dashboardTreeSelector';
import { Form } from 'react-bootstrap';
export interface DashboardAssetsPanelPropsValues {
  isLoading: boolean;
  totalBearingRed: number;
  totalBearingAmber: number;
  totalWheelRed: number;
  totalWheelAmber: number;
  totalSensorAmber: number;
  totalTractionMotorRed: number;
  totalTractionMotorAmber: number;
  totalGearBoxRed: number;
  totalGearBoxAmber: number;
  IMxRail: boolean;
  handledTreeSelected: any;
  treeData: any;
  valuesSelected: any;
  hierarchyFleetData: any;
}

export interface DashboardAssetsPanelPropsActions {
  bearingsFilter: (type: AlertStatus) => void;
  wheelsFilter: (type: AlertStatus) => void;
  sensorsFilter: (type: AlertStatus) => void;
  motorFilter: (type: AlertStatus) => void;
  gearFilter: (type: AlertStatus) => void;
}

export type DashboardAssetsPanelProps = DashboardAssetsPanelPropsValues &
  DashboardAssetsPanelPropsActions;

const DashboardAssetsPanel = ({
  isLoading,
  totalBearingRed,
  totalBearingAmber,
  totalWheelRed,
  totalWheelAmber,
  totalTractionMotorRed,
  totalTractionMotorAmber,
  totalGearBoxRed,
  totalGearBoxAmber,
  totalSensorAmber,
  handledTreeSelected,
  treeData,
  hierarchyFleetData,
  valuesSelected,
  bearingsFilter,
  wheelsFilter,
  sensorsFilter,
  motorFilter,
  gearFilter,
  IMxRail
}: DashboardAssetsPanelProps) => {
  return (
    <Row>
      <Col className="page-box-tab addMarginBottom">
        <Row className="map-title-container">
          <Col md={4} xl={5} xs={4}>
            <p className="page-box-map-main-title"> Assets</p>
          </Col>
          <Col md={8} xl={7} xs={8}>
            <Form className="font-weight-bold">
              <Form.Group
                controlId="selectForm"
                className="col-auto d-flex align-items-baseline"
              >
                <Col md={12} xl={12}>
                  <div style={{}}>
                    <DasboardTreeSelector
                      handledTreeSelected={handledTreeSelected}
                      treeData={treeData}
                      hierarchyFleetData={hierarchyFleetData}
                      valuesSelected={valuesSelected}
                    ></DasboardTreeSelector>
                  </div>
                </Col>
              </Form.Group>
            </Form>
          </Col>
          <Col md={12}>
            {isLoading ? (
              <Row>
                <LoadingWrapper>
                  <div className="spinner-border sfk_spinner" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </LoadingWrapper>
              </Row>
            ) : (
              <Row>
                <AssetTypeCardv02
                  red={totalBearingRed}
                  amber={totalBearingAmber}
                  assetType="Bearings"
                  numberClick={bearingsFilter}
                />
                <AssetTypeCardv02
                  red={totalWheelRed}
                  amber={totalWheelAmber}
                  assetType="Wheels"
                  numberClick={wheelsFilter}
                />
                {IMxRail && (
                  <Fragment>
                    <AssetTypeCardv02
                      red={totalTractionMotorRed}
                      amber={totalTractionMotorAmber}
                      assetType="Traction Motor"
                      numberClick={motorFilter}
                    />
                    <AssetTypeCardv02
                      red={totalGearBoxRed}
                      amber={totalGearBoxAmber}
                      assetType="Gear Box"
                      numberClick={gearFilter}
                    />
                  </Fragment>
                )}
                <AssetTypeCardv02
                  red={0}
                  amber={totalSensorAmber}
                  assetType="Sensors"
                  numberClick={sensorsFilter}
                />
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

DashboardAssetsPanel.defaultProps = {
  IMxRail: false,
  isLoading: false,
  totalBearingRed: 0,
  totalBearingAmber: 0,
  totalWheelRed: 0,
  totalWheelAmber: 0,
  totalSensorAmber: 0,
  totalTractionMotorRed: 0,
  totalTractionMotorAmber: 0,
  totalGearBoxRed: 0,
  totalGearBoxAmber: 0
};

export { DashboardAssetsPanel };
