/** it expects the redux state */

import { mergeArrays } from '../arraysHelper';

/** This function is used to give numbers based in the priorities from the asset status */
const getAssetStatus = (activeAlarm, alarmType) => {
  let result = '';
  const alarm = alarmType
    ? alarmType.toLowerCase().includes('temperature')
    : false;
  const red = activeAlarm ? activeAlarm.toLowerCase().includes('red') : false;
  /**
   * 1 means act now
   * 2 means act
   * 3 means plan
   * Added number values for sorting purposes.
   */
  if (alarm && red) {
    result = 1;
  } else if (red) {
    result = 2;
  } else {
    result = 3;
  }
  return result;
};

const eventsFleetConstructor = state => {
  /** Here we get all the events from the redux state of fleets */
  const { events } = state.fleet.fleet;
  /** Here we merge all the events from the fleet */
  const merged = mergeArrays(events);
  /** Here we push all the vehicles containing events in single arrays */
  let reduced = merged.reduce((acumulator, actualValue) => {
    const result = acumulator;
    result.push(Object.values(actualValue)[0]);

    return result;
  }, []);
  /** Here we merge all the events from all the arrays of vehicles */
  reduced = mergeArrays(reduced);

  /** Here we give each event a value used to sort and name the Asset status based on the alarm type and if is active */
  reduced.forEach(obj => {
    // eslint-disable-next-line no-param-reassign
    obj.assetStatus = getAssetStatus(obj.activeAlarm, obj.alarmType);

    if (obj.assetStatus === 1) {
      obj.assetStatusLabel = 'ActNow';
    } else if (obj.assetStatus === 2) {
      obj.assetStatusLabel = 'Act';
    } else {
      obj.assetStatusLabel = 'Plan';
    }
  });
  return reduced;
};

export default eventsFleetConstructor;
