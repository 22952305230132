import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import { LoadingWrapper } from '../loadingWrapper';
import AssetTypeCardv02 from '../../Home/Dashboard/DashboardContainer/Dashboard/AssetTypeCard/AssetTypeCardv02';

export interface IKpi {
  totalAmber: number;
  totalRed: number;
  totalBearingRed: number;
  totalBearingAmber: number;
  totalSensorAmber: number;
  totalWheelRed: number;
  totalWheelAmber: number;
  totalTractionMotorRed: number;
  totalTractionMotorAmber: number;
  totalGearBoxRed: number;
  totalGearBoxAmber: number;
  IMxRail: boolean;
}

export interface IFleetAssetPanel {
  kpi: IKpi;
  isLoading: boolean;
}

const FleetAssetPanel: React.FC<IFleetAssetPanel> = ({
  isLoading,
  kpi: {
    totalBearingRed,
    totalBearingAmber,
    totalWheelRed,
    totalWheelAmber,
    totalSensorAmber,
    totalTractionMotorRed,
    totalTractionMotorAmber,
    totalGearBoxRed,
    totalGearBoxAmber,
    IMxRail
  }
}) => {
  return (
    <Fragment>
      <Col className="page-box-shadow" style={{ padding: '1rem' }}>
        <p className="page-box-map-main-title"> Assets</p>
        {isLoading ? (
          <Row>
            <LoadingWrapper>
              <div className="spinner-border sfk_spinner" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </LoadingWrapper>
          </Row>
        ) : (
          <Row className="fleetAssetCardContainer">
            <AssetTypeCardv02
              red={totalBearingRed}
              amber={totalBearingAmber}
              assetType="Bearings"
            />
            <AssetTypeCardv02
              red={totalWheelRed}
              amber={totalWheelAmber}
              assetType="Wheels"
            />
            {IMxRail && (
              <Fragment>
                <AssetTypeCardv02
                  red={totalTractionMotorRed}
                  amber={totalTractionMotorAmber}
                  assetType="Traction Motor"
                />
                <AssetTypeCardv02
                  red={totalGearBoxRed}
                  amber={totalGearBoxAmber}
                  assetType="Gear Box"
                />
              </Fragment>
            )}
            <AssetTypeCardv02
              red={0}
              amber={totalSensorAmber}
              assetType="Sensors"
            />
          </Row>
        )}
      </Col>
    </Fragment>
  );
};

FleetAssetPanel.defaultProps = {
  isLoading: false,

  kpi: {
    totalRed: 0,
    totalAmber: 0,
    totalBearingRed: 0,
    totalBearingAmber: 0,
    totalWheelRed: 0,
    totalWheelAmber: 0,
    totalSensorAmber: 0,
    totalTractionMotorRed: 0,
    totalTractionMotorAmber: 0,
    totalGearBoxRed: 0,
    totalGearBoxAmber: 0,
    IMxRail: false
  }
};

export default FleetAssetPanel;
