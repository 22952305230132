import {
  TrainDashboardApiService,
  createTrainDashboardApiService
} from './trainDashboardApiService';

export interface Services {
  trainDashboardApi: TrainDashboardApiService;
}

export function createServices(): Services {

  return {
    trainDashboardApi: createTrainDashboardApiService(),
  };
}
