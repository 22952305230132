import { createAction, ActionType } from 'typesafe-actions';
import { Train } from '../models/train';

export const fetchTrainDashboard = createAction(
  'TRAIN_DASHBOARD/FETCH_TRAINS'
)();

export const fetchTrainDashboardFulfilled = createAction(
  'ASYNC_DASHBOARD/FETCH_TRAINS_FULFILLED',
  (status: string, vehicles: Train[]) => {
    return { status, vehicles };
  } // payload creator
)();

export const fetchTrainDashboardFailed = createAction(
  'ASYNC_DASHBOARD/FETCH_TRAINS_FAILED',
  (status: string) => {
    return { status };
  } // payload creator
)();

export const fetchTrainDashboardTimeout = createAction(
  'ASYNC_DASHBOARD/FETCH_TRAINS_TIMEOUT',
  (status: string) => {
    return { status };
  } // payload creator
)();

const ActionCreators = {
  fetchTrainDashboard,
  fetchTrainDashboardFulfilled,
  fetchTrainDashboardFailed,
  fetchTrainDashboardTimeout
};

export type TrainsDashboardAsyncAction = ActionType<typeof ActionCreators>;
