import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Title from './title';
import TimeZoneSelector from './timezone-selector';
import { format, toDate } from 'date-fns-tz';
import {
  dashboardSVG,
  fleetSVG,
  reportsSVG,
  historySVG
} from '../../assets/img/generalSvg';
import MainFooter from '../footer/mainFooter';
import {
  resetPassword,
  GetConfigurationPanel,
  PostConfigurationPanel
} from '../../services/userManagment.service';
import { toast } from 'react-toastify';
import timeZones from './timeZones';

const VirtualHeightContainer = styled.div`
  min-height: calc(100vh - 88px);
`;

const ComponentContainer = styled.div`
  display: flex;
  padding: 30px;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  width: 60%;
  display: flex;
  flex-flow: column;
`;

const WidgetContainer = styled.div`
  background-color: #fefefe;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
  padding: 30px;
`;

const RightContainer = styled(WidgetContainer)`
  width: 39%;
`;

const YourAccountContainer = styled(WidgetContainer)`
  margin-bottom: 20px;
`;

const ChangePasswordContainer = styled(WidgetContainer)`
  margin-bottom: 20px;
`;

const PreferenceTaxonomyContainer = styled(WidgetContainer)`
  float: left;
`;

const UserInfoContainer = styled.div`
  padding: 20px 0;
`;

const TimezoneContainer = styled.div`
  margin-top: 20px;
`;

const TwoInputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  #confirmPass {
    margin-top: 15px;
    @media (max-width: 1024px) {
      margin-top: 30px !important;
    }
  }
`;

const ButtonsContainer = styled.div`
  padding: 10px;
`;

const ClearButton = styled.button`
  border: 2px solid rgb(15, 88, 214);
  border-radius: 2px;
  background-color: transparent;
  color: rgb(15, 88, 214);
  padding: 5px 20px;
  font-weight: 700;
  margin-right: 15px;
  &:disabled {
    background-color: #edeef0;
    color: #a59b9b;
    border: 2px solid transparent;
  }
`;

const SaveButton = styled.button`
  border: 2px solid transparent;
  border-radius: 2px;
  background-color: #0f58d6;
  color: #ffffff;
  padding: 5px 20px;
  font-weight: 700;
  &:disabled {
    background-color: #edeef0;
    color: #a59b9b;
  }
`;

const AccesLabelContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;

const SVGContainer = styled.span`
  margin-right: 10px;
`;

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      serviceResponse: {},
      loadingButton: false,
      username: sessionStorage.getItem('username'),
      clientsPreferences: {},
      isLoading: true,
      user: '',
      externaluser: false
    };

    this.adminAcces = [
      {
        label: 'Dashboard',
        icon: dashboardSVG
      },
      { label: 'Fleets', icon: fleetSVG },
      { label: 'Reports', icon: reportsSVG },
      { label: 'History', icon: historySVG }
    ];
  }

  // Obtener el offset de una zona horaria
  getOffset = timeZone => {
    const now = new Date();
    const zonedDate = toDate(now, { timeZone });
    return format(zonedDate, 'XXX', { timeZone });
  };

  componentDidMount() {
    const clients = sessionStorage.getItem('clients').split(',');
    GetConfigurationPanel(clients).then(rest => {
      const clientsPreferences = {};
      let externaluser = false;

      rest.data.forEach(company => {
        externaluser = company.isIntegrationUser;
        const timezone =
          company.TimeZone === 'Etc/GMT+0' ? 'UTC' : company.TimeZone;

        const ianaTimeZones = [
          { label: `Etc/UTC (UTC+00:00)`, value: 'UTC' },
          ...Intl.supportedValuesOf('timeZone').map(zone => ({
            label: `${zone} (UTC${this.getOffset(zone)})`,
            value: zone
          }))
        ];

        clientsPreferences[company.CompanyName] = {
          level: company.Level,
          timeZone: ianaTimeZones.find(tz => tz.value === timezone)
            ? timezone
            : 'Etc/GMT+0'
        };
      });

      this.setState({
        clientsPreferences,
        externaluser,
        isLoading: false,
        user: rest.data[0].UserName
      });
    });
    this.props.changeMenu('');
  }

  componentWillUnmount() {
    this.setState({
      serviceResponse: {}
    });
  }

  handleOnChangeOldPassword = e => {
    this.setState({ oldPassword: e.target.value });
  };

  handleOnChangeNewPassword = e => {
    this.setState({ newPassword: e.target.value });
  };

  handleOnChangeConfirmNewPassword = e => {
    this.setState({ confirmNewPassword: e.target.value });
  };

  handleChangePassword = () => {
    window.location.replace(
      process.env.REACT_APP_LOGIN_URL + '?changePsw=true'
    );
  };

  handleOnclickSave = () => {
    if (
      this.state.confirmNewPassword.length >= 8 &&
      this.state.oldPassword.length >= 8 &&
      this.state.newPassword === this.state.confirmNewPassword
    ) {
      this.setState({ loadingButton: true });
      resetPassword({
        password: this.state.oldPassword,
        newPassword: this.state.newPassword,
        alias: this.state.username
      })
        .then(e => {
          this.setState({
            serviceResponse: {
              message: 'Your password has been updated.',
              code: e.status
            },
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            loadingButton: false
          });
        })
        .catch(e => {
          this.setState({
            serviceResponse: {
              message: e.response.data.message,
              code: e.response.data.statusCode
            },
            loadingButton: false
          });
        });
    } else if (this.state.newPassword !== this.state.confirmNewPassword) {
      this.setState({
        serviceResponse: {
          message: 'Your new password and confirm password do not match',
          code: 401
        },
        loadingButton: false
      });
    }

    return null;
  };

  handlerChangePreference = (client, event) => {
    const { value, name } = event.target;

    // Actualiza el estado con el nuevo valor de `level` o `timezone`
    const newClientsPreferences = {
      ...this.state.clientsPreferences,
      [client]: {
        ...this.state.clientsPreferences[client],
        [name]: value // Actualiza el valor del nivel o timezone
      }
    };

    this.setState({ clientsPreferences: newClientsPreferences }, () => {
      // Envía ambos valores juntos en la solicitud
      PostConfigurationPanel(
        client,
        newClientsPreferences[client].level, // Asegura que el nivel actual se envíe
        newClientsPreferences[client].timeZone // Asegura que el timezone actual se envíe
      )
        .then(() => {
          toast.success('Client configuration has been changed.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 6000
          });
        })
        .catch(error => {
          toast.error('Failed to update client configuration.', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 6000
          });
        });
    });
  };

  render() {
    const { user, clientsPreferences, isLoading, externaluser } = this.state;

    return (
      <Fragment>
        <VirtualHeightContainer>
          <ComponentContainer>
            <LeftContainer>
              <YourAccountContainer>
                <Title label="Your account" />
                <UserInfoContainer>
                  <div>{user}</div>
                </UserInfoContainer>
              </YourAccountContainer>
              {!externaluser && (
                <ChangePasswordContainer>
                  <Title label="Change password" />
                  <ButtonsContainer>
                    <ClearButton onClick={() => this.handleChangePassword()}>
                      Go to change Password
                    </ClearButton>
                  </ButtonsContainer>
                </ChangePasswordContainer>
              )}
              <PreferenceTaxonomyContainer>
                <Title label="Data configuration per customer" />

                {isLoading ? (
                  <div className="text-center">
                    <div
                      className="spinner-border sfk_spinner "
                      role="status"
                      style={{ width: '3rem', height: '3rem' }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {clientsPreferences &&
                      Object.keys(clientsPreferences)
                        .sort()
                        .map((client, index) => (
                          <form key={index} className="orderbySelectorTaxonomy">
                            <div className="form-group">
                              <label
                                htmlFor={`levelSelect-${index}`}
                                className="labelOrderby "
                              >
                                {client}
                              </label>

                              <div className="select-group">
                                <div className="datalevel-group">
                                  <label>Data level:</label>
                                  <br />
                                  <select
                                    className="form-control "
                                    id={`levelSelect-${index}`}
                                    name="level"
                                    value={clientsPreferences[client].level}
                                    onChange={event =>
                                      this.handlerChangePreference(
                                        client,
                                        event
                                      )
                                    }
                                  >
                                    <option value="Trains">Trains</option>
                                    <option value="Carriages">Carriages</option>
                                    <option value="Bogies">Bogies</option>
                                  </select>
                                </div>
                                <br />
                                <div className="timezone-group">
                                  <label htmlFor={`timezoneSelect-${index}`}>
                                    Time zone:
                                  </label>
                                  {clientsPreferences[client] && (
                                    <>
                                      <TimeZoneSelector
                                        defaultTimezoneSelectedParam={
                                          clientsPreferences[client].timeZone
                                        }
                                        indexParam={index}
                                        clientParam={client}
                                        onTimeZoneChange={
                                          this.handlerChangePreference
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </form>
                        ))}
                  </>
                )}
              </PreferenceTaxonomyContainer>
            </LeftContainer>
            <RightContainer>
              <Title label="Your access" />

              {this.adminAcces.map(element => {
                return (
                  <AccesLabelContainer key={element.label}>
                    <SVGContainer>{element.icon}</SVGContainer> {element.label}
                  </AccesLabelContainer>
                );
              })}
            </RightContainer>
          </ComponentContainer>
        </VirtualHeightContainer>
        <MainFooter />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeMenu: menu => dispatch({ type: 'CHANGE_MENU', menu })
  };
};

const mapStateToProps = state => ({
  timeZone: state.timeZone.timeZone
});

const connectedUserManagement = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagement);

UserManagement.propTypes = {
  changeMenu: PropTypes.func
};

UserManagement.defaultProps = {
  changeMenu: () => null
};

export { connectedUserManagement as UserManagement };
