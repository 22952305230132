import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrainDashboard } from '../models/train';
import { Dashboard } from '../components/Dashboard/dashboard';
import { DashboardClientView } from '../components/Dashboard/dashboardClientView';
import {
  getClientsTrains,
  getLocations
} from '../services/trainDashboardApiService';

import {
  isFetchingKpis,
  filteredTrainDashboardSelector
} from '../selectors/getTrainDashboardSelector';
import {
  trainDashboardFilterSelect,
  trainDashboardFilterClear,
  trainDashboardFilterRemove,
  trainDashboardBearingStatusSelect,
  trainDashboardWheelsStatusSelect,
  trainDashboardSensorStatusSelect,
  trainDashboardTrainStatusSelect,
  trainDashboardMotorStatusSelect,
  trainDashboardGearStatusSelect
} from '../actions/trainsDashboardAction';
import { useIsMounting } from '../hooks/isMounting';
import { fetchTrainDashboard } from '../actions/trainsDashboardAsyncAction';
import { AlertStatus } from '../models/alerts';
import { TrainsSummary } from '../models/train';
import { GetHierarchyForFleetDropdown } from '../services/common.service';
const defaultTrainSummary: TrainsSummary = {
  isIMx: false,
  totalRed: 0,
  totalAmber: 0,
  totalBearingRed: 0,
  totalBearingAmber: 0,
  totalWheelRed: 0,
  totalWheelAmber: 0,
  totalSensorAmber: 0,
  totalGearBoxAmber: 0,
  totalGearBoxRed: 0,
  totalTractionMotorAmber: 0,
  totalTractionMotorRed: 0,
  typeVehicle: ''
};

export interface DashboardContainerPropsValues {
  userName: string;
  showHeader: any;
  showOpctionsHeader: any;
  showOpctionCustomerHeader: any;
}

export interface DashboardContainerPropsActions {
  hasDashboardToVehicle: (payload: any) => void;
}

export type DashboardContainerProps = DashboardContainerPropsValues &
  DashboardContainerPropsActions;

const DashboardContainer = ({
  userName,
  showHeader,
  showOpctionsHeader,
  showOpctionCustomerHeader,
  hasDashboardToVehicle
}: DashboardContainerProps) => {
  const [companySelected, setCompanySelected] = useState('');
  const [locations, setLocations]: any = useState([]);
  const [hasMultipleCompanys, setHasMultipleCompanys] = useState(true);
  const [trainsByClient, setTrainsByClient]: any = useState([]);
  const filteredTrainsTags = useSelector(filteredTrainDashboardSelector);
  const [filterTrains, setFilterTrains]: any = useState([]);
  const [treeData, setTreeData]: any = useState([]);
  var client = sessionStorage.getItem('client');

  var level = sessionStorage.getItem('typeVehicle');

  useEffect(() => {
    if (client && level) {
      GetHierarchyForFleetDropdown(client, level).then((treeDataR: any) =>
        setTreeData(treeDataR)
      );
    }
  }, [client, level]);

  useEffect(() => {
    var client = sessionStorage.getItem('client');
    var level = sessionStorage.getItem('typeVehicle');
    if (client && level)
      GetHierarchyForFleetDropdown(client, level).then((treeDataR: any) =>
        setTreeData(treeDataR)
      );
  }, [companySelected]);

  function uniq(a: any[]) {
    return a.sort().filter(function(item, pos, ary) {
      return !pos || item != ary[pos - 1];
    });
  }

  function getChilds(value: string) {
    return treeData.filter((e: any) => e.pId === value);
  }

  function getAllChild(value: string) {
    var level = sessionStorage.getItem('typeVehicle');
    let result = getChilds(value);
    if (level === 'Trains') {
      return result.map((e: any) => e.value);
    }
    let resultCarriages: any[] = [];
    result.forEach((e: any) => {
      resultCarriages = resultCarriages.concat(getChilds(e.key));
    });
    if (level === 'Carriages') {
      return resultCarriages.map((e: any) => e.value);
    }
    let resultBogies: any[] = [];
    resultCarriages.forEach((e: any) => {
      resultBogies = resultBogies.concat(getChilds(e.key));
    });
    if (level === 'Bogies') {
      return resultBogies.map((e: any) => e.value);
    }
  }

  function getParent(node: any) {
    let nodeParent = treeData.find(
      (e: any) => e.value === node.pId || e.key === node.pId
    );
    //@ts-ignore
    if (nodeParent.level !== level.substring(0, level.length - 1))
      nodeParent = treeData.find(
        (e: any) => e.value === nodeParent.pId || e.key === nodeParent.pId
      );
    return nodeParent.value;
  }

  function getTrain(afilterVehicles: string[]) {
    let trainsByLevel: any[] = [];

    afilterVehicles.forEach(e => {
      const node = treeData.find((e2: any) => e2.value === e);

      //@ts-ignore
      if (!node.disabled && node.level !== level.substring(0, level.length - 1))
        trainsByLevel.push(node.value);

      //@ts-ignore
      if (node.level !== level.substring(0, level.length - 1))
        trainsByLevel.push(getParent(node));
      else if (node.pId === 0) {
        const childs = getAllChild(node.value);
        trainsByLevel = trainsByLevel.concat(childs);
      } else {
        const filterVehicles = sessionStorage.getItem('filterVehicles');
        if (filterVehicles) {
          const afilterVehicles = filterVehicles.split(',');
          trainsByLevel = trainsByLevel.concat(afilterVehicles);
        }
      }

      setFilterTrains(uniq(trainsByLevel));
    });
  }

  useEffect(() => {
    const filterVehicles = sessionStorage.getItem('filterVehicles');
    if (filterVehicles) {
      const afilterVehicles = filterVehicles.split(',');
      const array = filteredTrainsTags.concat(...afilterVehicles);
      setFilterTrains(uniq(array));
    } else {
      setFilterTrains(filteredTrainsTags);
    }
  }, [filteredTrainsTags]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) getTrain(filterTrains);
    return () => {
      isMounted = false;
    };
  }, [treeData]);

  if (client && client !== '' && companySelected !== client) {
    sessionStorage.setItem('client', client);
    setClient(client);
  }

  async function setClient(value: any) {
    setFilterTrains([]);
    setLocations([]);
    setTrainsByClient([]);
    setCompanySelected(value);
    sessionStorage.setItem('client', value);
    let vehicleType = sessionStorage.getItem('typeVehicle');
    let result = await getClientsTrains(value, vehicleType);
    setTrainsByClient(result);
    showOpctionsHeader(true);
    let locationsData = await getLocations();
    setLocations(locationsData);
  }

  function getTrainsbyClient() {
    return trainsByClient;
  }

  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingKpis);
  const {
    totalRed,
    totalAmber,
    totalBearingAmber,
    totalBearingRed,
    totalSensorAmber,
    totalWheelAmber,
    totalWheelRed,
    totalGearBoxAmber,
    totalGearBoxRed,
    totalTractionMotorAmber,
    totalTractionMotorRed,
    isIMx,
    typeVehicle
  } = getTrainsbyClient().reduce(
    (summary: any, vehicle: any) => {
      if (
        (filterTrains.length > 0 && filterTrains.includes(vehicle.reference)) ||
        filterTrains.length === 0
      ) {
        if (
          vehicle.companyName.toUpperCase() === companySelected.toUpperCase()
        ) {
          summary.totalRed += vehicle.totalRed;
          summary.totalAmber += vehicle.totalAmber;
          summary.totalBearingRed += vehicle.totalBearingRed;
          summary.totalBearingAmber += vehicle.totalBearingAmber;
          summary.totalWheelRed += vehicle.totalWheelRed;
          summary.totalWheelAmber += vehicle.totalWheelAmber;
          summary.totalSensorAmber += vehicle.totalSensorAmber;
          summary.totalGearBoxAmber += vehicle.totalGearBoxAmber;
          summary.totalGearBoxRed += vehicle.totalGearBoxRed;
          summary.totalTractionMotorAmber += vehicle.totalTractionMotorAmber;
          summary.totalTractionMotorRed += vehicle.totalTractionMotorRed;
          if (vehicle.isIMx) {
            summary.isIMx = vehicle.isIMx;
          }
          summary.typeVehicle = vehicle.typeVehicle;
        }
      }
      return summary;
    },
    { ...defaultTrainSummary }
  );

  const addTrainToFilter = (trainName: string) => {
    const filterVehicles = sessionStorage.getItem('filterVehicles');
    if (filterVehicles && filterVehicles !== '') {
      const filteredVeghicles = filterVehicles.split(',');
      if (!filteredVeghicles.includes(trainName))
        filteredVeghicles?.push(trainName);

      sessionStorage.setItem('filterVehicles', filteredVeghicles.toString());
    } else sessionStorage.setItem('filterVehicles', trainName);
    dispatch(trainDashboardFilterSelect(trainName));
  };
  const clearFilter = () => {
    sessionStorage.setItem('filterVehicles', '');
    dispatch(trainDashboardFilterClear());
  };
  const removeFilter = (trainName: string) => {
    const filterVehicles = sessionStorage.getItem('filterVehicles');
    if (filterVehicles && filterVehicles !== '') {
      let filteredVeghicles = filterVehicles.split(',');
      filteredVeghicles = filteredVeghicles.filter(e => e !== trainName);
      sessionStorage.setItem('filterVehicles', filteredVeghicles.toString());
    }
    dispatch(trainDashboardFilterRemove(trainName));
  };
  const bearingsFilter = (type: AlertStatus) =>
    dispatch(trainDashboardBearingStatusSelect({ type, trainsByClient }));
  const wheelsFilter = (type: AlertStatus) =>
    dispatch(trainDashboardWheelsStatusSelect({ type, trainsByClient }));
  const motorFilter = (type: AlertStatus) =>
    dispatch(trainDashboardMotorStatusSelect({ type, trainsByClient }));
  const gearFilter = (type: AlertStatus) =>
    dispatch(trainDashboardGearStatusSelect({ type, trainsByClient }));
  const sensorFilter = (type: AlertStatus) =>
    dispatch(trainDashboardSensorStatusSelect({ type, trainsByClient }));
  const totalFilter = (type: AlertStatus) =>
    dispatch(trainDashboardTrainStatusSelect({ type, trainsByClient }));
  // Dispatch Fetch
  const isMounting = useIsMounting();

  if (isMounting) {
    dispatch(fetchTrainDashboard());
  }

  if (typeVehicle) sessionStorage.setItem('typeVehicle', typeVehicle);
  sessionStorage.setItem('isIMx', isIMx);
  return (
    <div>
      {(client && client !== '') || !hasMultipleCompanys ? (
        <Dashboard
          typeVehicle={typeVehicle}
          treeData={treeData}
          totalRed={totalRed}
          totalAmber={totalAmber}
          totalBearingAmber={totalBearingAmber}
          totalBearingRed={totalBearingRed}
          totalSensorAmber={totalSensorAmber}
          totalWheelAmber={totalWheelAmber}
          totalWheelRed={totalWheelRed}
          totalGearBoxAmber={totalGearBoxAmber}
          totalGearBoxRed={totalGearBoxRed}
          totalTractionMotorAmber={totalTractionMotorAmber}
          totalTractionMotorRed={totalTractionMotorRed}
          isFetching={isFetching}
          locations={locations}
          trains={getTrainsbyClient()}
          selectedTrains={filterTrains}
          userName={userName}
          addTrainToFilter={addTrainToFilter}
          hashDashboardToVehicle={hasDashboardToVehicle}
          clearFilter={clearFilter}
          removeFilterTrain={removeFilter}
          wheelsFilter={wheelsFilter}
          motorFilter={motorFilter}
          gearFilter={gearFilter}
          bearingsFilter={bearingsFilter}
          sensorsFilter={sensorFilter}
          totalFilter={totalFilter}
          IMxRail={isIMx}
        />
      ) : (
        <DashboardClientView
          selectedTrains={filterTrains}
          userName={userName}
          totalFilter={totalFilter}
          setClient={setClient}
          showOpctionCustomerHeader={showOpctionCustomerHeader}
          setHasMultipleCompanys={setHasMultipleCompanys}
        ></DashboardClientView>
      )}
    </div>
  );
};

DashboardContainer.defaultProps = {
  userName: '',

  hasDashboardToVehicle: () => {
    return;
  }
};

export { DashboardContainer };
