import React, { useState, useEffect } from 'react';
import {
  faTimes,
  faCaretLeft,
  faCaretRight,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';
import { GetHelpImages } from '../services/common.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { connect } from 'react-redux';

export interface IPageTutorialProps {
  menu: { activePath?: { menu: string } };
}

const PageTutorial: React.FC<IPageTutorialProps> = props => {
  const [showing, setShowing] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [images, setImages] = useState<any[]>([]);

  // Similar to componentDidMount
  useEffect(
    () => {
      // Endpoint/service call
      (async () => {
        if (!props.menu.activePath || props.menu.activePath.menu === '') return;
        const {
          activePath: { menu }
        } = props.menu;

        const res: {
          data: Array<{ pos: number; image: string }>;
        } = await GetHelpImages(menu);
        if (Array.isArray(res.data))
          setImages([
            ...res.data.sort((a, b) => a.pos - b.pos).map(obj => obj.image)
          ]);
      })();
    },
    [props.menu] /* Similar to componentDidUpdate */
  );

  const nextImage = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.preventDefault();
    if (currentImage === images.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(currentImage + 1);
    }
  };

  const prevImage = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.preventDefault();
    if (currentImage === 0) {
      setCurrentImage(images.length - 1);
    } else {
      setCurrentImage(currentImage - 1);
    }
  };

  const showBackdrop = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Prevent page change when click on backdrop
    ev.preventDefault();
    setShowing(true);
  };

  return (
    <>
      <span
        className="nav-link dropdown-toggle clickable-block"
        onClick={showBackdrop}
      >
        <IconContainer icon={faQuestionCircle} />
      </span>
      {showing ? (
        <>
          <Backdrop
            onClick={ev => {
              ev.preventDefault();
            }}
          >
            <span
              className="closeIcon"
              onClick={ev => {
                ev.preventDefault();
                setShowing(false);
              }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                size="2x"
                color="rgb(44, 95, 243)"
              />
            </span>
          </Backdrop>
          <SliderContainer onClick={ev => ev.preventDefault()}>
            <span onClick={prevImage}>
              <FontAwesomeIcon
                icon={faCaretLeft}
                size="2x"
                color="rgb(44, 95, 243)"
              />
            </span>
            {images && images.length > 0 && (
              <img
                style={{ height: '80%', width: '80%' }}
                src={`data:image/png;base64,${images[currentImage]}`}
              />
            )}
            {images && images.length <= 0 && (
              <LoadingWrapper>
                <div className="spinner-border sfk_spinner" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </LoadingWrapper>
            )}
            <span onClick={nextImage}>
              <FontAwesomeIcon
                icon={faCaretRight}
                size="2x"
                color="rgb(44, 95, 243)"
              />
            </span>
          </SliderContainer>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  menu: state.menu
});

export default connect(mapStateToProps)(PageTutorial);

// styled components
const Backdrop = styled.div`
  background: rgb(19, 19, 19);
  opacity: 0.7;
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .closeIcon {
    position: absolute;
    top: 5%;
    left: 95%;
  }
`;

const SliderContainer = styled.div`
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  height: 80%;
  display: grid;
  grid-template-columns: 5% 90% 5%;
  & > * {
    display: flex;
    align-self: center;
    justify-self: center;
  }
  & img {
    width: 100%;
    max-height: 100%;
  }
`;

const IconContainer = styled(FontAwesomeIcon)`
  margin-right: 10px;
  font-size: 20px;
  color: white;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 0px 20px;
  padding: 60px 0;
  font-size: 32px;
  text-align: center;
`;
