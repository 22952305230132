import { createReducer } from 'typesafe-actions';
import {
  VehicleLocation,
  fetchLocationDashboard,
  fetchLocationDashboardFulfilled,
  fetchLocationDashboardFailed,
  fetchLocationDashboardTimeout
} from '../actions/trainDashboardMapAsyncAction';

export interface TrainDashboardMapStateSlice {
  locations: VehicleLocation[];
  isFetchingLocation?: boolean;
  fetchStatus?: string;
}

export interface TrainDashboardMapState {
  fetchLocationDashboardAsyncReducer: TrainDashboardMapStateSlice;
}

const defaultSlice: TrainDashboardMapStateSlice = {
  locations: [],
  isFetchingLocation: true,
  fetchStatus: ''
};

export const fetchLocationDashboardAsyncReducer = createReducer<
  TrainDashboardMapStateSlice
>(defaultSlice)
  .handleAction(fetchLocationDashboard, (state, _) => {
    return {
      ...state,
      isFetchingLocation: true,
      locations: []
    };
  })
  .handleAction(fetchLocationDashboardFulfilled, (state, action) => {
    const { payload } = action;
    return {
      ...state,
      locations: payload.locations,
      fetchStatus: payload.status,
      isFetchingLocation: false
    };
  })
  .handleAction(
    [fetchLocationDashboardFailed, fetchLocationDashboardTimeout],
    (state, action) => {
      const { payload } = action;
      return {
        ...state,
        isFetchingLocation: false,
        fetchStatus: payload.status
      };
    }
  );
