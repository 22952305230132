import React, { Component } from 'react';
import reportIcon from '../../assets/img/icon-report@x3.png';
import pdfIcon from '../../assets/img/pdf-logo-1.png';
import excelIcon from '../../assets/img/excel-logo-1.png';
import { apiService, HTTPMethods } from '../../services/apiService';
import Modal from '../vehiclePage/InformationButton';
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import { connect } from 'react-redux';

const ComponentColumns = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin-left: 10%;
`;
const ComponentColumn = styled.div`
  flex: 40%;
  text-align: center;
  margin: auto;
  max-width: 400px;
  border: 1px solid #0f58d6;
  padding: 30px;
  border-radius: 25px;
  height: 180px;
  margin: 20px;
`;
const Span = styled.div`
  margin-top: 25px;
  font-size: 20px;
  color: black;
`;

export interface IPdfProps {
  startDate: string;
  endDate: string;
  measuramentValue: string;
  actionValue: string;
  hierarchyValue: string;
  onlyOpen: boolean;
  filteredAxleboxData: any[];
  timeZone: any;
}
export interface IPdfState {
  toggleModal: boolean;
}

class PdfHistory extends Component<IPdfProps, IPdfState> {
  constructor(props: any) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      toggleModal: false
    };

    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  showModal() {
    this.setState({ toggleModal: true });
  }

  hideModal() {
    this.setState({ toggleModal: false });
  }

  b64toBlob = (b64Data: string, contentType: string) => {
    contentType = contentType || '';
    var sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  handleClick = () => {
    if (this.state.toggleModal) this.setState({ toggleModal: false });
    else this.setState({ toggleModal: true });
  };

  handleClickExcel = (data: any) => {
    const wb = XLSX.utils.book_new();
    const { timeZone } = this.props;
    let dataTotal: any[] = [];

    data.forEach((element: any) => {
      if (element.speed)
        Object.keys(element.speed).forEach((e: any) => {
          dataTotal.push({
            TRAIN: element?.functionaLocation?.train,
            CARRIAGE: element?.functionaLocation?.carriage,
            'FUNCTIONAL LOCATION': element?.functionaLocation?.locationName,
            'TYPE OF MEASUREMENT': 'Speed',
            VALUE: element.speed[e],
            DATETIME: e,
            TIMEZONE: timeZone
          });
        });
      if (element.vibration)
        Object.keys(element.vibration).forEach((e: any) => {
          dataTotal.push({
            TRAIN: element?.functionaLocation?.train,
            CARRIAGE: element?.functionaLocation?.carriage,
            'FUNCTIONAL LOCATION': element?.functionaLocation?.locationName,
            'TYPE OF MEASUREMENT': 'Vibration',
            VALUE: element.vibration[e],
            DATETIME: e,
            TIMEZONE: timeZone
          });
        });
      if (element.temperature)
        Object.keys(element.temperature).forEach((e: any) => {
          dataTotal.push({
            TRAIN: element?.functionaLocation?.train,
            CARRIAGE: element?.functionaLocation?.carriage,
            'FUNCTIONAL LOCATION': element?.functionaLocation?.locationName,
            'TYPE OF MEASUREMENT': 'Temperature',
            VALUE: element.temperature[e],
            DATETIME: e,
            TIMEZONE: timeZone
          });
        });
    });

    const ws = XLSX.utils.json_to_sheet(dataTotal);
    XLSX.utils.book_append_sheet(wb, ws, 'History');

    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'SKF Insight Rail Web Event Case Report.xlsx');
  };

  render() {
    let csvData = this.props.filteredAxleboxData;
    return (
      <div>
        <input
          type="image"
          src={reportIcon}
          onClick={this.handleClick}
          alt=""
        />
        <Modal
          handleHideModal={this.hideModal}
          width={'0%'}
          toggleModal={this.state.toggleModal ? 'block' : 'none'}
        >
          <h2>Export History data</h2>
          <br></br> <br></br> <br></br>
          <ComponentColumns>
            <ComponentColumn
              onClick={() => this.handleClickExcel(csvData)}
              style={{ cursor: 'pointer' }}
            >
              <input
                type="image"
                style={{ width: 60 }}
                src={excelIcon}
                alt=""
              />

              <Span>Export a Excel file</Span>
            </ComponentColumn>
            <br></br>
            <br></br>
            <Span>
              This will download a file of the History data shown in the
              previous screen according to the filters applied. This can take a
              few seconds to finish.
            </Span>
            <br></br>
            <br></br>
          </ComponentColumns>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  timeZone: state.timeZone.timeZone
});

export default connect(mapStateToProps)(PdfHistory);
