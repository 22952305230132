import { Observable, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ApplicationState } from '../reducers';
import { Services } from '../services';
import { StateObservable, combineEpics } from 'redux-observable';
import {
  trainDashboardFilterSelect,
  TrainsDashboardFilterAction,
  trainDashboardFilter,
  trainDashboardFilterRemove,
  trainDashboardBearingStatusSelect,
  trainDashboardWheelsStatusSelect,
  trainDashboardTrainStatusSelect,
  trainDashboardSensorStatusSelect,
  trainDashboardMotorStatusSelect,
  trainDashboardGearStatusSelect
} from '../actions/trainsDashboardAction';
import { getFilteredTrainDashboardSelector } from '../selectors/getTrainDashboardSelector';
import { AlertStatus } from '../models/alerts';

const addFilterTrainDashboardEpicCreator = () => (
  action$: Observable<TrainsDashboardFilterAction>,
  state$: StateObservable<ApplicationState>,
  services: Services
) =>
  action$.pipe(
    filter(isActionOf(trainDashboardFilterSelect)),
    mergeMap(action => {
      let previousFilter = [
        ...state$.value.newReducers.fetchTrainDashboardAsyncReducer
          .filteredVehicles
      ];
      const { payload } = action;
      if (!previousFilter.find(v => v === payload)) {
        previousFilter = [...previousFilter, payload];
      }
      return of(previousFilter).pipe(
        map(filter => trainDashboardFilter(filter))
      );
    })
  );

const removeFilterTrainDashboardEpicCreator = () => (
  action$: Observable<TrainsDashboardFilterAction>,
  state$: StateObservable<ApplicationState>,
  services: Services
) =>
  action$.pipe(
    filter(isActionOf(trainDashboardFilterRemove)),
    mergeMap(action => {
      let previousFilter = [
        ...state$.value.newReducers.fetchTrainDashboardAsyncReducer
          .filteredVehicles
      ];
      const { payload } = action;
      const index = previousFilter.indexOf(payload);
      if (index >= 0) {
        previousFilter.splice(index, 1);
      }
      return of(previousFilter).pipe(
        map(filter => trainDashboardFilter(filter))
      );
    })
  );

const bearingFilterTrainDashboardEpicCreator = () => (
  action$: Observable<TrainsDashboardFilterAction>,
  state$: StateObservable<ApplicationState>,
  services: Services
) =>
  action$.pipe(
    filter(isActionOf(trainDashboardBearingStatusSelect)),
    mergeMap(action => {
      const { payload } = action;
      let filteredTrains = payload.trainsByClient;
      if (payload.type === AlertStatus.RED) {
        filteredTrains = filteredTrains.filter(t => t.totalBearingRed > 0);
      } else {
        filteredTrains = filteredTrains.filter(t => t.totalBearingAmber > 0);
      }
      return of(filteredTrains.map(t => t.reference)).pipe(
        map(filter => trainDashboardFilter(filter))
      );
    })
  );

const wheelFilterTrainDashboardEpicCreator = () => (
  action$: Observable<TrainsDashboardFilterAction>,
  state$: StateObservable<ApplicationState>,
  services: Services
) =>
  action$.pipe(
    filter(isActionOf(trainDashboardWheelsStatusSelect)),
    mergeMap(action => {
      const { payload } = action;
      let filteredTrains = payload.trainsByClient;

      if (payload.type === AlertStatus.RED) {
        filteredTrains = filteredTrains.filter(t => t.totalWheelRed > 0);
      } else {
        filteredTrains = filteredTrains.filter(t => t.totalWheelAmber > 0);
      }
      return of(filteredTrains.map(t => t.reference)).pipe(
        map(filter => trainDashboardFilter(filter))
      );
    })
  );

const motorFilterTrainDashboardEpicCreator = () => (
  action$: Observable<TrainsDashboardFilterAction>,
  state$: StateObservable<ApplicationState>,
  services: Services
) =>
  action$.pipe(
    filter(isActionOf(trainDashboardMotorStatusSelect)),
    mergeMap(action => {
      const { payload } = action;
      let filteredTrains = payload.trainsByClient;
      if (payload.type === AlertStatus.RED) {
        filteredTrains = filteredTrains.filter(
          t => t.totalTractionMotorRed > 0
        );
      } else {
        filteredTrains = filteredTrains.filter(
          t => t.totalTractionMotorAmber > 0
        );
      }
      return of(filteredTrains.map(t => t.reference)).pipe(
        map(filter => trainDashboardFilter(filter))
      );
    })
  );

const gearFilterTrainDashboardEpicCreator = () => (
  action$: Observable<TrainsDashboardFilterAction>,
  state$: StateObservable<ApplicationState>,
  services: Services
) =>
  action$.pipe(
    filter(isActionOf(trainDashboardGearStatusSelect)),
    mergeMap(action => {
      const { payload } = action;
      let filteredTrains = payload.trainsByClient;

      if (payload.type === AlertStatus.RED) {
        filteredTrains = filteredTrains.filter(t => t.totalGearBoxRed > 0);
      } else {
        filteredTrains = filteredTrains.filter(t => t.totalGearBoxAmber > 0);
      }
      return of(filteredTrains.map((t: any) => t.reference)).pipe(
        map(filter => trainDashboardFilter(filter))
      );
    })
  );

const totalFilterTrainDashboardEpicCreator = () => (
  action$: Observable<TrainsDashboardFilterAction>,
  state$: StateObservable<ApplicationState>,
  services: Services
) =>
  action$.pipe(
    filter(isActionOf(trainDashboardTrainStatusSelect)),
    mergeMap(action => {
      const { payload } = action;
      let filteredTrains = payload.trainsByClient;

      if (payload.type === AlertStatus.RED) {
        filteredTrains = filteredTrains.filter(t => t.totalRed > 0);
      } else {
        filteredTrains = filteredTrains.filter(t => t.totalAmber > 0);
      }
      return of(filteredTrains.map(t => t.reference)).pipe(
        map(filter => trainDashboardFilter(filter))
      );
    })
  );

const sensorFilterTrainDashboardEpicCreator = () => (
  action$: Observable<TrainsDashboardFilterAction>,
  state$: StateObservable<ApplicationState>,
  services: Services
) =>
  action$.pipe(
    filter(isActionOf(trainDashboardSensorStatusSelect)),
    mergeMap(action => {
      const { payload } = action;
      let filteredTrains = payload.trainsByClient;
      filteredTrains = filteredTrains.filter(t => t.totalSensorAmber > 0);
      return of(filteredTrains.map(t => t.reference)).pipe(
        map(filter => trainDashboardFilter(filter))
      );
    })
  );

export const filterTrainDashboardEpicCreator = () =>
  combineEpics(
    ...[
      sensorFilterTrainDashboardEpicCreator(),
      totalFilterTrainDashboardEpicCreator(),
      wheelFilterTrainDashboardEpicCreator(),
      bearingFilterTrainDashboardEpicCreator(),
      removeFilterTrainDashboardEpicCreator(),
      addFilterTrainDashboardEpicCreator(),
      motorFilterTrainDashboardEpicCreator(),
      gearFilterTrainDashboardEpicCreator()
    ]
  );
