import React, { Component } from 'react';
import Moment from 'moment';
import uuidv1 from 'uuid/v1';
import styled from 'styled-components';
import { Image, Col } from 'react-bootstrap';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';

const ThCentered = styled.th`
  text-align: center;
  padding: 12px 0;
`;

const THead = styled.thead`
  border: 1px solid #eae8e8;
  background-color: #eae8e8;
  font-weight: 700;
  font-size: 14 px;
`;
const ThFL = styled.th`
  padding-left: 10px;
`;

class EventsTable extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      expandedRows: []
    };
  }

  formatDate(timestamp) {
    Moment.locale('en');
    return Moment(timestamp).format('lll');
  }

  handleExpand = event => {
    let newExpandedRows = [...this.state.expandedRows];

    let idxFound = newExpandedRows.findIndex(eventId => {
      return eventId === event.id;
    });

    if (idxFound > -1) {
      newExpandedRows.splice(idxFound, 1);
    } else {
      newExpandedRows.push(event.id);
    }

    this.setState({ expandedRows: [...newExpandedRows] });
  };

  isExpanded = event => {
    const idx = this.state.expandedRows.find(eventId => {
      return eventId === event.id;
    });
    return idx !== undefined;
  };

  getRows = (event, index) => {
    const eventStatusString =
      (event.status === 0 && 'New') ||
      (event.status === 1 && 'Open') ||
      (event.status === 2 && 'FollowUp') ||
      (event.status === 3 && 'Closed') ||
      event.status;
    const image = this.getImage();
    let rows = [];
    const firstRow = (
      <tr
        id={index}
        key={uuidv1()}
        className={`eventsRow ${
          event.activeAlarm.toLowerCase() === 'red'
            ? 'redAlarm'
            : event.activeAlarm.toLowerCase() === 'amber'
            ? 'amberAlarm'
            : ''
        }`}
      >
        <td className="showAlert">
          <span className="eventTitle">{event.functionalLocationID} </span>
        </td>
        <td className="text-center">
          <span className="eventDesc">{event.assetType}</span>
        </td>
        <td className="vCenter text-center">
          <div
            className={`eventDesc m-auto ${
              event.activeAlarm.toLowerCase() === 'red' &&
              event.measurementType.toLowerCase().indexOf('temperature') !== -1
                ? 'act-now-alarm act-now-text'
                : event.activeAlarm.toLowerCase() === 'red'
                ? 'red-color'
                : event.activeAlarm.toLowerCase() === 'amber'
                ? 'amber-color'
                : ''
            }`}
          >
            {`${
              event.activeAlarm.toLowerCase() === 'red' &&
              event.measurementType.toLowerCase().indexOf('temperature') !== -1
                ? 'Act now'
                : event.activeAlarm.toLowerCase() === 'red'
                ? 'Act'
                : event.activeAlarm.toLowerCase() === 'amber'
                ? 'Plan'
                : ''
            }`}
          </div>
        </td>
        <td className="text-center">
          <span className="eventDesc">{event.measurementType} </span>
        </td>
        <td>
          {event.measurementType.toLowerCase() === 'temperature'
            ? `${event.temperature_External} ºC`
            : null}
        </td>
        {/* <td className="text-center">
          <span className="eventDesc"> 0 days left</span>
        </td> */}

        <td className="text-center">
          <span className="eventDesc">
            {this.formatDate(event.alarmTimestamp)}
          </span>
        </td>
        <td>
          <div className="eventDesc m-auto text-center event-status event-status-text">
            {eventStatusString}
          </div>
        </td>

        <td id={index} onClick={() => this.handleExpand(event)}>
          <FontAwesomeIcon
            size="1x"
            icon={this.isExpanded(event) ? faChevronDown : faChevronRight}
          />
        </td>
      </tr>
    );

    rows.push(firstRow);

    if (this.isExpanded(event)) {
      const detailRow = (
        <tr
          key={uuidv1()}
          className={`eventsRow ${
            event.activeAlarm.toLowerCase() === 'red'
              ? 'redAlarm'
              : event.activeAlarm.toLowerCase() === 'amber'
              ? 'amberAlarm'
              : ''
          }`}
        >
          <td colSpan="8" className="player-details">
            <div className="collapse-items">
              <fieldset className="event-fieldset">
                <legend className="event-legend">
                  {event.diagnosis ? 'ASSESSMENT' : ''}
                </legend>
                <div className="text-event-toggle">{event.diagnosis}</div>
              </fieldset>
              <fieldset className="event-fieldset">
                <legend className="event-legend">
                  {event.recommendation ? 'RECOMMENDATION' : ''}
                </legend>
                <span className="text-event-toggle">
                  {event.recommendation || ''}
                </span>
              </fieldset>
              {/*               <fieldset className="event-fieldset">
                <legend className="event-legend">
                  {event.recommendation ? 'PICTURE' : ''}
                </legend>
                <span className="text-event-toggle">
                 {event.recommendation ? image : ''}
                </span>
              </fieldset> */}
            </div>
          </td>
        </tr>
      );
      rows.push(detailRow);
    }
    return rows;
  };

  getDataTable = () => {
    const dataRows = this.props.eventsData
      .map((data, index) => {
        return this.getRows(data, index);
      })
      .reverse();
    return (
      <table className="eventsTable">
        <THead>
          <tr>
            <ThFL>Functional Location</ThFL>
            <ThCentered>Asset</ThCentered>
            <ThCentered>Action</ThCentered>
            <ThCentered>Measurement Type</ThCentered>
            <ThCentered />
            <ThCentered>Reported on</ThCentered>
            <ThCentered>Status</ThCentered>
            <th></th>
          </tr>
        </THead>
        <tbody>{dataRows}</tbody>
      </table>
    );
  };

  getImage() {
    return (
      <React.Fragment>
        <p>Ball spin frequencies in the envelope spectra</p>
        <Col xs="12" lg={{ span: 8, offset: 2 }}>
          <Image
            className="imageRecommendations"
            src="/images/skf-bg-login.jpg"
          />
        </Col>
      </React.Fragment>
    );
  }
  render() {
    return <div key={uuidv1()}>{this.getDataTable()}</div>;
  }
}

export default EventsTable;
