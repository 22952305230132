import { FeedBackModel, Fault } from '../models/feedback';
import { customError, catchError } from './error.service';
import { authHeader } from '../helpers';
import axios from 'axios';

export const getFeedback = async (sourceKey: string, sourceType: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/Feedback/GetFeedback/${sourceKey}/${sourceType}`,
      {
        headers: authHeader()
      }
    )
    .then(response => customError(response))
    .then(response => {
      return response;
    })

    .catch(error => {
      return Promise.resolve(resetFeedBackModel());
    });
};

export const getFeedbackFile = async (
  sourceId: string,
  companyId: string,
  feedbackFilename: string,
  faultId: number
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/Feedback/GetFeedBackFile/sourceId/${sourceId}/companyId/${companyId}/faultId/${faultId}/fileName/${feedbackFilename}`,
      {
        headers: authHeader(),
        responseType: 'blob'
      }
    )
    .then(response => customError(response))
    .then(response => {
      return response;
    })

    .catch(function(error) {
      catchError(error.message);
    });
};

export const saveFeedback = async (feedBack: FeedBackModel) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/Feedback/SaveFeedback`,
      feedBack,
      {
        headers: authHeader()
      }
    )
    .then(response => customError(response))
    .then(response => {
      return response;
    })

    .catch(function(error) {
      catchError(error.message);
    });
};

export const saveFeedbackImg = async (feedBack: any) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/Feedback/SaveFeedbackFile`,
      feedBack,
      {
        headers: authHeader()
      }
    )
    .then(response => customError(response))
    .then(response => {
      return response;
    })

    .catch(function(error) {
      catchError(error.message);
    });
};

export const getFaultClassificationCategories = async (asset: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/Feedback/FaultClassificationCategories/${asset}`,
      {
        headers: authHeader()
      }
    )
    .then(response => customError(response))
    .then(response => {
      return response;
    })

    .catch(function(error) {
      catchError(error.message);
    });
};

export const getFaultLocationCategories = async (asset: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/Feedback/FaultLocationCategories/${asset}`,
      {
        headers: authHeader()
      }
    )
    .then(response => customError(response))
    .then(response => {
      return response;
    })

    .catch(function(error) {
      catchError(error.message);
    });
};

export const resetFault = (): Fault => {
  return {
    faultId: 0,
    classification: '',
    faultPosition: '',
    severity: '',
    feedBackComment: '',
    base64File: '',
    file: new Uint8Array(),
    fileName: ''
  };
};

export const resetFeedBackModel = (): FeedBackModel => {
  return {
    eventCaseId: '',
    modifiedBy: sessionStorage.getItem('username'),
    faultCorrect: true,
    faultCorrectReason: true,
    totalMileageBearing: 0,
    lastMileageInterval: 0,
    faultFound: false,
    faults: [resetFault()],
    sourceFlag: '',
    sourceId: '',
    sourceKey: '',
    sourceType: ''
  };
};
