/** it expects vehiclesDesignation from a brother mstp function */

const getTrainChildren = (train, vehiclesDesignation) => {
  const result = [];
  vehiclesDesignation
    .filter(element => {
      return element.designation === train;
    })
    .forEach(value => {
      value.carriageNumber.forEach(carriage => {
        result.push({
          label: carriage
        });
      });
    });
  return result;
};

const getFleetChildren = (fleet, vehiclesDesignation) => {
  return vehiclesDesignation
    .filter(element => {
      return element.fleetType === fleet;
    })
    .map(value => {
      return {
        label: value.designation,
        children: getTrainChildren(value.designation, vehiclesDesignation)
      };
    });
};

const referenceForFilterConstructor = vehiclesDesignation => {
  let result = [];

  if (vehiclesDesignation && vehiclesDesignation.length) {
    vehiclesDesignation.forEach(item => result.push(item.fleetType));
    result = [...new Set(result)];
    result = result.map(reference => {
      return {
        label: reference,
        children: getFleetChildren(reference, vehiclesDesignation)
      };
    });
  }
  return result;
};

export default referenceForFilterConstructor;
