import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DatePickerRange from '../../reports/reportsTable/datePickerRange';
import BubbleTree from './bubbleTree';
import TreeSelectComponent from './treeSelect';
import { Col, Row } from 'react-bootstrap';
import PdfHistory from '../../history/pdfHistory';
const ComponentContainer = styled.div`
  display: flex;
  margin: 0 40px;
  :first-child {
    margin-left: 0;
  }
  position: relative;
  z-index: 10;
`;

const FilterContainer = styled.div`
  width: 12%;
  margin-right: 20px;
  margin-top: 30px;
`;

const HierarchyContainer = styled.div`
  width: auto;
  margin-top: 1px;
  margin-right: 20px;
  transform: translateY(29px);

  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
  }
  .tag-list {
    display: inline-block;
    flex-flow: row-reverse;
    flex-wrap: wrap-reverse;
  }
  .tag-item .tag {
    margin: 5px 0;
    display: inline-flex;
    align-items: center;
  }
  .node.disabled > * {
    cursor: default;
  }
`;

const DownloadReportContainer = styled.div`
  margin-left: 20px;
  margin-top: 30px;
`;

const DownloadReportButtonContainer = styled(DownloadReportContainer)``;
const DatePickerContainer = styled(FilterContainer)``;

const filterReferencesBasedOnFleets = (value, state) => {
  let result = false;
  if (!state.fleetsSelected.length) {
    result = true;
  } else if (
    state.fleetsSelected.some(
      fleetSelected => value.fleetType === fleetSelected.value
    )
  ) {
    result = true;
  }
  return result;
};

const filterReferencesBasedOnVehicles = (value, state) => {
  let result = false;
  if (!state.vehiclesSelected.length) {
    result = true;
  } else if (
    state.vehiclesSelected.some(
      vehicleSelected => value.designation === vehicleSelected.value
    )
  ) {
    result = true;
  }
  return result;
};

const filterByHierarchy = arr => {
  const arrReversed = arr.reverse();
  const result = arrReversed.find(element => element.value !== 'All');
  return ((result !== -1 || result !== undefined) && result) || null;
};

class FiltersContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fleetValues: [],
      vehiclesValues: [],
      carriagesValues: [],
      fleetsSelected: [],
      vehiclesSelected: [],
      carriagesSelected: [],
      hierarchySelected: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.fleetValues !== state.fleetValues) {
      const fleetValues = props.sfk_VehiclesReferences
        .filter(() =>
          state.fleetsSelected.length >= 1
            ? state.fleetsSelected[0].value !== 'All'
            : true
        )
        .map(value => {
          return value.fleetType;
        });

      const vehiclesValues = props.sfk_VehiclesReferences
        .filter(() =>
          state.vehiclesSelected.length >= 1
            ? state.vehiclesSelected[0].value !== 'All'
            : true
        )
        .filter(value => {
          const result =
            state.fleetsSelected.length &&
            state.fleetsSelected[0].value !== 'All'
              ? filterReferencesBasedOnFleets(value, state)
              : true;
          return result;
        })
        .map(value => value.designation);

      const carriagesValues = props.mergeArrays(
        props.sfk_VehiclesReferences
          .filter(() =>
            state.carriagesSelected.length >= 1
              ? state.carriagesSelected[0].value !== 'All'
              : true
          )
          .filter(value =>
            state.fleetsSelected.length &&
            state.fleetsSelected[0].value !== 'All'
              ? filterReferencesBasedOnFleets(value, state)
              : true
          )
          .filter(value =>
            state.vehiclesSelected.length &&
            state.vehiclesSelected[0].value !== 'All'
              ? filterReferencesBasedOnVehicles(value, state)
              : true
          )
          .map(value => value.carriageNumber)
      );
      let bubbleHierarchy = {};
      if (
        state.vehiclesSelected.length &&
        state.carriagesSelected.length &&
        state.fleetsSelected.length
      ) {
        const hierarchyArray = filterByHierarchy([
          state.fleetsSelected,
          state.vehiclesSelected,
          state.carriagesSelected
        ]);
        bubbleHierarchy = {
          fleetsSelected: [],
          vehiclesSelected: [],
          carriagesSelected: [],
          hierarchySelected: [...state.hierarchySelected, hierarchyArray],
          fleetValues: [
            'All',
            ...props.sfk_VehiclesReferences.map(value => {
              return value.fleetType;
            })
          ],
          vehiclesValues: [
            'All',
            ...props.sfk_VehiclesReferences.map(value => value.designation)
          ],
          carriagesValues: props.mergeArrays([
            'All',
            ...props.sfk_VehiclesReferences.map(value => value.carriageNumber)
          ])
        };
      }

      const myNewState = {
        fleetValues: !state.fleetsSelected.length
          ? ['All', ...fleetValues]
          : [...fleetValues],
        vehiclesValues: !state.vehiclesSelected.length
          ? ['All', ...vehiclesValues]
          : [...vehiclesValues],
        carriagesValues: !state.carriagesSelected.length
          ? ['All', ...carriagesValues]
          : [...carriagesValues],
        ...bubbleHierarchy
      };

      return myNewState;
    }

    return null;
  }
  getValuehierarchyFleetData = value => {
    return value;
  };
  handleBubbleClick = ({ value, root }) => {
    switch (root) {
      case 'hierarchyFleetData':
        this.handleHierarchyFleetOnChange(
          this.getValuehierarchyFleetData(value)
        );
        break;
      case 'mType':
        this.handleMesTypeOnChange(value);

        break;
      case 'assetStatusSelected':
        this.handleAssetStatusDelete({ value, callAxleboxService: false });

        break;
    }
  };

  handleHierarchyFleetOnChange = value => {
    if (typeof value !== 'string') {
      let tmpValue = value;
      tmpValue.value = tmpValue[1].triggerValue;
      value = tmpValue;
    }

    this.props.historyMainCall({
      value,
      root: 'hierarchyFleetData'
    });
  };

  handleMesTypeOnChange = value => {
    this.props.handleMeasuramentTypeChange(value);
  };

  handleAssetStatusChange = value => {
    this.props.handleAssetStatusChange(value);
  };

  handleAssetStatusDelete = ({ value, callAxleboxService }) => {
    this.props.handleAssetStatusDelete(value);
  };

  filterChilds = (valueSelected, valueToChange) => {
    let referencetoGet = '';
    switch (valueToChange) {
      case 'vehiclesValues':
        referencetoGet = 'designation';
        break;
      case 'carriagesValues':
        referencetoGet = 'referenceCarriage';
        break;
      default:
        break;
    }

    const result = [];
    const selected = this.state[valueSelected];
    selected.forEach(value => {
      const vehiclesfiltered = this.props.sfk_VehiclesReferences.filter(
        element => {
          return element.elementType === value;
        }
      );
      result.push(vehiclesfiltered[referencetoGet]);
    });
    this.setState({ [valueToChange]: result });
  };

  handleFleetOnChange = newValue => {
    this.setState({ fleetsSelected: newValue });
  };

  handleVehicleOnChange = newValue => {
    this.setState({ vehiclesSelected: newValue });
  };

  handleCarriageOnChange = newValue => {
    this.setState({ carriagesSelected: newValue });
  };

  handleOnClick = (newValue, calledFrom) => {
    switch (calledFrom) {
      case 'fleet':
        this.handleFleetOnChange(newValue);
        break;
      case 'vehicle':
        this.handleVehicleOnChange(newValue);
        break;
      case 'carriage':
        this.handleCarriageOnChange(newValue);
        break;
      default:
        break;
    }
  };

  constructOptions = values => {
    return (
      values
        // .filter(arr => this.filterValues(arr))
        .map(value => {
          return { value, label: value };
        })
    );
  };

  render() {
    const {
      handleStartDateChange,
      handleEndDateChange,
      startDate,
      endDate,
      minDate,
      maxDate,
      selectedDate,
      skf_TreeData,
      hierarchyFleetData,
      assetStatusSelected
    } = this.props;

    const aux = this.props.hierarchyFleetData.map(e => {
      return [e, skf_TreeData.find(e2 => e2.key === e)?.title];
    });
    return (
      <Fragment>
        <ComponentContainer className="Reports_Filter">
          <Row>
            <Col>
              <HierarchyContainer>
                <TreeSelectComponent
                  placeholder={'Search or Select'}
                  title=""
                  skf_TreeData={skf_TreeData}
                  onChange={value => this.handleHierarchyFleetOnChange(value)}
                  value={hierarchyFleetData}
                  valuesSelected={hierarchyFleetData}
                />
              </HierarchyContainer>
            </Col>
            <Col>
              <HierarchyContainer>
                <TreeSelectComponent
                  title=""
                  placeholder={'Action'}
                  skf_TreeData={[
                    {
                      key: 'ActNow',
                      pId: 0,
                      title: 'Act Now',
                      value: 'Act Now'
                    },
                    { key: 'Act', pId: 0, title: 'Act', value: 'Act' },
                    { key: 'Plan', pId: 0, title: 'Plan', value: 'Plan' }
                  ]}
                  onChange={this.handleAssetStatusChange}
                  value={assetStatusSelected}
                  singleSelect
                  valuesSelected={assetStatusSelected}
                />
              </HierarchyContainer>
            </Col>
            <Col>
              <DatePickerContainer>
                <DatePickerRange
                  handleStartDateChange={handleStartDateChange}
                  handleEndDateChange={handleEndDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  selectedDate={selectedDate}
                />
              </DatePickerContainer>
            </Col>
            {this.props.isDownloadReport && (
              <Col>
                <DownloadReportButtonContainer>
                  <PdfHistory
                    startDate={startDate}
                    endDate={endDate}
                    onlyOpen={this.props.onlyOpen}
                    measuramentValue={''}
                    actionValue={assetStatusSelected}
                    hierarchyValue={[]}
                    filteredAxleboxData={this.props.filteredAxleboxData}
                  />
                </DownloadReportButtonContainer>
              </Col>
            )}
          </Row>
        </ComponentContainer>
        {this.props.hierarchyFleetData &&
          this.props.hierarchyFleetData.length > 0 && (
            <BubbleTree
              hierarchyFleetData={aux}
              handleBubbleClick={this.handleBubbleClick}
              assetStatusSelected={assetStatusSelected}
            />
          )}
      </Fragment>
    );
  }
}

FiltersContainer.defaultProps = {
  fleetValues: [],
  hierarchyFleetData: [],
  sfk_VehiclesReferences: [],

  historyMainCall: () => null,
  handleMeasuramentTypeChange: () => null,
  handleAssetStatusChange: () => null,
  handleStartDateChange: () => null,
  handleEndDateChange: () => null,
  startDate: Date.now(),
  endDate: Date.now(),
  minDate: Date.now(),
  maxDate: Date.now(),
  selectedDate: Date.now()
};

FiltersContainer.propTypes = {
  /** options to populate the vehicle selector */
  fleetValues: PropTypes.array,
  /** options to populate the fleet/train/vehicle selector */
  hierarchyFleetData: PropTypes.array,
  sfk_VehiclesReferences: PropTypes.array,

  historyMainCall: PropTypes.func,
  handleStartDateChange: PropTypes.func,
  handleEndDateChange: PropTypes.func,
  handleMeasuramentTypeChange: PropTypes.func,
  handleAssetStatusChange: PropTypes.func,
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
};

export default FiltersContainer;
