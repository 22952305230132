import React from 'react';
import { Form } from 'react-bootstrap';

const SelectForm = ({ handleChange, typeVehicle }) => {
  const selectForm = (
    <Form className="font-weight-bold">
      <Form.Group
        controlId="selectForm"
        className="col-auto d-flex align-items-baseline"
      >
        <Form.Label className="inline-form col-md-4 col-xl-6 text-right">
          Order by&nbsp;
        </Form.Label>
        <Form.Control
          as="select"
          className="select font-weight-bold inline-form col-md-8 col-xl-6 border-top-0 border-left-0 border-right-0 rounded-0 battleship"
          onChange={handleChange}
        >
          <option value="critical">Most critical {typeVehicle}</option>
          <option value="recent">Most recent events</option>
        </Form.Control>
      </Form.Group>
    </Form>
  );
  return selectForm;
};

export default SelectForm;
