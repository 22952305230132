import axios from 'axios';
import { authHeader } from '../helpers';
import { customError } from './error.service';
function getSensorMaxTempByCarriage(hashCarriage, hashVehicle, date) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Sensors/GetSensorMaxTempInfoByLevel/${hashCarriage}/vehicle/${hashVehicle}/level/Carriages/thedate/${date}`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(error => {
    return error;
  });
}
function getSensorMaxTempByFunctionalLocation(hashAxlebox, hashVehicle, date) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Sensors/GetSensorMaxTempInfoByAsset/${hashAxlebox}/vehicle/${hashVehicle}/thedate/${date}`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(error => {
    return error;
  });
}
export const chartService = {
  getSensorMaxTempByCarriage,
  getSensorMaxTempByFunctionalLocation
};