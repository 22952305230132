import { createAction, ActionType } from 'typesafe-actions';
import { AlertStatus } from '../models/alerts';

export const trainDashboardFilterSelect = createAction(
  'TRAINS_DASHBOARD/FILTER_SELECT',
  (vehicle: string) => vehicle // payload creator
)();

export const clientsDashboard = createAction(
  'CLIENTS_DASHBOARD/FILTER_SELECT',
  (clients: any) => clients // payload creator
)();

export const trainDashboardFilterRemove = createAction(
  'TRAINS_DASHBOARD/FILTER_REMOVE',
  (vehicle: string) => vehicle // payload creator
)();

export const trainDashboardFilterClear = createAction(
  'TRAINS_DASHBOARD/FILTER_CLEAR'
)();

export const trainDashboardFilter = createAction(
  'TRAINS_DASHBOARD/FILTER',
  (vehicles: string[]) => vehicles // payload creator
)();

export const trainDashboardBearingStatusSelect = createAction(
  'TRAINS_DASHBOARD/FILTER_BEARING_STATUS_SELECT',
  (status: any) => status,
  (trains: any) => trains
)();

export const trainDashboardSensorStatusSelect = createAction(
  'TRAINS_DASHBOARD/FILTER_SENSOR_STATUS_SELECT',
  (status: any) => status,
  (trains: any) => trains
)();

export const trainDashboardTrainStatusSelect = createAction(
  'TRAINS_DASHBOARD/FILTER_TRAIN_STATUS_SELECT',
  (status: any) => status,
  (trains: any) => trains
)();

export const trainDashboardWheelsStatusSelect = createAction(
  'TRAINS_DASHBOARD/FILTER_WHEELS_STATUS_SELECT',
  (status: any) => status,
  (trains: any) => trains
)();
export const trainDashboardMotorStatusSelect = createAction(
  'TRAINS_DASHBOARD/FILTER_MOTOR_STATUS_SELECT',
  (status: any) => status,
  (trains: any) => trains
)();
export const trainDashboardGearStatusSelect = createAction(
  'TRAINS_DASHBOARD/FILTER_GEAR_STATUS_SELECT',
  (status: any) => status,
  (trains: any) => trains
)();

const ActionCreators = {
  trainDashboardWheelsStatusSelect,
  trainDashboardTrainStatusSelect,
  trainDashboardSensorStatusSelect,
  trainDashboardFilterSelect,
  trainDashboardBearingStatusSelect,
  trainDashboardGearStatusSelect,
  trainDashboardMotorStatusSelect,
  trainDashboardFilterClear,
  trainDashboardFilter
};

export type TrainsDashboardFilterAction = ActionType<typeof ActionCreators>;
