import React from 'react';

import styled from 'styled-components';
import TreeSelectComponent from '../utils/filters/treeSelect';

const TrainFilterContainerOne = styled.div`
  height: 100%;
`;
const TrainFilterContainerTwo = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  width: 100%;
`;

const TrainFilterContainerFour = styled.div`
  transform: translateY(-8px);
  width: 100%;
  margin-left: 7vw;
`;

const DasboardTreeSelector = ({
  treeData,
  handledTreeSelected,
  hierarchyFleetData,
  valuesSelected
}: any) => {
  return (
    <TrainFilterContainerOne className="text-left">
      <TrainFilterContainerTwo>
        <TrainFilterContainerFour>
          <TreeSelectComponent
            title=""
            placeholder={' Quick search:'}
            skf_TreeData={treeData}
            onChange={handledTreeSelected}
            value={hierarchyFleetData}
            singleSelect
            spinner={false}
            valuesSelected={valuesSelected}
          />
        </TrainFilterContainerFour>
      </TrainFilterContainerTwo>
    </TrainFilterContainerOne>
  );
};

export { DasboardTreeSelector };
