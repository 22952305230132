import { wrapErrors } from './wrapErrors';
import { combineEpics } from 'redux-observable';
import { fetchTrainDashboardEpicCreator } from './fetchTrainDashboardEpic';
import { filterTrainDashboardEpicCreator } from './filterTrainDashboardEpic';
import { fetchLocationDashboardEpicCreator } from './fetchLocationDashboardEpic';

export const createRootEpic = () =>
  combineEpics(
    ...[
      fetchTrainDashboardEpicCreator(),
      filterTrainDashboardEpicCreator(),
      fetchLocationDashboardEpicCreator(),
    ].map(wrapErrors)
  );
