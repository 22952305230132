/** This component is a filter that allows multiple selection and return an array of the value.
 * @param { Array } options must be an array of objects with the params value and label
 * */
import React, { Component } from 'react';
import Select from 'react-select';

interface IDropDownProps {
  options: { label: string; value: number }[];
  defaultValue: number | string;
  title: string;
  selected: Function;
  cKey?: number;
}

export class DropDown extends Component<IDropDownProps> {
  constructor(props: any) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  customStyles: any = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid #d5d8dc',
      ':hover': {
        ...provided[':hover'],
        border: '1px solid #d5d8dc'
      }
    })
  };

  render() {
    var _this = this;
    const { options, defaultValue } = this.props;
    var valueSelected: any = 0;
    this.props.options &&
      this.props.options.forEach(item => {
        if (_this.props.defaultValue && item.label === _this.props.defaultValue)
          valueSelected = item;
      });
    // const { filterStatus } = this.state;
    let newOptions = options;

    return (
      <div>
        <p>
          <b>{this.props.title}</b>
        </p>
        <Select
          styles={this.customStyles}
          value={valueSelected}
          onChange={this.onChange}
          options={options}
        />
      </div>
    );
  }

  onChange(e: any) {
    this.props.selected(e.label, this.props.cKey);
  }
}

export interface DropDownValues {
  value: number;
  label: string;
}
