const lineOptions = {
  showAllTooltips: true,
  showXLabels: 7,
  animation: false,
  animation: {
    duration: 0
  },
  elements: {
    line: {
      tension: 0 // disables bezier curves
    }
  },
  responsive: true,
  title: {
    display: false,
    text: 'Temperature'
  },
  legend: false,
  hover: {
    mode: 'x',
    intersect: false
  },
  scales: {
    x: {
      id: 'XTemp',
      beginAtZero: true,
      display: true,
      gridLines: { color: 'rgba(255,255,255,0)' },
      type: 'time',
      time: {
        unit: 'day',
        unitStepSize: 1,
        round: 'minute',
        tooltipFormat: 'MM/DD/YY H:mm:ss',
        displayFormats: {
          millisecond: 'MMM DD',
          second: 'MMM DD',
          minute: 'MMM DD',
          hour: 'MMM DD',
          day: 'MMM DD',
          week: 'MMM DD',
          month: 'MMM DD',
          quarter: 'MMM DD',
          year: 'MMM DD'
        } /* ,
          min: "2018-08-29 00:00:00",
          max: "2018-08-30 23:59:59" */
      },
      ticks: {
        fixedStepSize: 1,
        maxTicksLimit: 15,
        autoSkip: true
      },
      distribution: 'linear'
    },
    y: {
      afterFit: scaleInstance => {
        scaleInstance.width = 35; // sets the width to 100px
      },
      id: 'YTemp',
      position: 'left',
      display: true,
      gridLines: {
        borderDash: [4, 4],
        borderDashOffset: 10,
        color: [
          'lightgrey',
          'rgb(183,28,28)',
          'rgba(255,193,7)',
          'lightgrey',
          'lightgrey'
        ] /* this
              .scaleOptions.ticks.callback === 0
              ? rgb(183, 28, 28)
              : rgba(255, 193, 7)*/
      },
      scaleLabel: {
        display: false,
        labelString: 'Value'
      },
      ticks: {
        maxTicksLimit: 5,
        /*  min: 0,
          max: 100, */
        stepSize: 20,
        fontSize: 10,
        display: true,
        callback: (tick, index, ticksArray) => {
          //console.log(tick, index, ticksArray);
          return tick;
        },
        showLabelBackdrop: true
      },
      pointLabels: {
        display: true
      },
      callback: gridLines => {
        //console.log(gridLines);
      }
    }
  }
};

const radarOptions = {
  elements: {
    line: {
      tension: 0 // disables bezier curves
    }
  },
  legend: {
    display: false
  },
  animation: false,
  animation: {
    duration: 0
  },
  hover: {
    animationDuration: 0 // duration of animations when hovering an item
  },
  responsiveAnimationDuration: 0,

  responsive: true,
  title: {
    display: false
  },
  plugins: {
    sensorColorPlugin: { enable: true },
    crosshair: false
  },
  scale: {
    gridLines: {
      circular: true,
      color: ['#efefef', '#efefef', '#efefef', '#ffca15', '#cc0000', '#efefef'],
      lineWidth: 1,
      borderDash: [10, 5],
      borderDashOffset: 10
    },
    /* radial lines */
    angleLines: {
      display: false
    },
    /* 0, 20, 40, 60, 80, 100 */
    ticks: {
      display: true,
      backdropColor: 'rgba(0,0,0,0)',
      fontColor: 'black',
      showLabelBackdrop: true,
      beginAtZero: false,
      min: 0,
      max: 100,
      stepSize: 10
    }
  }
};

const vibrationOptions = {
  showAllTooltips: true,
  showXLabels: 7,
  animation: false,
  normalized: true,
  animation: {
    duration: 0
  },
  elements: {
    line: {
      tension: 0 // disables bezier curves
    }
  },
  responsive: true,
  title: {
    display: false,
    text: 'Vibration'
  },
  legend: false,
  hover: {
    mode: 'x',
    intersect: false
  },
  scales: {
    x: {
      id: 'XVib',
      beginAtZero: true,
      display: true,
      gridLines: { color: 'rgba(255,255,255,0)' },

      type: 'time',

      time: {
        unit: 'day',
        unitStepSize: 1,
        round: 'minute',

        tooltipFormat: 'MMM DD, YYYY H:mm:ss',
        displayFormats: {
          millisecond: 'MMM DD',
          second: 'MMM DD',
          minute: 'MMM DD',
          hour: 'MMM DD',
          day: 'MMM DD',
          week: 'MMM DD',
          month: 'MMM DD',
          quarter: 'MMM DD',
          year: 'MMM DD'
        }
      },
      ticks: {
        fixedStepSize: 2,
        maxTicksLimit: 15,
        sampleSize: 15,
        autoSkip: true
      },

      distribution: 'linear'
    },
    y: {
      afterFit: function(scaleInstance) {
        scaleInstance.width = 35; // sets the width to 100px
      },
      id: 'YVib',
      position: 'left',
      display: true,
      gridLines: {
        borderDash: [4, 4],
        borderDashOffset: 10,
        color: [
          'lightgrey',
          'lightgrey',
          'lightgrey',
          'lightgrey',
          'lightgrey',
          'lightgrey'
        ]
      },
      scaleLabel: {
        display: false,
        labelString: 'Value'
      },
      ticks: {
        maxTicksLimit: 5,
        min: 0,
        fontSize: 10,
        display: true
      },
      pointLabels: {
        display: true
      }
    }
  },
  plugins: {
    tooltips: {
      callbacks: {
        label: function(tooltipItem) {
          return '';
        }
      }
    },

    crosshair: {
      line: {
        color: '#F66', // crosshair line color
        width: 1 // crosshair line width
      },
      sync: {
        enabled: true, // enable trace line syncing with other charts
        group: 1, // chart group
        suppressTooltips: false // suppress tooltips when showing a synced tracer
      },

      callbacks: {
        beforeZoom: () =>
          function(start, end) {
            return true;
          },
        afterZoom: () => function(start, end) {}
      }
    }
  }
};

const speedOptions = {
  showAllTooltips: true,
  showXLabels: 7,
  animation: false,
  normalized: true,
  animation: {
    duration: 0
  },
  elements: {
    line: {
      tension: 0 // disables bezier curves
    }
  },
  responsive: true,

  title: {
    display: false,
    text: 'Speed'
  },
  legend: false,
  hover: {
    mode: 'x',
    intersect: false
  },
  scales: {
    x: {
      id: 'XVib',
      beginAtZero: true,
      display: true,
      gridLines: { color: 'rgba(255,255,255,0)' },

      type: 'time',

      time: {
        unit: 'day',
        unitStepSize: 1,
        round: 'minute',

        tooltipFormat: 'MMM DD, YYYY H:mm:ss',
        displayFormats: {
          millisecond: 'MMM DD',
          second: 'MMM DD',
          minute: 'MMM DD',
          hour: 'MMM DD',
          day: 'MMM DD',
          week: 'MMM DD',
          month: 'MMM DD',
          quarter: 'MMM DD',
          year: 'MMM DD'
        }
      },
      ticks: {
        fixedStepSize: 2,
        maxTicksLimit: 15,
        sampleSize: 15,
        autoSkip: true
      },

      distribution: 'linear'
    },
    y: {
      afterFit: function(scaleInstance) {
        scaleInstance.width = 35; // sets the width to 100px
      },
      id: 'YVib',
      position: 'left',
      display: true,
      gridLines: {
        borderDash: [4, 4],
        borderDashOffset: 10,
        color: [
          'lightgrey',
          'lightgrey',
          'lightgrey',
          'lightgrey',
          'lightgrey',
          'lightgrey'
        ]
      },
      scaleLabel: {
        display: false,
        labelString: 'Value'
      },
      ticks: {
        maxTicksLimit: 5,
        min: 0,
        fontSize: 10,
        display: true
      },
      pointLabels: {
        display: true
      }
    }
  },
  plugins: {
    tooltips: {
      callbacks: {
        label: function(tooltipItem) {
          return '';
        }
      }
    },

    crosshair: {
      line: {
        color: '#F66', // crosshair line color
        width: 1 // crosshair line width
      },
      sync: {
        enabled: true, // enable trace line syncing with other charts
        group: 1, // chart group
        suppressTooltips: false // suppress tooltips when showing a synced tracer
      },

      callbacks: {
        beforeZoom: () =>
          function(start, end) {
            // called before zoom, return false to prevent zoom
            return true;
          },
        afterZoom: () =>
          function(start, end) {
            // called after zoom
          }
      }
    }
  }
};

const temperatureOptions = {
  showAllTooltips: true,
  showXLabels: 7,
  animation: false,
  normalized: true,
  animation: {
    duration: 0
  },
  elements: {
    line: {
      tension: 0 // disables bezier curves
    }
  },
  responsive: true,

  title: {
    display: false,
    text: 'temperature'
  },
  legend: false,

  hover: {
    mode: 'x',
    intersect: false
  },
  scales: {
    x: {
      id: 'XVib',
      beginAtZero: true,
      display: true,
      gridLines: { color: 'rgba(255,255,255,0)' },

      type: 'time',

      time: {
        unit: 'day',
        unitStepSize: 1,
        round: 'minute',

        tooltipFormat: 'MMM DD, YYYY H:mm:ss',
        displayFormats: {
          millisecond: 'MMM DD',
          second: 'MMM DD',
          minute: 'MMM DD',
          hour: 'MMM DD',
          day: 'MMM DD',
          week: 'MMM DD',
          month: 'MMM DD',
          quarter: 'MMM DD',
          year: 'MMM DD'
        }
      },
      ticks: {
        fixedStepSize: 2,
        maxTicksLimit: 15,
        sampleSize: 15,
        autoSkip: true
      },

      distribution: 'linear'
    },
    y: {
      afterFit: function(scaleInstance) {
        scaleInstance.width = 35; // sets the width to 100px
      },
      id: 'YVib',
      position: 'left',
      display: true,
      gridLines: {
        borderDash: [4, 4],
        borderDashOffset: 10,
        color: [
          'lightgrey',
          'lightgrey',
          'lightgrey',
          'lightgrey',
          'lightgrey',
          'lightgrey'
        ]
      },
      scaleLabel: {
        display: false,
        labelString: 'Value'
      },
      ticks: {
        maxTicksLimit: 5,
        min: 0,
        fontSize: 10,
        display: true
      },
      pointLabels: {
        display: true
      }
    }
  }
};

export {
  lineOptions,
  radarOptions,
  vibrationOptions,
  speedOptions,
  temperatureOptions
};
