import React from 'react';
import jsPDF from 'jspdf';
import bug from '../../assets/img/faExclamationCircle.png';
import alert from '../../assets/img/faExclamationTriangle.png';
import logo from '../../assets/img/skf-logo-blue.png';
import autoTable from 'jspdf-autotable';
import dateFormat from 'dateformat';
import pdfIcon from '../../assets/img/pdf-logo-1.png';
import styled from 'styled-components';
import { GetConfigurationPanel } from '../../services/userManagment.service';

const ComponentColumn = styled.div`
  flex: 40%;
  text-align: center;
  margin: auto;
  max-width: 400px;
  border: 1px solid #0f58d6;
  padding: 30px;
  border-radius: 25px;
  height: 180px;
  margin: 20px;
`;

const Span = styled.div`
  margin-top: 25px;
  font-size: 20px;
  color: black;
`;

class PdfFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeZone: '' // Añadimos state para el timeZone
    };
  }

  // Función para obtener el timeZone
  fetchTimeZone = async () => {
    try {
      let token = sessionStorage.getItem('idToken');
      if (!token) {
        console.error('No token found');
        return;
      }

      if (!token.startsWith('Bearer ')) {
        token = `Bearer ${token}`;
      }

      const customer = sessionStorage.getItem('client');
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/Users/GetTimeZone?company=${customer}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token
          }
        }
      );

      if (response.ok) {
        const data = await response.json();
        this.setState({ timeZone: data }); // Guardar el timeZone en el estado
      } else if (response.status === 401) {
        console.error('Unauthorized access - 401');
      } else {
        console.error(
          'Error en la respuesta del servidor:',
          response.statusText
        );
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Generar el PDF
  generatePDF = async () => {
    await this.fetchTimeZone();
    const { timeZone } = this.state;
    const allevents = this.getallEvents(this.props.dataPdf);
    const filters = {
      vehicles: this.props.hierarchyValue.toString(),
      action: this.props.actionValue.toString(),
      measurementType: this.props.measuramentValue.toString(),
      time: `From ${this.props.startDate.toLocaleDateString(
        'en-US'
      )} to ${this.props.endDate.toLocaleDateString('en-US')}`
    };

    let userName = '';
    const clients = sessionStorage.getItem('clients').split(',');
    GetConfigurationPanel(clients).then(rest => {
      userName = rest.data[0].UserName;

      var pdf = new jsPDF('p', 'pt', 'letter');
      pdf.setFontSize(14);
      pdf.addImage(logo, 'PNG', 500, 40, 50, 15);
      pdf.setFontSize(20).setFont(undefined, 'bold');
      pdf.text(50, 200, 'SKF Rail CoMo Visualization');
      pdf.text(50, 230, 'Web Event Case Report');
      pdf.setFontSize(11).setFont(undefined, 'normal');
      pdf.text(50, 260, 'timezone: ' + timeZone || 'No timeZone available');
      pdf.text(50, 290, userName);

      this.generateIndex(allevents, pdf);
      this.generateAssetHealth(pdf);
      this.generateFilterSelected(pdf, filters);
      this.generateEventsTables(pdf, allevents);
      pdf.save('demo.pdf');
    });
  };

  // Función para obtener los eventos
  getallEvents = events => {
    const allEvents = {};
    events.forEach(e => {
      if (allEvents[e[0].train_Fleet]) {
        if (allEvents[e[0].train_Fleet][e[0].train_ID]) {
          e = e.filter(e2 => e2.status !== 2 || !this.props.onlyOpen);
          if (e?.length > 0) allEvents[e[0].train_Fleet][e[0].train_ID].push(e);
        } else allEvents[e[0].train_Fleet][e[0].train_ID] = [e];
      } else {
        e = e.filter(e2 => e2.status !== 2 || !this.props.onlyOpen);
        if (e?.length > 0) {
          allEvents[e[0].train_Fleet] = {};
          allEvents[e[0].train_Fleet][e[0].train_ID] = [e];
        }
      }
    });

    return allEvents;
  };

  // Generar el índice
  generateIndex = (allEvents, pdf) => {
    let toPage = 5;
    pdf.addPage();
    pdf.addImage(logo, 'PNG', 500, 40, 50, 15);
    let top = 120;
    let right = 30;
    pdf.setFontSize(18).setFont(undefined, 'bold');
    pdf.text(30, 90, 'Table of contents');
    pdf.setFontSize(14).setFont(undefined, 'bold');
    pdf.textWithLink('1. Asset Health Status Categories', right, top, {
      pageNumber: 3,
      top: 1
    });
    top += 40;
    pdf.textWithLink('2. Filters Selected', right, top, {
      pageNumber: 3,
      top: 1
    });
    top += 15;

    Object.values(allEvents).forEach((eventsByFleet, i) => {
      top += 25;
      right = 30;
      let j = 0;
      var height = pdf.internal.pageSize.getHeight();
      pdf.setFontSize(14).setFont(undefined, 'bold');
      pdf.textWithLink(
        i + 3 + '. ' + Object.values(eventsByFleet)[0][0][0].train_Fleet,
        right,
        top,
        { pageNumber: toPage + j, top: 1 }
      );
      Object.values(eventsByFleet).forEach(eventsByTrain => {
        right = 60;
        top += 25;
        if (top > height - 200) {
          top = 120;
          pdf.addPage();
          pdf.addImage(logo, 'PNG', 500, 40, 50, 15);
          pdf.setFontSize(14).setFont(undefined, 'bold');
          pdf.textWithLink(
            i + 3 + '. ' + Object.values(eventsByFleet)[0][0][0].train_Fleet,
            right,
            top,
            { pageNumber: toPage, top: 1 }
          );
          top += 25;
        }
        pdf.setFontSize(14).setFont(undefined, 'normal');
        pdf.textWithLink(
          i + 3 + '.' + (j + 1) + '. ' + eventsByTrain[0][0].train_ID,
          right,
          top,
          { pageNumber: toPage, top: 1 }
        );
        j += 1;
        let numTrains = 0;
        let lastIteration = false;

        eventsByTrain.forEach(groupEvents => {
          groupEvents.forEach(events => {
            numTrains += 1;
            lastIteration = false;
            if (numTrains % 2 === 0 && numTrains !== 0) {
              toPage += 1;
              lastIteration = true;
            }
          });
        });
        if (!lastIteration) toPage += 1;
      });
      top += 10;
    });
  };

  // Generar la tabla de estado de salud del activo
  generateAssetHealth = pdf => {
    pdf.addPage();
    pdf.addImage(logo, 'PNG', 500, 40, 50, 15);
    pdf.setFontSize(18).setFont(undefined, 'bold');
    pdf.text(30, 90, '1 Asset Health Status Categories');
    pdf.setFontSize(14).setFont(undefined, 'normal');
    pdf.text(
      50,
      125,
      pdf.splitTextToSize(
        'All detected failures and defects are categorized as either Plan (Amber) or Act (Red).',
        500
      )
    );

    pdf.text(80, 180, '-');
    pdf.text(80, 240, '-');
    pdf.text(80, 300, '-');
    pdf.text(
      100,
      180,
      pdf.splitTextToSize(
        'Plan (Amber) indicates action is needed but not imminent failure is likely. What recommended action is explained in the Event Report.',
        500
      )
    );
    pdf.text(
      100,
      240,
      pdf.splitTextToSize(
        'Act (Red) indicates action is needed as soon as possible. What recommended action is explained in the Event Report.',
        500
      )
    );
    pdf.text(
      100,
      300,
      pdf.splitTextToSize(
        'Act Now (Red) indicates that immediate action is needed. Act Now is triggered only by temperature alarm coming from the sensor.',
        500
      )
    );

    const margin = {
      left: 75,
      right: 15,
      top: 360,
      bottom: 20
    };
    const rowsCount = 2;

    function createRows(count) {
      const rows = [
        [
          'Asset has a damage',
          `The asset monitored has a damage, as identified through data analysis described in the Event Report.
A recommendation for a customer action is provided in the report and it is expected to be carried out. No imminent asset failure is expected to occur.
Medium risk`
        ],
        [
          'Asset has a serious damage or failure',
          `The asset monitored has a serious damage or failure, as identified through data analysis described in the Event Report.
A recommendation for a customer action is provided in the report and it is expected to be carried out at the earliest possibility. An imminent asset failure could occur.
High risk`
        ]
      ];

      return rows;
    }

    autoTable(pdf, {
      head: [['Condition', 'Description']],
      body: createRows(rowsCount),
      tableWidth: 450,
      margin,
      theme: 'plain',
      rowPageBreak: 'avoid',
      columnStyles: {
        0: { cellWidth: 150, halign: 'left', valign: 'middle' },
        1: { cellWidth: 300, halign: 'left' }
      },
      alternateRowStyles: {
        fillColor: '#E0D9D7'
      }
    });
    pdf.addImage(alert, 60, 410, 15, 15);
    pdf.addImage(bug, 60, 490, 15, 15);
  };

  // Generar la sección de filtros seleccionados
  generateFilterSelected = (pdf, filters) => {
    const minHeight = 90;
    pdf.addPage();
    pdf.addImage(logo, 'PNG', 500, 40, 50, 15);
    pdf.setFontSize(18).setFont(undefined, 'bold');
    pdf.text(30, 30 + minHeight, '2 Filters Selected');
    pdf.setFontSize(14).setFont(undefined, 'bold');
    pdf.text(30, 70 + minHeight, 'Fleet, Trains and Vehicles');
    pdf.setFontSize(12).setFont(undefined, 'normal');
    pdf.text(30, 100 + minHeight, filters.vehicles);
    pdf.setFontSize(14).setFont(undefined, 'bold');
    pdf.text(30, 130 + minHeight, 'Action');
    pdf.setFontSize(12).setFont(undefined, 'normal');
    pdf.text(30, 160 + minHeight, filters.action);
    pdf.setFontSize(14).setFont(undefined, 'bold');
    pdf.text(30, 190 + minHeight, 'Measurement Type');
    pdf.setFontSize(12).setFont(undefined, 'normal');
    pdf.text(30, 220 + minHeight, filters.measurementType);
    pdf.setFontSize(14).setFont(undefined, 'bold');
    pdf.text(30, 250 + minHeight, 'Time Period');
    pdf.setFontSize(12).setFont(undefined, 'normal');
    pdf.text(30, 280 + minHeight, filters.time);
  };

  // Generar tablas de eventos
  generateEventsTables = (pdf, allEvents) => {
    const minHeight = 90;
    let height = 0;
    Object.values(allEvents).forEach(eventsByFleet => {
      height = 0;
      pdf.addPage();
      pdf.addImage(logo, 'PNG', 500, 40, 50, 15);
      pdf.setFontSize(18).setFont(undefined, 'bold');
      pdf.text(
        30,
        minHeight,
        '3 Fleet ' + Object.values(eventsByFleet)[0][0][0].train_Fleet
      );
      height = height + 30;
      pdf.setFontSize(14).setFont(undefined, 'bold');
      Object.values(eventsByFleet).forEach((eventsByTrain, i) => {
        let k = 0;
        if (height > 150) {
          height = 0;
          pdf.addPage();
        }
        pdf.setFontSize(14).setFont(undefined, 'bold');
        pdf.text(
          30,
          minHeight + height,
          '3.' + (i + 1) + ' Train ' + eventsByTrain[0][0].train_ID
        );
        eventsByTrain.forEach(groupEvents => {
          groupEvents.forEach(events => {
            if (k % 2 === 0 && k !== 0) {
              height = 0;
              pdf.addPage();
              pdf.addImage(logo, 'PNG', 500, 40, 50, 15);
              pdf.setFontSize(14).setFont(undefined, 'bold');
              pdf.text(
                30,
                minHeight + height,
                '3.' + (i + 1) + ' Train ' + eventsByTrain[0][0].train_ID
              );
            }
            let date = new Date(events.alarmTimestamp);

            date = dateFormat(date, 'dddd, mmmm d, yyyy HH:MM TT');
            let status = 'Open';
            if (events.status === 2) status = 'Close';
            height = height + 20;
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineWidth(2.0);
            pdf.line(30, minHeight + height, 498, minHeight + height);
            height = height + 20;
            pdf.setFontSize(10).setFont(undefined, 'bold');
            pdf.text(30, minHeight + height, 'Functional Location');
            pdf.text(275, minHeight + height, 'Functional Location status');
            height = height + 20;
            pdf.setFontSize(10).setFont(undefined, 'normal');
            pdf.setFillColor('#E0D9D7');
            pdf.setLineWidth(1.0);
            pdf.rect(28, minHeight + height - 13, 235, 18, 'FD');
            pdf.setFillColor('#eb202a');
            if (events.assetStatusLabel === 'Plan') pdf.setFillColor('#ffd615');
            pdf.rect(263, minHeight + height - 13, 235, 18, 'FD');
            pdf.text(
              30,
              minHeight + height,
              'Axlebox ID: ' + events.functionalLocationID ??
                'events.relatedFunctionalLocationLocationName'
            );
            pdf.text(275, minHeight + height, 'events.assetStatusLabel');
            pdf.setFontSize(10).setFont(undefined, 'bold');
            height = height + 30;
            pdf.text(
              30,
              minHeight + height,
              `Event Case  ${events.caseNoPrefix}-${events.sourceId}-${events.reportID}`
            );
            height = height + 25;
            let marginLeft = 25;
            pdf.setFontSize(10).setFont(undefined, 'normal');
            pdf.text(
              30 + marginLeft,
              minHeight + height,
              'Functional location: '
            );
            pdf.setFontSize(10).setFont(undefined, 'bold');
            pdf.text(
              120 + marginLeft,
              minHeight + height,
              'Axlebox ID: ' + events.functionalLocationID
            );
            height = height + 15;
            pdf.setFontSize(10).setFont(undefined, 'normal');
            pdf.text(30 + marginLeft, minHeight + height, 'Asset: ');
            pdf.setFontSize(10).setFont(undefined, 'bold');
            pdf.text(
              65 + marginLeft,
              minHeight + height,
              ` ${events.assetType}`
            );
            height = height + 15;
            pdf.setFontSize(10).setFont(undefined, 'normal');
            pdf.text(30 + marginLeft, minHeight + height, 'Action: ');
            pdf.setTextColor('#eb202a');
            if (events.assetStatusLabel === 'Plan') pdf.setTextColor('#ffd615');
            pdf.text(
              65 + marginLeft,
              minHeight + height,
              ` ${events.assetStatusLabel}`
            );
            pdf.setTextColor('#000');
            height = height + 15;
            pdf.setFontSize(10).setFont(undefined, 'normal');
            pdf.text(
              30 + marginLeft,
              minHeight + height,
              'Meassurement type: '
            );
            pdf.text(
              125 + marginLeft,
              minHeight + height,
              events.measurementType
            );

            height = height + 15;
            pdf.text(30 + marginLeft, minHeight + height, 'Reported on: ');
            pdf.text(95 + marginLeft, minHeight + height, `${date}`);
            height = height + 15;
            pdf.text(30 + marginLeft, minHeight + height, 'Status: ');
            pdf.text(65 + marginLeft, minHeight + height, `${status}`);
            height = height + 15;
            pdf.setFontSize(10).setFont(undefined, 'bold');
            pdf.text(30 + marginLeft, minHeight + height, 'Assessment');
            height = height + 15;
            pdf.setFontSize(10).setFont(undefined, 'normal');
            pdf.text(
              30 + marginLeft,
              minHeight + height,
              `${events.diagnosis}`
            );
            height = height + 15;
            pdf.setFontSize(10).setFont(undefined, 'bold');
            pdf.text(30 + marginLeft, minHeight + height, 'Recommendation');
            height = height + 15;
            pdf.setFontSize(10).setFont(undefined, 'normal');
            pdf.text(
              30 + marginLeft,
              minHeight + height,
              pdf.splitTextToSize(events.recommendation, 400)
            );
            height = height + 15;
            k = k + 1;
          });
        });
      });
    });
  };

  render() {
    return (
      <ComponentColumn style={{ cursor: 'pointer' }} onClick={this.generatePDF}>
        <input type="image" style={{ width: 60 }} src={pdfIcon} alt="" />
        <Span>Export a PDF file</Span>
      </ComponentColumn>
    );
  }
}

export default PdfFile;
