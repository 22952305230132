import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (
          sessionStorage.getItem('loggedIn') === 'false' ||
          Date.now() >
            new Date(sessionStorage.getItem('expiresIn')).getTime() ||
          !sessionStorage.getItem('idToken')
        ) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }
        return <Component {...props} {...rest} />;
      }}
    />
  );
};
