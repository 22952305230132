import axios from 'axios';
const sendContactForm = async _ref => {
  let {
    auth,
    helpType,
    comment,
    sendCopy,
    dateTime
  } = _ref;
  const url = `${process.env.REACT_APP_API_URL}/api/ZeEv/sendcomments`;
  return axios({
    method: 'post',
    url,
    data: {
      auth,
      user: auth.alias,
      helpType,
      comment,
      sendCopy,
      dateTime
    },
    headers: {
      'Content-Type': 'application/json'
    }
  });
};
export { sendContactForm };