import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
const ComponentContainer = styled.div`
  .react-datepicker__year-read-view--down-arrow {
    margin-left: 25px;
    transform: translateY(-5px);
  }

  .react-datepicker-wrapper:first-child button {
    margin-left: 0;
  }

  .react-datepicker-popper {
    @media (max-width: 1194px) {
      top: -65px !important;
      left: -680px !important;
    }
  }

  .react-datepicker__input-container > button {
    -webkit-appearance: none;
    border: 0px;
    margin-left: 15px;
    background-color: #f6f6f6;
    padding-bottom: 4.7px;
    @media (min-width: 768px) {
      max-width: 126px !important;
    }
    @media (max-width: 1194px) {
      margin-left: -3px;
      max-width: 108px !important;
    }
  }
`;

const DatePickersContainer = styled.div`
  border-bottom: 1px solid #343a40;
  display: inline-flex;
  transform: translateY(7px);
  padding-top: 6px;
`;

const ArrowContainer = styled(FontAwesomeIcon)`
  @media (min-width: 1194px) {
    transform: translate(7px, 1px);
  }
`;

class DatePickerRange extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
      <button onClick={onClick}>{value}</button>
    ));

    const {
      startDate,
      endDate,
      handleStartDateChange,
      handleEndDateChange,
      minDate,
      maxDate,
      selectedDate
    } = this.props;

    return (
      <ComponentContainer>
        <DatePickersContainer>
          <DatePicker
            selected={startDate}
            selectsStart
            onChange={handleStartDateChange}
            startDate={startDate}
            endDate={selectedDate}
            showYearDropdown
            dateFormatCalendar="MMMM"
            scrollableYearDropdown
            yearDropdownItemNumber={15}
            dateFormat="dd/MMM/yyyy"
            minDate={minDate}
            maxDate={maxDate}
            customInput={<CustomInput />}
          />
          <ArrowContainer icon={faChevronRight} />
          <DatePicker
            selected={selectedDate}
            selectsEnd
            startDate={startDate}
            endDate={selectedDate}
            onChangeRaw={false}
            onChange={handleEndDateChange}
            showYearDropdown
            dateFormatCalendar="MMMM"
            scrollableYearDropdown
            yearDropdownItemNumber={15}
            dateFormat="dd/MMM/yyyy"
            minDate={startDate}
            maxDate={maxDate}
            popperPlacement="left-start"
            customInput={<CustomInput />}
          />
        </DatePickersContainer>
      </ComponentContainer>
    );
  }
}

DatePickerRange.defaultProps = {
  handleStartDateChange: () => null,
  handleEndDateChange: () => null,
  startDate: Date.now(),
  endDate: Date.now(),
  minDate: Date.now(),
  maxDate: Date.now(),
  selectedDate: Date.now()
};

DatePickerRange.propTypes = {
  handleStartDateChange: PropTypes.func,
  handleEndDateChange: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
};

export default DatePickerRange;
