import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 0px 20px;
  padding: 60px 0;
  font-size: 32px;
  text-align: center;
  transform: translateY(18px);
`;
