import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import MainFooter from '../footer/mainFooter';
import aboutSKFLogo from '../../assets/img/generalSvg/aboutSKFLogo';
// import { ErrorBoundary, RecordPageView } from '../../App/AwsRum';

const ComponentContainer = styled.div`
  background-color: #fefefe;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
  padding: 30px;
  margin: 20px;
  min-height: calc(100vh - 135px);
`;

const TitleContainer = styled.div`
  width: 100%;
  font-size: 32px;
  border-bottom: 1px solid grey;
`;

const AboutInfoContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

const AboutInfo = styled.div`
  display: flex;
  flex-flow: column;
  align-content: center;
  align-items: center;
`;

class AboutUsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.changeMenu('');
  }

  render() {
    return (
      <Fragment>
     
        <ComponentContainer>
          <TitleContainer>About</TitleContainer>
          <AboutInfoContainer>
            <AboutInfo>
              {aboutSKFLogo}
              <span>SKF Rail Condition Monitoring Visualization</span>
              <span>Version 2.5.0</span>
              <span>© 2019 SKF AB. All rights reserved</span>
            </AboutInfo>
  
          </AboutInfoContainer>
        </ComponentContainer>
        <MainFooter />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeMenu: menu => dispatch({ type: 'CHANGE_MENU', menu })
  };
};

AboutUsMain.propTypes = {
  changeMenu: PropTypes.func
};

AboutUsMain.defaultProps = {
  changeMenu: () => null
};

const connectedAboutUsMain = connect(null, mapDispatchToProps)(AboutUsMain);

export { connectedAboutUsMain as AboutUsMain };
