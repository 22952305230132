import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  TimeScale,
  Tooltip,
  Legend,
  Interaction
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import expandir from '../../../assets/img/expandir.png';
import 'chartjs-adapter-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { speedOptions } from './options';
Chart.register(
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  CrosshairPlugin
);
Interaction.modes.interpolate = Interpolate;

const ComponentContainer = styled.div`
  background-color: #fefefe;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
`;

const IconContainer = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const TitleContainer = styled.div`
  padding-bottom: 15px;
`;

class SpeedChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { iszoom: false };
    this.myRef2 = React.createRef();
  }
  getChart = () => {
    const data = this.createData();
    this.updateData(this.chart2, data.labels, data.datasets);
    this.setState({ setColors: false });
  };
  setZoom = (min, max) => {
    this.props.setZoomIn(min, max);
  };

  componentDidMount() {
    this.ctx2 = this.myRef2.current.getContext('2d');
    let getchart = this.getChart;
    this.chart2 = new Chart(this.ctx2, {
      type: 'line',
      data: this.createData(),
      options: {
        ...speedOptions,

        scales: {
          ...speedOptions.scales,
          x: {
            ...speedOptions.scales.x,
            min: this.props.startDate,
            max: this.props.endDate,
            type: 'time',

            time: {
              ...speedOptions.scales.x.time,
              min: this.props.startDate,
              max: this.props.endDate
            },
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              borderDash: [4, 4]
            },
            ticks: {
              // maxTicksLimit: 10,
              //upperThreshold + stepSize,

              fontSize: 10,
              display: true,
              callback: function(value) {
                if (Math.floor(value) === value) {
                  return value;
                } else return Math.floor(value);
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            mode: 'interpolate',
            intersect: false
          },
          zoom: {
            zoom: {
              drag: {
                enabled: true
              },
              onZoomComplete: ({ chart }) => {
                this.setState({ iszoom: true });
                chart.scales.y.start = Math.trunc(chart.scales.y.start);
                chart.scales.y.min = Math.trunc(chart.scales.y.min);
                chart.scales.y.ticks.forEach((e, i) => {
                  chart.scales.y.ticks[i].value = Math.trunc(
                    chart.scales.y.ticks[i].value
                  );
                  chart.scales.y.ticks[i].label = Math.trunc(
                    chart.scales.y.ticks[i].value
                  );
                });
                this.setZoom(chart.scales.x.min, chart.scales.x.max);
              },
              mode: 'xy'
            }
          },
          crosshair: {
            line: {
              color: '#F66', // crosshair line color
              width: 1 // crosshair line width
            },
            sync: {
              enabled: true, // enable trace line syncing with other charts
              group: 2, // chart group
              suppressTooltips: false // suppress tooltips when showing a synced tracer
            },
            zoom: {
              enabled: true, // enable zooming
              zoomboxBackgroundColor: 'rgba(66,133,244,0.2)', // background color of zoom box
              zoomboxBorderColor: '#48F', // border color of zoom box
              zoomButtonText: 'Reset Zoom', // reset zoom button text
              zoomButtonClass: 'reset-zoom' // reset zoom button class
            },
            callbacks: {
              beforeZoom: () =>
                function(start, end) {
                  // getchart(); called before zoom, return false to prevent zoom
                  return true;
                },
              afterZoom: () =>
                function(start, end) {
                  getchart();
                }
            }
          }
        },
        onClick: (e, items) => {
          if (items.length == 0) return;
          items.map(point => {
            const label =
              this.chart2.data.datasets[point.datasetIndex].label || '';
            const timeStamp = this.chart2.data.datasets[point.datasetIndex]
              .data[point.index]['x'];
            const value =
              Math.round(
                this.chart2.data.datasets[point.datasetIndex].data[point.index][
                  'y'
                ] * 100
              ) / 100;
            this.callbackHover({ label, timeStamp, value });
          });
        }
      }
    });
    this.chart2.options.scales.y.grid.borderDash = [4, 4];
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.axleboxData !== this.props.axleboxData ||
      prevProps.sideBarSelected !== this.props.sideBarSelected
    ) {
      const data = this.createData();
      this.addData(this.chart2, data.labels, data.datasets);
    }

    if (
      prevProps.endDate !== this.props.endDate ||
      prevProps.startDate !== this.props.startDate
    ) {
      const data = this.createData();
      this.addData(this.chart2, data.labels, data.datasets);
    }
  }

  componentWillUnmount() {
    if (this.chart2) this.chart2.destroy();
  }

  createData = () => {
    const { axleboxData, sideBarSelected } = this.props;
    const result = { labels: [], datasets: [] };
    if (!axleboxData.length) {
      return result;
    }

    axleboxData.forEach(data => {
      if (data.speed) {
        result.labels = Object.keys(data.speed);
        let dataXY = [];
        Object.keys(data.speed).map(key => {
          dataXY.push({ x: key, y: data.speed[key] });
        });
        result.datasets.push({
          label: data.functionaLocation.locationName,
          fill: false,
          lineTension: 0,
          borderWidth: 0.7,
          pointRadius: 2,
          pointHoverRadius: 5,
          backgroundColor: `${
            sideBarSelected[data.functionaLocation.reference]
              ? 'rgba(15, 88, 214,1)'
              : 'grey'
          } `,
          borderColor: `${
            sideBarSelected[data.functionaLocation.reference]
              ? 'rgba(15, 88, 214,1)'
              : 'grey'
          } `,
          pointHoverBackgroundColor: 'rgba(15, 88, 214,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          data: dataXY
        });
      }
    });

    return result;
  };

  updateData = (chart, label, datasets) => {
    chart.data.labels = label;
    chart.data.datasets = datasets;
    chart.update();
  };

  addData = (chart, label, datasets) => {
    chart.data.labels = label;
    chart.data.datasets = datasets;

    chart.options.scales.x.min = this.props.startDate;
    chart.options.scales.x.max = this.props.endDate;
    chart.update();
  };

  callbackHover({ label, timeStamp, value }) {
    this.props.dataHandler({ label, timeStamp, value });
  }

  fullSize = () => {
    this.props.setFullSize('speed');
  };

  render() {
    return (
      <ComponentContainer>
        <TitleContainer>
          <div>
            <IconContainer icon={faTachometerAlt} />
            <span>Speed (km/h)</span>
            <img
              src={expandir}
              onClick={() => this.fullSize()}
              alt=""
              style={{ float: 'right', height: 20, cursor: 'pointer' }}
            />
          </div>
        </TitleContainer>
        <canvas
          ref={this.myRef2}
          id="chart2"
          width="800px"
          height="140px"
          className="canvasForCharts"
        />
        {this.state.iszoom || this.props.zoom ? (
          <button
            onClick={() => {
              this.chart2.resetZoom();
              this.setState({ iszoom: false });
              this.props.setZoomOut();
            }}
          >
            Reset zoom
          </button>
        ) : (
          <div></div>
        )}
      </ComponentContainer>
    );
  }
}

export default SpeedChart;
