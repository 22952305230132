import { ApplicationState } from '../../reducers/createRootReducer';
import { RootAction } from 'typesafe-actions';
import { createEpicMiddleware } from 'redux-observable';
import { createServices } from '../../services';

export function getEpicMiddleware() {
  const epicMiddleware = createEpicMiddleware<
    RootAction,
    RootAction,
    ApplicationState
  >({
   
    dependencies: createServices()
  });
 
  return epicMiddleware;
}