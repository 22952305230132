import { createAction, ActionType } from 'typesafe-actions';

export interface BasicLocation {
  lat: number;
  lng: number;
  date: string;
}

export interface VehicleLocation {
  heatMapPoints: BasicLocation[];
  marker: BasicLocation;
  name: string;
  reference: string;
  severity: string;
}

export const fetchLocationDashboard = createAction(
  'TRAIN_DASHBOARD/FETCH_LOCATIONS'
)();

export const fetchLocationDashboardFulfilled = createAction(
  'ASYNC_DASHBOARD/FETCH_LOCATIONS_FULFILLED',
  (status: string, locations: VehicleLocation[]) => {
    return { status, locations };
  } // payload creator
)();

export const fetchLocationDashboardFailed = createAction(
  'ASYNC_DASHBOARD/FETCH_LOCATIONS_FAILED',
  (status: string) => {
    return { status };
  } // payload creator
)();

export const fetchLocationDashboardTimeout = createAction(
  'ASYNC_DASHBOARD/FETCH_LOCATIONS_TIMEOUT',
  (status: string) => {
    return { status };
  } // payload creator
)();

const ActionCreators = {
  fetchLocationDashboard,
  fetchLocationDashboardFulfilled,
  fetchLocationDashboardFailed,
  fetchLocationDashboardTimeout
};

export type TrainsDashboardMapAsyncAction = ActionType<typeof ActionCreators>;
