import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { Function } from '@babel/types';
import { toast } from 'react-toastify';

interface IButtonAtachFile {
  updatedFile: Function;
  title: string;
  className: string;
  cKey?: number;
}

interface FileState {
  filePreview: File;
  fileName: string;
}

class ButtonAtachFile extends Component<IButtonAtachFile, FileState> {
  constructor(props: any) {
    super(props);
    this.updateFile = this.updateFile.bind(this);
  }

  render() {
    return (
      <div className={this.props.className}>
        <input
          accept="image/*"
          id={'upload-photo-' + this.props.cKey}
          type="file"
          name="photo"
          onChange={this.updateFile}
          className="uploadPhotoButton"
        />
        <label
          className="labelInputModal"
          htmlFor={'upload-photo-' + this.props.cKey}
        >
          <FontAwesomeIcon color="#0f58d6" icon={faPaperclip} />{' '}
          {this.props.title}
        </label>
      </div>
    );
  }

  // convert(bytes: any, callback: Function) {

  //       var binary = "", blockSize = 2 * 1024 * 1024, // 2 mb block
  //           block = blockSize,                    // block segment
  //           xx = 0, len = bytes.byteLength;

  //       (function _loop() {
  //           while (xx < len && --block > 0) {        // copy until block segment = 0
  //               binary += String.fromCharCode(bytes[xx++]);
  //           }

  //           if (xx < len) {                         // more data to copy?
  //               block = blockSize;                    // reinit new block segment
  //               binary = "";                          // for demo to avoid out-of-memory
  //               setTimeout(_loop, 10);                // KEY: async wait

  //               // update a progress bar so we can see something going on:
  //               //document.querySelector("div").style.width = (xx / len) * 100 + "%";
  //           }
  //           else callback(binary);                  // if done, invoke callback
  //       })();                                     // selv-invoke loop
  //   }

  updateFile(evt: any) {
    var _this = this;
    var reader = new FileReader();
    var fileByteArray = new Uint8Array();
    //console.debug("size", evt.target.files[0].size);
    //console.debug("size_if", (evt.target.files[0].size / 1024 / 1024).toFixed(4));
    if (
      evt.target.files &&
      evt.target.files.length > 0 &&
      evt.target.files[0].size < 4100000
    ) {
      if (evt.target.files && evt.target.files.length > 0) {
        this.setState({
          filePreview: evt.target.files[0],
          fileName: evt.target.files[0].name
        });
        reader.onloadend = function(evt: any) {
          var fileByteArray = new Uint8Array();
          if (evt.target.readyState == FileReader.DONE) {
            var arrayBuffer = evt.target.result;
            //this.convert(arrayBuffer, function (str) {              // invoke the process with a callback defined
            fileByteArray = new Uint8Array(arrayBuffer);
            //});
          }

          //console.debug(this.state);
          _this.props.updatedFile(
            fileByteArray,
            _this.props.cKey,
            _this.state.fileName,
            _this.state.filePreview
          );
        };

        reader.readAsArrayBuffer(evt.target.files[0]);
      } else {
        _this.props.updatedFile(fileByteArray, _this.props.cKey, '');
      }
    } else {
      evt.target.value = '';
      toast.error('File size must be smaller than 4 MB', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 6000
      });
    }
  }
}
export default ButtonAtachFile;
