import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import isEqual from 'lodash/isEqual';
import VehicleEventsIcons from './VehicleEventsIcons';

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 0px 20px;
  padding: 60px 0;
  font-size: 32px;
  text-align: center;
  box-shadow: 0 0.0625rem 0.375rem rgba(0, 0, 0, 0.12),
    0 0.0625rem 0.25rem rgba(0, 0, 0, 0.12);
`;
const Legend = styled.div`
  margin-right: 5px;
`;

const BtnPaginator = styled.div`
  background: lightgray;
  border-radius: 35px;
  width: 20px;
  text-align: center;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

class TrainsTablev02 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allVehicles: [],
      pagedVehicles: [],
      numPerPage: 10,
      pages: null,
      page: 0
    };
  }

  componentDidMount() {
    const { pagedVehicles, pages } = this.paginator(this.props.vehicles);

    this.setState({
      allVehicles: this.props.vehicles,
      pagedVehicles,
      pages,
      page: 0
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { pagedVehicles, pages } = this.paginator(this.state.allVehicles);
    if (!isEqual(pagedVehicles, this.state.pagedVehicles)) {
      this.setState({
        pagedVehicles,
        pages
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEqual(props.vehicles, state.allVehicles)) {
      return { allVehicles: props.vehicles };
    }
    return state;
  }

  paginator = trains => {
    const numPerPage = this.state.numPerPage;

    let pagedVehicles = [];
    let pages = 0;
    let start = 0;
    let end = numPerPage;

    while (end < trains.length) {
      pagedVehicles.push(trains.slice(start, end));
      start = end;
      end = end + numPerPage;
    }

    if (start > trains.length) {
      start = trains.length;
    }

    if (end > trains.length) {
      end = trains.length;
    }
    pagedVehicles.push(trains.slice(start, end));

    pages = pagedVehicles.length;
    return { pagedVehicles, pages };
  };

  beforePage = () => {
    let page = this.state.page;
    page = page - 1;
    if (page < 0) {
      page = 0;
    }
    this.setState({
      page
    });
  };
  nextPage = () => {
    let lastPage = this.state.pages - 1;
    let page = this.state.page;
    page = page + 1;
    if (page > lastPage) {
      page = lastPage + 1;
    }
    this.setState({
      page
    });
  };

  render() {
    if (this.props.vehicles.length === 0 || !this.state.pagedVehicles[0]) {
      return (
        <LoadingContainer>
          <div className="spinner-border sfk_spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </LoadingContainer>
      );
    }
    let level = sessionStorage.getItem('typeVehicle');
    return (
      <Fragment>
        <Table className="border font-weight-bold" size="sm">
          <thead>
            <tr className="text-center">
              <th>{this.props.typeVehicle}</th>
              <th>Sensors</th>
              <th>Action</th>
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <tbody className="battleship">
            {this.state.pagedVehicles[this.state.page].map((vehicle, index) => {
              let severityColor = '';
              let classAlarmColor = 'None';
              if (
                vehicle.severity === 'ACT' ||
                vehicle.severity === 'ACT NOW'
              ) {
                severityColor = 'tableRowRed';
                classAlarmColor = 'red';
              } else if (vehicle.severity === 'PLAN') {
                severityColor = 'tableRowAmbar';
                classAlarmColor = 'yellow';
              } else {
                severityColor = 'tableRowGray';
                classAlarmColor = 'noEvents';
              }

              let isSelected = 'notSelected';
              const foundVehicle = this.props.selectedVehicles.find(
                selectedVehicle => {
                  return selectedVehicle === vehicle.reference;
                }
              );

              if (foundVehicle) {
                isSelected = 'selected';
              }

              return (
                <tr
                  className={`text-center ${severityColor} ${isSelected}`}
                  key={index}
                  onClick={() => {
                    this.props.clickRow({ vehicleName: vehicle.reference });
                  }}
                >
                  <td>{vehicle.vehicleName}</td>
                  <td>{vehicle.totalCommissionedSensors}</td>
                  <td className={classAlarmColor}>
                    <span
                      className={
                        vehicle.severity === 'ACT NOW'
                          ? 'eventDesc m-auto act-now-alarm act-now-text'
                          : vehicle.severity === 'PLAN'
                          ? 'amber-color'
                          : vehicle.severity === 'ACT'
                          ? 'red-color'
                          : ''
                      }
                    >
                      {vehicle.severity.charAt(0) +
                        vehicle.severity.slice(1).toLowerCase()}
                    </span>
                  </td>
                  <td>
                    {vehicle.totalTemperatureEvents > 0
                      ? `${vehicle.maxTemperatureValue} ºC`
                      : null}
                  </td>
                  <td>
                    <VehicleEventsIcons
                      vibrationEvens={vehicle.totalVibrationEvents}
                      sensorEvents={vehicle.totalSensorEvents}
                      temperatureEvents={vehicle.totalTemperatureEvents}
                    />
                  </td>
                  <td>
                    <Link
                      to={'/vehicle-detail'}
                      className="highlighted font-weight-bold"
                      onClick={() => {
                        sessionStorage.setItem(
                          'filterVehicles',
                          vehicle.reference
                        );
                        this.props.handleViewDetailsClick(vehicle.reference);
                      }}
                    >
                      View Detail &gt;
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <BtnPaginator onClick={() => this.beforePage()}>
          <FontAwesomeIcon icon={faCaretLeft} />
        </BtnPaginator>
        <Legend>
          &nbsp;Page {this.state.page + 1} / {this.state.pages || 1} of{' '}
          {this.state.allVehicles.length} {this.props.g2boostertypeVehicle}{' '}
          {' ' + level}
          &nbsp;
        </Legend>
        <BtnPaginator onClick={() => this.nextPage()}>
          <FontAwesomeIcon icon={faCaretRight} />
        </BtnPaginator>
      </Fragment>
    );
  }
}

export default TrainsTablev02;

TrainsTablev02.propTypes = {
  clickRow: PropTypes.func,
  selectedVehicles: PropTypes.array,
  vehicles: PropTypes.array,
  handleViewDetailsClick: PropTypes.func
};

TrainsTablev02.defaultProps = {
  clickRow: () => null,
  handleViewDetailsClick: () => null,
  selectedVehicles: [],
  vehicles: []
};
