import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import uuidv1 from 'uuid/v1';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface DashboardFilterPropsValues {
  filteredTrains: string[];
}

export interface DashboardFilterPropsActions {
  removeFilterTrain: (vehicle: string) => void;
  clearAllFilters: () => void;
  treeData: any;
}

export type DashboardFilterProps = DashboardFilterPropsValues &
  DashboardFilterPropsActions;

const DashboardFilter = ({
  filteredTrains,
  removeFilterTrain,
  clearAllFilters,
  treeData
}: DashboardFilterProps) => {
  if (!filteredTrains || !filteredTrains.length) {
    return <div></div>;
  }

  return (
    <Col>
      {filteredTrains.map(vehicle => {
        return (
          <button
            onClick={e => removeFilterTrain(vehicle)}
            key={uuidv1()}
            className="btn btn-filters"
          >
            {treeData.find((node: any) => node.key === vehicle)?.title}
            <FontAwesomeIcon className="bubbleDashboard" icon={faTimesCircle} />
          </button>
        );
      })}
      <span onClick={() => clearAllFilters()} className="clearFilters">
        Clear all filters
      </span>
    </Col>
  );
};

DashboardFilter.defaultProps = {
  filteredTrains: [],
  removeFilterTrain: () => {
    return;
  },
  clearAllFilters: () => {
    return;
  }
};

export { DashboardFilter };
