import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
  faExclamationTriangle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { oldGetByReferenceOrDesignation } from '../../utils/state/mstp_Vehicles'; //clean
import './sideHierarchy.css';
import { AssetStatusEnum } from '../../../constants/assetStatusEnum';

const ArrowContainer = styled(FontAwesomeIcon)`
  margin-left: 10px;
  transition-duration: 0.3s;
  transition-property: transform;
  font-size: 12px;
  color: blue;
`;

const AssetIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  color: ${props => (props.color ? props.color : 'grey')};
`;

const ComponentContainer = styled.div``;
const TitleContainer = styled.span`
  font-weight: 700;
  flex: 1;
  padding-left: 5px;
  word-break: break-word;
`;
const VehicleName = styled.div`
  font-size: 11px;
`;

class SideHierarchy extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      openedListGroup: []
    };
  }

  handleSidebarCarriageClick = index => {
    let newOpenedListGroup = this.state.openedListGroup;

    if (newOpenedListGroup.includes(index)) {
      newOpenedListGroup = newOpenedListGroup.filter(value => value !== index);
    } else {
      newOpenedListGroup = [...this.state.openedListGroup, index];
    }
    this.setState({
      openedListGroup: newOpenedListGroup
    });
  };

  render() {
    const {
      handleSidebarClick,
      sideBarSelected,
      hierarchyAxleData
    } = this.props;

    const carriageSelected = _.groupBy(
      hierarchyAxleData,
      'carriageDesignation'
    );

    return (
      <ComponentContainer className="just-padding">
        {Object.entries(carriageSelected).length !== 0 &&
          Object.values(carriageSelected).map((carriage, index) => {
            const carriageArrEvents = [];
            carriage.forEach(z => {
              z.event.forEach(j => {
                carriageArrEvents.push(j);
              });
            });
            /** Get events here */
            const maxAssetStatusCarriage = _.chain(carriageArrEvents)
              .flatten()
              .minBy('assetStatus')
              .value();
            let assetStatusAlarmCarriage = (
              <AssetIcon color="transparent" icon={faExclamationCircle} />
            );
            if (maxAssetStatusCarriage && maxAssetStatusCarriage.assetStatus) {
              switch (maxAssetStatusCarriage.assetStatus) {
                case AssetStatusEnum.Plan:
                  assetStatusAlarmCarriage = (
                    <AssetIcon color="#ffca10" icon={faExclamationTriangle} />
                  );
                  break;
                case AssetStatusEnum.Act:
                case AssetStatusEnum.ActNow:
                  assetStatusAlarmCarriage = (
                    <AssetIcon color="#cc0000" icon={faExclamationCircle} />
                  );
                  break;
                default:
                  break;
              }
            }
            return (
              <div
                className="list-group list-group-root well custom-list-group-root"
                key={carriage[0].carriageDesignation}
              >
                <span
                  href={`#item-${index}`}
                  className="list-group-item custom-item-Header"
                  data-toggle="collapse"
                  onClick={() => {
                    this.handleSidebarCarriageClick(index);
                  }}
                >
                  {assetStatusAlarmCarriage}
                  <TitleContainer>
                    {carriage[0].carriageDesignation}
                    <VehicleName>{carriage[0].vehicleDesignation}</VehicleName>
                  </TitleContainer>
                  <ArrowContainer
                    icon={
                      this.state.openedListGroup.includes(index)
                        ? faChevronDown
                        : faChevronRight
                    }
                  />
                </span>
                <div className="list-group collapse" id={`item-${index}`}>
                  {carriage.map(axlebox => {
                    const maxAssetStatusAxlebox =
                      axlebox.event && _.minBy(axlebox.event, 'assetStatus');
                    let assetStatusAlarmAxlebox = (
                      <AssetIcon
                        color="transparent"
                        icon={faExclamationCircle}
                      />
                    );
                    if (
                      maxAssetStatusAxlebox &&
                      maxAssetStatusAxlebox.assetStatus
                    ) {
                      switch (maxAssetStatusAxlebox.assetStatus) {
                        case AssetStatusEnum.Plan:
                          assetStatusAlarmAxlebox = (
                            <AssetIcon
                              color="#ffca10"
                              icon={faExclamationTriangle}
                            />
                          );
                          break;
                        case AssetStatusEnum.Act:
                        case AssetStatusEnum.ActNow:
                          assetStatusAlarmAxlebox = (
                            <AssetIcon
                              color="#cc0000"
                              icon={faExclamationCircle}
                            />
                          );
                          break;
                        default:
                          break;
                      }
                    }
                    return (
                      <span
                        className={`list-group-item custom-list-group-item ${
                          sideBarSelected[axlebox.referenceKey] ? 'active' : ''
                        }`}
                        key={axlebox.reference}
                        onClick={() =>
                          handleSidebarClick({
                            reference: axlebox.referenceKey
                          })
                        }
                      >
                        {assetStatusAlarmAxlebox}
                        {axlebox.designation}
                      </span>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </ComponentContainer>
    );
  }
}

SideHierarchy.defaultProps = {
  handleSidebarClick: () => null,
  hierarchyAxleData: null,
  sideBarSelected: {},
  skf_events: [],
  skf_vehicles: []
};

SideHierarchy.propTypes = {
  handleSidebarClick: PropTypes.func,
  hierarchyAxleData: PropTypes.array,
  sideBarSelected: PropTypes.object,
  skf_events: PropTypes.array,
  skf_vehicles: PropTypes.array
};

export default SideHierarchy;
