import { Row, Col } from 'react-bootstrap';
import React from 'react';
import { LoadingWrapper } from '../loadingWrapper';
import FleetMapWrapperv02 from '../../Home/Fleet/Components/FleetMapWrapper/FleetMapWrapperv02';
import { VehicleLocation } from '../../actions/trainDashboardMapAsyncAction';

export interface DashboardFleetHistoryPropsValues {
  isLoading: boolean;
  filteredLocations: VehicleLocation[];
  currentLatLng: { lat: number; lng: number };
}

export interface DashboardFleetHistoryPropsActions {
  handleClickOnMarker: (clickedTrain: string) => void;
}

export type DashboardFleetHistoryProps = DashboardFleetHistoryPropsValues &
  DashboardFleetHistoryPropsActions;

const getMarkerHandler = (
  handleClickOnMarker: (clickedTrain: string) => void
) => {
  return (event: any, train: string) => handleClickOnMarker(train);
};

const DashboardFleetHistory = ({
  isLoading,
  filteredLocations,
  handleClickOnMarker,
  currentLatLng
}: DashboardFleetHistoryProps) => {
  return (
    <Row className="flex1">
      <Col className="page-box-tab dFlex">
        <Row className="map-title-container">
          <Col>
            <p className="page-box-map-main-title"> Fleet History Map</p>
          </Col>
          <Col>
            <p className="lastDays">Last 7 days</p>
          </Col>
        </Row>
        {/*************** Google Map *******************/}
        <Row className="flex1">
          {isLoading ? (
            <LoadingWrapper>
              <div className="spinner-border sfk_spinner" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </LoadingWrapper>
          ) : (
            <FleetMapWrapperv02
              className="h-100"
              clickMarker={getMarkerHandler(handleClickOnMarker)}
              scopedPoints={filteredLocations}
              currentLocation={currentLatLng}
            />
          )}
          {/*************** Density of Taken Measurements *******************/}
        </Row>
        <Row className="marginInfoFleet">
          <Col xs={5}>
            <span className="fleetInfoMapTitle">
              Density of Taken Measurements:
            </span>
          </Col>
          <Col xs={2} />
          <Col xs={5}>
            <Row className="gradient-container">
              <Col xs={12}>
                <div className="gradient" />
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={6} className="gradient-low-label">
                    <p> Low </p>
                  </Col>
                  <Col xs={6} className="gradient-high-label">
                    <p> High </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row />
      </Col>
    </Row>
  );
};

DashboardFleetHistory.defaultProps = {
  isLoading: false,
  currentLatLng: {
    lat: 0,
    lng: 0
  },
  filteredLocations: [],
  handleClickOnMarker: () => {
    return;
  }
};

export { DashboardFleetHistory };
