import { authHeader } from '../helpers';

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export const apiService = async (
  path: string,
  method: string = 'GET',
  bodyObject: any = ''
): Promise<any> => {
  let headers = authHeader();
  var header = new Headers();
  header.append('Content-Type', 'application/json');
  headers.Authorization &&
    header.append('Authorization', headers.Authorization);
  headers.UserName && header.append('UserName', headers.UserName);
  var url = process.env.REACT_APP_API_URL + path;
  //var url = 'http://localhost:60117/' + path;

  switch (method) {
    case HTTPMethods.GET:
      return fetch(url, {
        headers: header,
        method: method
      });
    case HTTPMethods.POST:
      return fetch(url, {
        headers: header,
        method: method,
        body: JSON.stringify(bodyObject)
      });
    case HTTPMethods.PUT:
      return fetch(url, {
        headers: header,
        method: method,
        body: JSON.stringify(bodyObject)
      });
    case HTTPMethods.DELETE:
      return fetch(url, {
        headers: header,
        method: method,
        body: JSON.stringify(bodyObject)
      });
    default:
      return new Promise((resolve, reject) => reject);
  }
};

export const apiServiceImg = async (
  path: string,
  method: string = 'POST',
  bodyObject: any = ''
): Promise<any> => {
  let headers = authHeader();
  var header = new Headers();

  headers.Authorization &&
    header.append('Authorization', headers.Authorization);
  headers.UserName && header.append('UserName', headers.UserName);

  var url = (window as any).ENV.apiUrlDev + path;


  switch (method) {

    case HTTPMethods.POST:
      return fetch(url, {
        headers: header,
        method: method,
        body: bodyObject
      });

    default:
      return new Promise((resolve, reject) => reject);
  }
};
