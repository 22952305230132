import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { AssetStatusEnum } from '../constants/assetStatusEnum';
import {
  faExclamationTriangle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export const getAssetIcon = (status: number, size: SizeProp): JSX.Element => {
  //console.log(status);
  switch (status) {
    case AssetStatusEnum.Plan:
      //console.log('plan');
      return (
        <span className="trianglebullet">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            color="#ffd615"
            style={{ background: 'white' }}
            size={size}
          />
        </span>
      );
    default:
      //console.log('act');
      return (
        <span className="bullet">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            color="#eb202a"
            style={{ background: 'white' }}
            size={size}
          />
        </span>
      );
  }
};
