import React, { Component, Fragment } from 'react';
import SkfModal from '../reports/SkfModal';
import { Form, Col, Row } from 'react-bootstrap';

export interface IEventLogProps {
  vehicles: any[];
  isOpen: boolean;
  closeModal: any;
  setFilter: any;
}

export interface StateProps {}

class ModalPreFilters extends Component<IEventLogProps, StateProps> {
  public readonly state: Readonly<StateProps> = {};

  setFilter = (e: any) => {
    this.props.setFilter(e);
  };

  render() {
    return (
      <div>
        <SkfModal
          size="small"
          isOpen={this.props.isOpen}
          handleClose={this.props.closeModal}
        >
          <SkfModal.Header handleClose={this.props.closeModal}>
            Please choose one of the previous filters or close this window to
            select a different one
          </SkfModal.Header>
          <SkfModal.Content>
            <Row>
              <Col sm="10" md="10" lg="10">
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextPassword"
                >
                  <Col>
                    {this.props.vehicles.length > 3 ? (
                      <Fragment>
                        <Form.Label column sm="2">
                          Vehicles
                        </Form.Label>
                        <Form.Control
                          onChange={e => this.setFilter(e.target.value)}
                          as="select"
                          name="selectedToBucket"
                        >
                          <option value={''}>{''}</option>
                          {this.props.vehicles &&
                            this.props.vehicles.map((e: any) => (
                              <option value={e.value}>{e.title}</option>
                            ))}
                        </Form.Control>
                      </Fragment>
                    ) : (
                      this.props.vehicles &&
                      this.props.vehicles.map((e: any) => (
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formPlaintextPassword"
                        >
                          <Col>
                            <Form.Label>{e.title}</Form.Label>
                          </Col>
                          <Col sm={2}>
                            <Form.Control
                              size="sm"
                              type="checkbox"
                              onChange={event => this.setFilter(e.value)}
                            />
                          </Col>
                        </Form.Group>
                      ))
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </SkfModal.Content>
        </SkfModal>
      </div>
    );
  }
}

export default ModalPreFilters;
