export function menu() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'CHANGE_MENU':
      return {
        ...state,
        activePath: action
      };
    case 'OPEN_SIDEBAR':
      return {
        ...state,
        open: action
      };
    default:
      return state;
  }
}