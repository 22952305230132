import { authHeader } from '../helpers';
import { customError, catchError } from './error.service';
import axios from 'axios';
export const eventService = {
  getAllEventsByUnit,
  countAllEventsByVehicle,
  countRedEventsByVehicle,
  countAmberEventsByVehicle,
  getRedEventsByUnit,
  getAmberEventsByUnit,
  getAllEventsByVehicle,
  getRedEventsByVehicle,
  getAmberEventsByVehicle,
  countSensorsByVehicle,
  getAllEventsByCarriage,
  getAllEventsForTopViewByVehicle,
  getSensorMaxTempByVehicle,
  getSensorMaxVibrationByVehicle,
  getSensorMaxWheelFlatByVehicle,
  getMonitoringInfoByCarriage,
  getMonitoringInfoByVehicle,
  getEventsNotifications,
  postEventsNotification
};
function postEventsNotification(sourceKey, sourceId) {
  const url = `${process.env.REACT_APP_API_URL}/api/Notifications/${sourceKey}`;
  return axios({
    method: 'put',
    url,
    headers: authHeader(),
    data: {
      sourceKey,
      sourceId,
      commitInformation: {
        commited: true,
        errorDescription: ''
      }
    }
  });
}
function getEventsNotifications() {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Notifications`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    //  catchError(error.message);
  });
}
function getMonitoringInfoByCarriage(hash, vehicleHash, date) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Sensors/GetMonitoringInfoByCarriage/${hash}/vehicle/${vehicleHash}/thedate/${date}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function getMonitoringInfoByVehicle(hash, date) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Sensors/GetMonitoringInfoByVehicle/${hash}/thedate/${date}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function getAllEventsByCarriage(hash, vehicleHash) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Events/GetAllEventsByCarriage/${hash}/vehicle/${vehicleHash}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function getAllEventsByUnit(hash, vehicleHash) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Events/GetAllEventsByUnit/${hash}/vehicle/${vehicleHash}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function countAllEventsByVehicle(hash, vehicleHash) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Events/CountAllEventsByVehicle/${hash}/vehicle/${vehicleHash}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function countRedEventsByVehicle(hash) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Events/CountRedEventsByVehicle/${hash}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function countAmberEventsByVehicle(hash) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Events/CountAmberEventsByVehicle/${hash}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function getAllEventsByVehicle(reference, vehicleReference, level) {
  let levelReference = vehicleReference;
  if (!levelReference) levelReference = reference;
  //"GetAllEventsByLevel/{levelReference}/vehicle/{vehicleReference}/level/{level}"

  return axios.get(`${process.env.REACT_APP_API_URL}/api/Events/GetAllEventsByLevel/${reference}/vehicle/${levelReference}/level/${level}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function getAllEventsForTopViewByVehicle(levelReference, vehicleReference, level) {
  ///api/Events/GetAllEventsForTopViewByLevel/{levelReference}/vehicle/{vehicleReference}/level/{level}
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Events/GetAllEventsForTopViewByLevel/${levelReference}/vehicle/${vehicleReference}/level/${level}`, {
    headers: authHeader()
  }).then(response => customError(response)).catch(function (error) {
    catchError(error.message);
  });
}
function getRedEventsByVehicle(hash) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Events/GetRedEventsByVehicle/${hash}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function getAmberEventsByVehicle(hash) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Events/GetAmberEventsByVehicle/${hash}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function getRedEventsByUnit(hash, vehicleHash) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Events/GetRedEventsByUnit/${hash}/vehicle/${vehicleHash}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function getAmberEventsByUnit(hash, vehicleHash) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Events/GetAmberEventsByUnit/${hash}/vehicle/${vehicleHash}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function countSensorsByVehicle(hash) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Sensors/CountSensorsByVehicle/${hash}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(function (error) {
    catchError(error.message);
  });
}
function getSensorMaxTempByVehicle(reference, date, vehicleReference, level) {
  let levelReference = vehicleReference;
  if (!levelReference) levelReference = reference;

  //GetSensorMaxTempInfoByLevel/{​levelReference}​/vehicle/

  return axios.get(`${process.env.REACT_APP_API_URL}/api/Sensors/GetSensorMaxTempInfoByLevel/${reference}/vehicle/${levelReference}/level/${level}/thedate/${date}`, {
    headers: authHeader()
  }).then(response => {
    if (!response.data.errMessage) return response.data;
    return response.data;
  }).then(response => {
    return response;
  }).catch(error => {
    return error;
  });
}
function getSensorMaxVibrationByVehicle(hash, date) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Sensors/GetSensorMaxVibrationInfoByVehicle/${hash}/thedate/${date}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(error => {
    return error;
  });
}
function getSensorMaxWheelFlatByVehicle(hash, date) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/Sensors/GetSensorMaxWheelFlatInfoByVehicle/${hash}/thedate/${date}`, {
    headers: authHeader()
  }).then(response => customError(response)).then(response => {
    return response;
  }).catch(error => {
    return error;
  });
}