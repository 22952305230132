import { toast } from 'react-toastify';
function logout() {
  sessionStorage.setItem('client', '');
  sessionStorage.setItem('loggedIn', 'false');
  sessionStorage.setItem('idToken', '');
  sessionStorage.setItem('refreshToken', '');
  window.location.replace(process.env.REACT_APP_LOGIN_URL + '?logout=true');
}
const customError = response => {
  if (!response.data.errMessage) return response.data;else {
    if (response.data.errorCode === 'Critical') {
      toast.error('Critical fail, you will be redirected to login', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 7000,
        hideProgressBar: false
      });
      toast.error(response.data.errMessage, {
        position: toast.POSITION.TOP_CENTER,
        icon: true,
        autoClose: 7000,
        hideProgressBar: false
      });
      setTimeout(logout, 7000);
    } else if (response.data.errorCode === 'Warning') toast.warn(response.data.errMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 7000,
      hideProgressBar: false
    });
  }
};
const catchError = msg => {
  toast.error('Critical fail, you will be redirected to login', {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 7000,
    hideProgressBar: false
  });
  let mesage = msg + ', contact with the administrator';
  toast.error(mesage, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 7000,
    icon: true,
    hideProgressBar: false
  });
  setTimeout(logout, 7000);
};
export { customError, catchError };