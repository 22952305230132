import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reduxThunk from 'redux-thunk';
import rootReducer from '../reducers';
import { getEpicMiddleware } from '../storeWrapper';
import { createRootEpic } from '../epics';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const getEnhancers = epicMiddleware => {
  return applyMiddleware(reduxThunk, thunkMiddleware, epicMiddleware);
};
function setupStore() {
  const epicMiddleware = getEpicMiddleware();
  const enhancers = getEnhancers(epicMiddleware);
  const setup = createStore(rootReducer, undefined,
  //  initial state provided by startup
  composeEnhancers(enhancers));
  epicMiddleware.run(createRootEpic());
  return setup;
}
export const store = setupStore();